import { IChartData } from 'store/state'
import dayjs from 'dayjs'

interface IField {
  name: string
  code: string
}

export const prepareChartData = (fields: IField[], data: IChartData[]) => {
  const result: { timestamp: number; name: string; axisLabel: string[]; [key: string]: any }[] = []
  if (data) {
    data.forEach(i => {
      const date = dayjs(i.time)
      const name = date.format('MMMM')
      const year = date.format('YYYY')
      let item = result.find(r => r.timestamp === i.time)
      if (!item) {
        item = {
          name,
          timestamp: i.time,
          axisLabel: [name, year],
        }
        result.push(item)
      }

      fields.forEach(f => {
        if (item && i.measure.toLowerCase() === f.code.toLowerCase()) {
          item[f.code] = i.num_val
        }
      })
    })
  }
  return result
}
