import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { TWhanau } from 'models/Whanau'
import { TMember, getLink } from 'models/Member'
import { useMultiFocus } from 'hooks/useMultiFocus'
import styles from './Item.module.scss'

interface Props {
  dark?: boolean
  whanau: TWhanau
  member: TMember
}

export const CardMemberTableItem = ({ dark, whanau, member }: Props) => {
  const { isFocused, setFocus } = useMultiFocus('members')

  const classes = classNames(
    styles.root,
    dark && styles.dark,
    isFocused(member.id) && styles.focus
  )

  return (
    <Link
      to={getLink(whanau, member)}
      className={classes}
      onMouseOver={() => setFocus(member.id, true)}
      onMouseOut={() => setFocus(member.id, false)}
    >
      {member.gender}
    </Link>
  )
}
