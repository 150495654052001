import React from 'react'
import styles from 'components/shared/ui/CardWhanauStats.module.scss'
import { IconClock } from 'components/shared/icons/IconClock'
import { IconWhanau4 } from 'components/shared/icons/domain/IconWhanau4'
import { IconWhanau2 } from 'components/shared/icons/domain/IconWhanau2'
import { IconWhanau5 } from 'components/shared/icons/domain/IconWhanau5'
import { IconWhanau3 } from 'components/shared/icons/domain/IconWhanau3'
import { IconWhanau1 } from 'components/shared/icons/domain/IconWhanau1'
import { IconWhanau0 } from 'components/shared/icons/domain/IconWhanau0'
import { IconManQueue } from 'components/shared/icons/IconManQueue'
import { TWhanau, getLink, getLinkTab, trafficLights } from 'models/Whanau'
import { IconArrow } from 'components/shared/icons/IconArrow'
import { RouteComponentProps, withRouter } from 'react-router'
import classNames from 'classnames'

interface Props extends RouteComponentProps {
  item: TWhanau
  className?: string
}

const CardWhanauStats = React.memo((props: Props) => {
  const {
    item,
    className,
    history: { push },
  } = props
  const url = getLink(item)

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        <div className={styles.title} onClick={() => push(url)}>
          {item.name}
        </div>
        <div className={styles.time}>
          <IconClock width={27} height={27} />
          <span>{item.time}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.icons}>{renderIcons(props)}</div>

        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.title}>Number in whānau</div>
            <div className={styles.peoples}>
              <IconManQueue count={item.num} height={60} className={styles.queue} ellipsis={4} />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.title}>Status</div>
            <div className={styles.status}>{item.status}</div>
          </div>
          <div className={styles.column}>
            <div className={styles.next} onClick={() => push(url)}>
              <IconArrow width={50} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

const renderIcons = ({ item, history: { push } }: Props) => {
  const components: React.ReactNode[] = []
  trafficLights(item).forEach((i, index) => {
    if (i.code === 'ed presentations') {
      components.push(
        <IconWhanau0
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab1'))}
          key={i.code}
        />
      )
    }
    if (i.code === 'enrolled at gp') {
      components.push(
        <IconWhanau4
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab2'))}
          key={i.code}
        />
      )
    }
    if (i.code === 'family harm events') {
      components.push(
        <IconWhanau3
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab3'))}
          key={i.code}
        />
      )
    }
    if (i.code === 'moemoea') {
      components.push(
        <IconWhanau5
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab4'))}
          key={i.code}
        />
      )
    }
    if (i.code === 'changes in school') {
      components.push(
        <IconWhanau2
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab5'))}
          key={i.code}
        />
      )
    }
    if (i.code === 'total debt to government agencies') {
      components.push(
        <IconWhanau1
          width={53}
          height={53}
          color={i.colorStatus}
          onClick={() => push(getLinkTab(item, 'tab6'))}
          key={i.code}
        />
      )
    }
  })

  return components
}

const CardWhanauStatsHOC = withRouter(CardWhanauStats)
export { CardWhanauStatsHOC as CardWhanauStats }
