import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import {
  getBetterWorseOrSame,
  getCurrentCareProtection,
  getEmergencyPlan,
  getNaturalDisasterPlan,
  getSupportInTimeOfCrisis,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent3Table1 = ({ className, whanau }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
  </Card>
)

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    {
      name: 'Connection with services',
      code: 'connection with services',
      value: 'TODO evaluate',
      markers: { total: 1 },
    },
    {
      name: 'Current care protection',
      code: 'current care protection',
      value: getCurrentCareProtection(whanau).join(', '),
      markers: { total: 1 },
    },
    {
      name: 'Are things getting better, worse or staying the same?',
      code: 'better, worse or same',
      value: getBetterWorseOrSame(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Days in the last week everyone in household ate a meal together',
      code: 'Days in the last week everyone in household ate a meal together',
      value: 'TODO evaluate',
      markers: { total: 1 },
    },
    {
      name: 'Support in time of crisis',
      code: 'support in time of crisis',
      value: getSupportInTimeOfCrisis(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Household Emergency plan',
      code: 'emergency plan',
      value: getEmergencyPlan(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Items set aside for natural disaster',
      code: 'natural disaster plan',
      value: getNaturalDisasterPlan(whanau),
      markers: { total: 1 },
    },
  ]
}
