import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import { getEmployment, getLengthOfTenancy, getTrafficColorLowest, TWhanau } from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent5Table2 = ({ className, whanau }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
  </Card>
)

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    { name: 'Employment', code: 'employment', value: getEmployment(whanau), markers: { total: 1 } },
    { name: 'Length of tenancy', code: 'length of tenancy', value: getLengthOfTenancy(whanau), markers: { total: 1 } },
  ]
}
