import * as React from 'react'
import { History } from 'history'
import { RouteComponentProps, withRouter } from 'react-router'
import posed from 'react-pose'
import Paper from '@material-ui/core/Paper'
import { Meta } from 'components/shared/Meta'
import { LayoutSimple } from 'components/shared/layouts/LayoutSimple'
import { LoginForm } from './viewLogin/Form'
import styles from './Common.module.scss'
import { authService } from 'services/AuthService'

export interface Props extends RouteComponentProps {
  history: History
}

interface State {
  hasError: boolean
  next: boolean | Function
}

class ViewLogin extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    next: false,
  }

  public onSubmit = async (email: string, password: string) => {
    const next = await authService.login(email, password)
    this.setState({ next, hasError: !next })
  }

  public onAnimationComplete = (pose: any) => {
    const location = this.props.history.location
    if (pose === 'finish' && typeof this.state.next === 'function') {
      this.state.next()
      this.props.history.push((location.state && location.state.from) || '/')
    }
  }

  public render() {
    const { hasError, next } = this.state

    return (
      <LayoutSimple>
        <Meta title="Login" />
        <Animated
          className={styles.root}
          initialPose="start"
          pose={next ? 'finish' : 'active'}
          onPoseComplete={this.onAnimationComplete}
        >
          <Paper className={styles.paper}>
            <LoginForm hasError={hasError} onSubmit={this.onSubmit} />
          </Paper>
        </Animated>
      </LayoutSimple>
    )
  }
}

const Animated = posed.div({
  start: { x: '-50%', y: '-60%', opacity: 0 },
  active: { x: '-50%', y: '-50%', opacity: 1, transition: { duration: 300 } },
  finish: { x: '-50%', y: '-60%', opacity: 0, transition: { duration: 300 } },
})

export const ViewLoginContainer = withRouter(ViewLogin)
