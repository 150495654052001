import React from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent4Card1 } from './tabContent4/Card1'
import { TabContent4Card2 } from './tabContent4/Card2'
import { TabContent4Card3 } from './tabContent4/Card3'
import { TabContent4Card4 } from './tabContent4/Card4'
import { TabContent4Card5 } from './tabContent4/Card5'
import { TabContent4Card6 } from './tabContent4/Card6'

export const ViewCohortStatusTabContent4 = () => (
  <div>
    <PageFrame simple>
      <Grid container spacing={16}>
        <Grid item xs={12} lg={6}>
          <TabContent4Card1 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent4Card2 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent4Card3 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent4Card4 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent4Card5 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent4Card6 />
        </Grid>
      </Grid>
    </PageFrame>
  </div>
)
