import React from 'react'
import { ResponsiveContainer, AreaChart as ReAreaChart, Area, XAxis, YAxis, AreaProps, Tooltip } from 'recharts'
import { Legend } from './Legend'
import styles from './ChartsCommon.module.scss'

interface TArea {
  name: string
  color: string
  field: string
  props?: Partial<AreaProps>
}

interface TDataItem {
  name: string
  axisLabel: string[]
  [key: string]: any
}

interface Props {
  title?: string
  withLegend?: boolean
  height: number
  data: TDataItem[]
  areas: TArea[]
  renderAxisValue?: (v: number) => number | string
  canvasMargin?: number | string
}

export const AreaChart = (
  { title, withLegend, data, areas, height, renderAxisValue, canvasMargin }: Props
) => (
  <div className={styles.root}>
    <div className={styles.head}>
      <div className={styles.title}>{title}</div>
      {withLegend && <Legend items={areas} />}
    </div>

    <div className={styles.canvas} style={{ margin: canvasMargin }}>
      <ResponsiveContainer width={'100%'} height={height}>
        <ReAreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis
            dataKey="name"
            stroke="#E0E0E0"
            tickLine={false}
            height={60}
            allowDataOverflow={true}
            tick={<XAxisTick data={data} />}
          />
          <YAxis
            stroke="#E0E0E0"
            tickLine={false}
            width={60}
            allowDataOverflow={true}
            tick={<YAxisTick data={data} renderAxisValue={renderAxisValue} />}
          />
          <Tooltip cursor={false} />
          {areas.map(area => (
            <Area key={area.name} type={'linear'} dataKey={area.field} fill={area.color} stroke={0} {...area.props} />
          ))}
        </ReAreaChart>
      </ResponsiveContainer>
    </div>
  </div>
)

const XAxisTick = ({ x, y, stroke, payload, data }: any) => {
  const item = data[payload.index]
  return (
    item && (
      <g transform={`translate(${x},${y + 24})`}>
        {item.axisLabel[0] && <XAxisTickText text={item.axisLabel[0]} />}
        {item.axisLabel[1] && <XAxisTickText text={item.axisLabel[1]} dy={24} />}
      </g>
    )
  )
}

const XAxisTickText = ({ text, dy = 0 }: any) =>
  text && (
    <text x={0} y={dy} textAnchor="middle" fill="#5f5f5f" fontSize={12}>
      {text}
    </text>
  )

const YAxisTick = ({ x, y, stroke, payload, data, renderAxisValue }: any) => (
  <g transform={`translate(${x - 10},${y})`}>
    <text x={0} y={0} textAnchor="end" fill="#5f5f5f" fontSize={12}>
      {renderAxisValue ? renderAxisValue(payload.value) : payload.value}
    </text>
  </g>
)
