import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { BarChart } from 'components/shared/ui/charts/BarChart'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchChart } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import { prepareChartData } from 'helpers/ChartDataHelper'

const fields = [
  { code: 'Family harm', name: 'Family harm', color: '#0379A0' },
  { code: 'Current care protection', name: 'Current care protection', color: '#26C6DA' },
]

export const TabContent7Card2 = () => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchChart('family-harm')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card padding={24} title={'Connection with services'}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <BarChart
          withLegend
          height={200}
          bars={fields.map(i => ({ name: i.name, color: i.color, field: i.code }))}
          data={prepareChartData(fields, data)}
        />
      </LoaderPane>
    </Card>
  )
}
