import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import {
  getComfortWithCulture,
  getKnowledgeOfIwiAndHapu,
  getKohangaParticipation,
  getLearningTeReo,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent4Table1 = ({ className, whanau }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
  </Card>
)

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    { name: 'Learning Te Reo', code: 'learning te reo', value: getLearningTeReo(whanau), markers: { total: 1 } },
    {
      name: 'Knowledge of Iwi and Hap',
      code: 'knowledge of iwi and hapu',
      value: getKnowledgeOfIwiAndHapu(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Comfort with culture',
      code: 'comfort with culture',
      value: getComfortWithCulture(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Kohanga participation',
      code: 'kohanga participation',
      value: getKohangaParticipation(whanau),
      markers: { total: 1 },
    },
  ]
}
