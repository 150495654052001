import React from 'react'
import { mem } from 'base/utils/mem'
import classNames from 'classnames'
import { TGoal } from 'models/Goal'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import { IconGoalComplete } from 'components/shared/icons/IconGoalComplete'
import { useMultiFocus } from 'hooks/useMultiFocus'
import { GoalModal } from 'components/shared/ui/GoalModal'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import { Loader } from 'base/components/ui/Loader'

interface Props {
  isLoading: boolean
  goals: TGoal[]
  className?: string
}

export const TabContent1CardGoalsComplete = mem(({ goals, className, isLoading }: Props) => {
  const [openGoal, setOpenGoal] = React.useState<TGoal | null>(null)
  const { isFocused, setFocus } = useMultiFocus('goals')

  return (
    <Card className={classNames(styles.root, className)}>
      { isLoading ? <Loader absolute /> :
        <Table
          className={ styles.table }
          columns={ columns }
          onRowClick={ setOpenGoal }
          getRowProps={ item => (isFocused(item.id) ? { className: styles.trActive } : {}) }
          onRowFocus={ item => setFocus(item.id, true) }
          onRowBlur={ item => setFocus(item.id, false) }
          items={ goals }
        />
      }
      <GoalModal goal={openGoal} close={() => setOpenGoal(null)}/>
    </Card>
  )
})

const columns: TColumn[] = [
  {
    label: (
      <div className={styles.head}>
        <IconGoalComplete width={30} height={30} /> Complete goals
      </div>
    ),
    render: (item: TGoal) => item.summary,
  },
  {
    label: '',
    render: () => '',
  },
  {
    label: '',
    render: () => '',
  },
]
