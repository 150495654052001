import React, { useState } from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent1Numbers } from './tabContent1/Numbers'
import { TabContent1AgeGroup } from './tabContent1/AgeGroup'
import { TabContent1FamilyType } from './tabContent1/FamilyType'
import { TabContent1ActivityChart } from './tabContent1/ActivityChart'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchSimpleList } from 'api/WhanauApi'
import { WhanauMap } from 'components/shared/ui/Map'
import { TWhanau } from 'models/Whanau'

export const ViewCohortStatusTabContent1 = () => {
  const [whanauList, setWhanauList] = useState<TWhanau[]>([])

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchSimpleList(1000)
      setWhanauList(data)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <div>
      <PageFrame simple>
        <Grid container spacing={16} mb={24}>
          <Grid item xs={12} lg={4}>
            <TabContent1Numbers />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TabContent1AgeGroup />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TabContent1FamilyType />
          </Grid>
        </Grid>

        <Grid container>
          <TabContent1ActivityChart />
        </Grid>
      </PageFrame>

      <WhanauMap items={whanauList} withPopup={false} markerColor={'green'} />
    </div>
  )
}
