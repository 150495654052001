import React from 'react'
import { Grid } from '@material-ui/core'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Heading2 } from 'components/shared/ui/Heading2'
import Image1 from 'assets/img/image1.png'
import Image2 from 'assets/img/image2.png'
import Image3 from 'assets/img/image3.png'
import Image4 from 'assets/img/image4.png'
import Image5 from 'assets/img/image5.png'
import Image6 from 'assets/img/image6.png'
import Image7 from 'assets/img/image7.png'
import Image8 from 'assets/img/image8.png'
import Image9 from 'assets/img/image9.jpg'
import Image10 from 'assets/img/image10.png'
import Image11 from 'assets/img/image11.png'
import Image12 from 'assets/img/image12.png'
import Image13 from 'assets/img/image13.png'
import Image14 from 'assets/img/image14.png'
import Image15 from 'assets/img/image15.png'
import styles from './Partners.module.scss'

export const ViewHomePartners = () => (
  <div className={styles.root}>
    <PageFrame simple>
      <div className={styles.partners}>
        <Heading2 className={styles.title}>Collective Impact Partners</Heading2>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image1} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image2} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image3} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image4} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image5} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image6} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image7} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image8} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image9} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image10} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image11} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image12} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image13} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image14} alt={'test'} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.item}>
              <img src={Image15} alt={'test'} />
            </div>
          </Grid>
        </Grid>
      </div>
    </PageFrame>
  </div>
)
