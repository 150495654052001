import { appGetState, appNextState, appUpdateState } from 'store'
import { TMember, create as createMember } from 'models/Member'
import { TWhanau, create as createWhanau } from 'models/Whanau'
import { fetchOneById } from 'api/WhanauApi'
import { fetchMember } from 'api/MemberApi'

const comment = (m: string) => `MemberService::${m}`

class MemberService {
  private appGetState = appGetState
  private appNextState = appNextState
  private appUpdateState = appUpdateState

  public loadDetail = async (whanauId: string, id: string) => {
    if (this.appGetState().members.detail.member.id !== id) {
      this.setIsLoading(true)
      const whanauPromise = fetchOneById(whanauId)
      const kwoPromise = await fetchMember(id, 'individual')

      const member = createMember({
        id: `${id}`,
        name: id,
      }, await kwoPromise)
      this.setMemberDetails(await whanauPromise, member)
    }
  }

  /**
   */
  private setMemberDetails = (whanau?: TWhanau, item?: TMember): void => {
    this.appUpdateState(s => {
      s.members.detail.whanau = whanau ? { ...whanau } : createWhanau()
      s.members.detail.member = item ? { ...item } : createMember()
      s.members.isLoading = false
    }, comment('setMemberDetails'))
  }

  /**
   */
  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(s => (s.members.isLoading = isLoading), comment('setIsLoading'))
  }
}

const memberService = new MemberService()
export { memberService }
