import * as React from 'react'
import classNames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './Loader.module.scss'

interface Props {
  size?: number
  thickness?: number
  absolute?: boolean
}

export const Loader = React.memo(({ size = 40, thickness = 3.4, absolute }: Props) => (
  <div className={classNames(styles.root, absolute && styles.rootAbsolute)}>
    <CircularProgress size={size} thickness={thickness} classes={{ circle: styles.progress }} />
  </div>
))
