import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import {
  getChangesInSchool,
  getCurrentSchools,
  getLearningSupport,
  getSchoolEnrollment,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent5Table1 = ({ className, whanau }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
  </Card>
)

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    {
      name: 'School enrolment',
      code: 'school enrollment',
      value: (
        <div className={styles.list}>
          <div>{getSchoolEnrollment(whanau)}</div>
          {getCurrentSchools(whanau).map(i => (
            <div>{i}</div>
          ))}
        </div>
      ),
      markers: { total: 1 },
    },
    { name: 'Changes in school', code: 'changes in school', value: getChangesInSchool(whanau), markers: { total: 1 } },
    { name: 'Learning support', code: 'learning support', value: getLearningSupport(whanau), markers: { total: 1 } },
  ]
}
