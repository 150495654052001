import React from 'react'
import classNames from 'classnames'
import { Paper } from '@material-ui/core'
import styles from './CardLine.module.scss'
import { TColor } from 'components/shared/ui/CardIcon'

interface Props {
  color: TColor
  title: string
  content: string
  className?: string
}

export const CardLine = ({ color, title, content, className }: Props) => (
  <Paper className={classNames(styles.root, className)}>
    <div className={styles.left}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>
    </div>
    <div className={classNames(styles.line, styles[color])} />
  </Paper>
)
