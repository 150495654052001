import React from 'react'
import classNames from 'classnames'
import { CardKoru } from 'components/shared/ui/CardKoru'
import { TWhanau } from 'models/Whanau'
import { TMember } from 'models/Member'
import { CardMemberListItem } from './cardMemberList/Item'
import styles from './CardMemberList.module.scss'
import dayjs from 'dayjs'

interface Props {
  whanau: TWhanau
  members: TMember[]
  className?: string
}

export const TabContent1CardMemberList = ({ whanau, members, className }: Props) => {
  const memberFiltered = members.filter(i => !!whanau.peoples.find(p => p.kwoId === i.id))
  return (
    <CardKoru className={classNames(className, styles.root)}>
      <div className={styles.title}>
        Whānau ID: <span>{whanau.id}</span>
      </div>
      <div className={styles.info}>In programme since {dayjs(whanau.startDate).format('MMMM YYYY')}</div>
      <div className={styles.listTitle}>Individual IDs</div>
      <div>
        {memberFiltered.map(member => (
          <CardMemberListItem key={member.id} whanau={whanau} member={member} />
        ))}
      </div>
    </CardKoru>
  )
}
