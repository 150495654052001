import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconGoalCurrent = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 30 30" width={width} height={height}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 Z M10.8332,8.75 C10.6122,8.75 10.4002,8.8378 10.244,8.99408 C10.0877,9.15036 9.99988,9.36232 9.99988,9.58333 L9.99988,23.75 L11.6665,23.75 L11.6665,17.9167 C11.6665,17.9167 12.4999,16.25 14.9999,16.25 C16.6665,16.25 16.6665,17.9167 19.1665,17.9167 C21.6665,17.9167 22.4999,16.25 22.4999,16.25 L22.4999,9.58333 C22.4999,9.58333 21.6665,11.25 19.1665,11.25 C17.4999,11.25 17.4999,9.58333 14.9999,9.58333 C13.7499,9.58333 12.5499,9.95 11.6665,10.3167 L11.6665,9.58333 C11.6665,9.36232 11.5787,9.15036 11.4225,8.99408 C11.2662,8.8378 11.0542,8.75 10.8332,8.75 Z" />
    </g>
  </svg>
))
