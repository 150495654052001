import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconAdministration = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 30 30" width={width} height={height} fill="currentColor">
    <path
      d="M15 19.375C13.8397 19.375 12.7269 18.9141 11.9065 18.0936C11.086 17.2731 10.625 16.1603 10.625 15C10.625 13.8397 11.086 12.7269 11.9065 11.9064C12.7269 11.0859 13.8397 10.625 15 10.625C16.1604 10.625 17.2732 11.0859 18.0936 11.9064C18.9141 12.7269 19.375 13.8397 19.375 15C19.375 16.1603 18.9141 17.2731 18.0936 18.0936C17.2732 18.9141 16.1604 19.375 15 19.375ZM24.2875 16.2125C24.3375 15.8125 24.375 15.4125 24.375 15C24.375 14.5875 24.3375 14.175 24.2875 13.75L26.925 11.7125C27.1625 11.525 27.225 11.1875 27.075 10.9125L24.575 6.5875C24.425 6.3125 24.0875 6.2 23.8125 6.3125L20.7 7.5625C20.05 7.075 19.375 6.65 18.5875 6.3375L18.125 3.025C18.075 2.725 17.8125 2.5 17.5 2.5H12.5C12.1875 2.5 11.925 2.725 11.875 3.025L11.4125 6.3375C10.625 6.65 9.95004 7.075 9.30004 7.5625L6.18754 6.3125C5.91254 6.2 5.57504 6.3125 5.42504 6.5875L2.92504 10.9125C2.76254 11.1875 2.83754 11.525 3.07504 11.7125L5.71254 13.75C5.66254 14.175 5.62504 14.5875 5.62504 15C5.62504 15.4125 5.66254 15.8125 5.71254 16.2125L3.07504 18.2875C2.83754 18.475 2.76254 18.8125 2.92504 19.0875L5.42504 23.4125C5.57504 23.6875 5.91254 23.7875 6.18754 23.6875L9.30004 22.425C9.95004 22.925 10.625 23.35 11.4125 23.6625L11.875 26.975C11.925 27.275 12.1875 27.5 12.5 27.5H17.5C17.8125 27.5 18.075 27.275 18.125 26.975L18.5875 23.6625C19.375 23.3375 20.05 22.925 20.7 22.425L23.8125 23.6875C24.0875 23.7875 24.425 23.6875 24.575 23.4125L27.075 19.0875C27.225 18.8125 27.1625 18.475 26.925 18.2875L24.2875 16.2125Z"
      fill="#6F6F6F"
    />
  </svg>
))
