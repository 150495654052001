import dayjs from 'dayjs'

const initialData = {
  id: '',
  summary: '',
  whanauId: '',
  completionDate: '',
  tsFrom: 0,
  tsTo: 0,
  from: '',
  to: '',
  isCurrent: false,
  outcomeArea: '',
  outcomeId: '',
}

export type TGoal = typeof initialData

export const create = (data?: Partial<TGoal>): TGoal => ({
  ...initialData,
  ...data,
})

export const map = (data: TObjectAny): TGoal => {
  return {
    id: data.id,
    summary: data.summary,
    whanauId: data.whanau_id,
    completionDate: dayjs(data.completion_date).format('YYYY-MM-DD'),
    tsFrom: data.start_date,
    tsTo: data.due_date,
    from: dayjs(data.start_date).format('YYYY-MM-DD'),
    to: dayjs(data.due_date).format('YYYY-MM-DD'),
    isCurrent: data.is_current,
    outcomeArea: data.outcome_area,
    outcomeId: data.outcome_id,
  }
}

const initialAreaData = {
  item: create({}) as TGoal,
  dx: 0,
  width: 0,
  today: 0,
  complete: false,
}

export type TGoalArea = typeof initialAreaData

export const createArea = (data?: Partial<TGoalArea>): TGoalArea => ({ ...initialAreaData, ...data })
