import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconKPI = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 24 24" width={width} height={height} fill="currentColor">
    <path
      d="M21.6 9.6C20.7104 9.6 19.9424 10.08 19.5264 10.8032H17.8816C17.728 10.0352 17.4272 9.3248 17.0176 8.6912L18.1824 7.5264C18.976 7.744 19.8656 7.5392 20.4864 6.912C21.4208 5.9776 21.4208 4.4544 20.4864 3.5136C19.552 2.5728 18.0288 2.5792 17.088 3.5136C16.4608 4.1408 16.2624 5.024 16.4736 5.8304L15.3088 6.9952C14.6752 6.5728 13.9648 6.272 13.1968 6.1184V4.4864C13.92 4.064 14.4 3.296 14.4 2.4C14.4 1.0816 13.3312 0 12 0C10.6816 0 9.6 1.0816 9.6 2.4C9.6 3.2896 10.08 4.0704 10.8032 4.4736V6.1184C10.0352 6.272 9.3248 6.5728 8.6912 6.9952L7.5264 5.8304C7.744 5.024 7.5392 4.1408 6.912 3.5136C5.9776 2.5792 4.4544 2.5792 3.5136 3.5136C2.5728 4.448 2.5792 5.9712 3.5136 6.912C4.1408 7.5392 5.024 7.7376 5.8304 7.5264L6.9952 8.6912C6.5728 9.3248 6.272 10.0352 6.1184 10.8032H4.4736C4.064 10.08 3.2832 9.6 2.4 9.6C1.0816 9.6 0 10.6816 0 12C0 13.3312 1.0816 14.4 2.4 14.4C3.3024 14.4 4.0704 13.92 4.4864 13.1968H6.1184C6.272 13.9648 6.5728 14.688 6.9952 15.3088L5.8176 16.4864C5.024 16.2688 4.1344 16.4736 3.5136 17.1008C2.5792 18.0352 2.5792 19.552 3.5136 20.4992C4.448 21.4336 5.9712 21.4336 6.912 20.4992C7.5392 19.8656 7.7376 18.976 7.5264 18.1824L8.6912 17.0176C9.3248 17.4272 10.0352 17.728 10.8032 17.8816V19.5264C10.08 19.9488 9.6 20.7168 9.6 21.6C9.6 22.9312 10.6816 24 12 24C13.3312 24 14.4 22.9312 14.4 21.6C14.4 20.7104 13.92 19.9296 13.1968 19.5264V17.8816C13.9648 17.728 14.6752 17.4272 15.3088 17.0176L16.4736 18.1824C16.256 18.976 16.4608 19.8656 17.088 20.4992C18.0224 21.4336 19.5456 21.4336 20.4864 20.4992C21.4208 19.552 21.4208 18.0416 20.4864 17.1008C19.8592 16.4736 18.976 16.2752 18.1824 16.4864L17.0048 15.3088C17.4272 14.6816 17.728 13.9648 17.8816 13.1968H19.5264C19.936 13.92 20.7168 14.4 21.6 14.4C22.9312 14.4 24 13.3312 24 12C24 10.6816 22.9312 9.6 21.6 9.6ZM12 15.6032C10.0224 15.6032 8.3968 13.984 8.3968 12C8.3968 10.016 10.016 8.3968 12 8.3968C13.984 8.3968 15.6032 10.016 15.6032 12C15.6032 13.984 13.9776 15.6032 12 15.6032Z"
      fill="#6F6F6F"
    />
  </svg>
))
