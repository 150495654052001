import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconTick = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 12 12" width={width} height={height} fill="currentColor">
    <path d="M4.45984 9.26001C4.35628 9.26001 4.2526 9.22197 4.17172 9.14553L1.37188 6.50553C1.20316 6.34641 1.19524 6.08061 1.35436 5.91189C1.51336 5.74305 1.77916 5.73525 1.94812 5.89437L4.45624 8.25933L10.048 2.85789C10.215 2.69685 10.4807 2.70129 10.6419 2.86809C10.803 3.03489 10.7985 3.30081 10.6316 3.46197L4.75156 9.14193C4.67032 9.22053 4.56508 9.26001 4.45984 9.26001Z" />
  </svg>
))
