import * as React from 'react'

export interface Props {
  errorView?: any
}
interface State {
  error: null | Error
  errorInfo: null | React.ErrorInfo
}

class ErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null,
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    window.logger.error(error, errorInfo)
    this.setState({ error, errorInfo })
  }

  public render() {
    const { errorView, children } = this.props
    const { error } = this.state

    return error ? errorView || 'Error catch' : children
  }
}

export { ErrorBoundary }
