import React from 'react'
import classNames from 'classnames'
import { Hidden, Table as MuiTable, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core'
import { TableCellProps } from '@material-ui/core/TableCell'
import { TableRowProps } from '@material-ui/core/TableRow'
import { HiddenProps } from '@material-ui/core/Hidden'
import styles from './Table.module.scss'

export interface TColumn {
  label: React.ReactNode
  render: (row: any) => any
  thProps?: TableCellProps
  tdProps?: TableCellProps
  hiddenProps?: HiddenProps
}

interface Props<T> {
  hideHeader?: boolean
  columns: TColumn[]
  items: T[]
  getRowProps?: (item: T) => TableRowProps
  onRowClick?: (item: T) => void
  onRowFocus?: (item: T) => void
  onRowBlur?: (item: T) => void
  footer?: React.ReactNode
  className?: string
}

export const Table = <T extends any>(
  { columns, items, footer, className, getRowProps, onRowClick, onRowFocus, onRowBlur, hideHeader }: Props<T>
) => {
  return (
    <MuiTable className={classNames(styles.root, className)}>
      {!hideHeader && (
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <Hidden key={`${i}:${column.label}`} {...column.hiddenProps}>
                <TableCell {...column.thProps}>
                  {column.label}
                </TableCell>
              </Hidden>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {items.map((item, i) => {
          getRowProps && window.logger.info(getRowProps(item))
          return (
            <TableRow
              key={item.id || i}
              onClick={() => onRowClick && onRowClick(item)}
              onMouseOver={() => onRowFocus && onRowFocus(item)}
              onMouseOut={() => onRowBlur && onRowBlur(item)}
              {...getRowProps && getRowProps(item)}
            >
              {columns.map((column, i) => (
                <Hidden key={`${i}:${column.label}`} {...column.hiddenProps}>
                  <TableCell {...column.tdProps}>
                    {column.render(item)}
                  </TableCell>
                </Hidden>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
      {footer && <TableFooter>{footer}</TableFooter>}
    </MuiTable>
  )
}
