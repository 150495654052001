import React from 'react'
import { TextField } from '@material-ui/core'
import { FormPropsContext } from 'base/components/ui/FormFrame'
import styles from './Fields.module.scss'
import { FormikProps } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'

interface BaseProps {
  label: string
  name: string
  required?: boolean
  autoFocus?: boolean
}

interface FieldTextProps extends BaseProps {
  type?: string
  autoComplete?: string
  disabled?: boolean
}

export const FieldText = ({
  label,
  name,
  required,
  autoFocus,
  autoComplete,
  type = 'text',
  disabled = false,
}: FieldTextProps) => {
  const formProps = React.useContext(FormPropsContext)
  const errorText = getErrorText(formProps, name)
  return (
    <div className={styles.field}>
      <TextField
        fullWidth
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        label={label}
        name={name}
        type={type}
        autoComplete={autoComplete}
        helperText={errorText || ' '}
        error={!!errorText}
        value={formProps.values[name]}
        onChange={formProps.handleChange}
        onBlur={formProps.handleBlur}
      />
    </div>
  )
}

interface FieldSelectOption {
  value: any
  label: string
}
interface FieldSelectProps extends BaseProps {
  options: FieldSelectOption[]
  disabled?: boolean
}

export const FieldSelect = ({ label, name, options, required, autoFocus, disabled = false }: FieldSelectProps) => {
  const formProps = React.useContext(FormPropsContext)
  const errorText = getErrorText(formProps, name)
  return (
    <div className={styles.field}>
      <TextField
        select
        fullWidth
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        label={label}
        name={name}
        helperText={errorText || ' '}
        error={!!errorText}
        value={formProps.values[name]}
        onChange={formProps.handleChange}
        onBlur={formProps.handleBlur}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

function getErrorText(formProps: FormikProps<any>, fieldName: string) {
  return (!!formProps.touched[fieldName] || formProps.submitCount > 0) && formProps.errors[fieldName]
}
