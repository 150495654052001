import React from 'react'
import { Card } from 'base/components/ui/Card'
import { PieChart } from 'components/shared/ui/charts/PieChart'
import styles from './TabContent5Common.module.scss'
import { IReportBreakDown } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

interface Props {
  isLoaded: boolean
  data?: IReportBreakDown
}

const getPies = (data?: IReportBreakDown) => {
  return data
    ? { name: data.measure, value: data.num_perc, color: '#77C288', info: data.dimension }
    : { name: '', value: 0, color: '#77C288', info: '' }
}

export const TabContent5Card2 = ({ isLoaded, data }: Props) => {
  const pies = getPies(data)

  return (
    <Card title={pies.name} padding={24} className={styles.root}>
      <LoaderPane isLoaded={isLoaded} isData={!!data}>
        <div className={styles.content}>
          <div className={styles.chart}>
            <PieChart pies={[pies]} outerWidth={170} innerWidth={130} totalValue={100} />
          </div>
          <div className={styles.legend}>
            <div className={styles.value} style={{ color: pies.color }}>
              {pies.value}%
            </div>
            <div className={styles.info}>{pies.info}</div>
          </div>
        </div>
      </LoaderPane>
    </Card>
  )
}
