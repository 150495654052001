import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { IconWhanau1 } from 'components/shared/icons/domain/IconWhanau1'
import { IconWhanau2 } from 'components/shared/icons/domain/IconWhanau2'
import { IconWhanau3 } from 'components/shared/icons/domain/IconWhanau3'
import { IconWhanau4 } from 'components/shared/icons/domain/IconWhanau4'
import { IconWhanau5 } from 'components/shared/icons/domain/IconWhanau5'
import { IconWhanau0 } from 'components/shared/icons/domain/IconWhanau0'
import styles from './Header.module.scss'

export const TableHeader = () => (
  <TableHead className={styles.root}>
    <TableRow>
      <TableCell className={styles.info}>Whānau ID</TableCell>
      <TableCell className={styles.info}>Time in programme</TableCell>
      <TableCell className={styles.info}>Number in whānau</TableCell>
      <TableCell className={styles.info}>Status</TableCell>
      <TableCell className={styles.section}>
        <IconWhanau0 className={styles.icon} width={52} />
        Self managing
      </TableCell>
      <TableCell className={styles.section}>
        <IconWhanau4 className={styles.icon} width={52} />
        Healthy lifestyle
      </TableCell>
      <TableCell className={styles.section}>
        <IconWhanau3 className={styles.icon} width={52} />
        Whanau Cohesion
      </TableCell>
      <TableCell className={styles.section}>
        <IconWhanau5 className={styles.icon} width={52} />
        Participation in Te Ao Māori
      </TableCell>
      <TableCell className={styles.section}>
        <IconWhanau2 className={styles.icon} width={52} />
        Participation in society
      </TableCell>
      <TableCell className={styles.section}>
        <IconWhanau1 className={styles.icon} width={52} />
        Economically secure
      </TableCell>
    </TableRow>
  </TableHead>
)
