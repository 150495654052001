import React from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent3Card1 } from './tabContent3/Card1'
import { TabContent3Card2 } from './tabContent3/Card2'
import { TabContent3Card3 } from './tabContent3/Card3'

export const ViewCohortStatusTabContent3 = () => {
  return (
    <div>
      <PageFrame simple>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            <TabContent3Card1 />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TabContent3Card2 />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TabContent3Card3 />
          </Grid>
        </Grid>
      </PageFrame>
    </div>
  )
}
