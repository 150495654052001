import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router'
import { ViewNotFound } from 'components/views/ViewNotFound'
import { canAccess, TModel as TUser } from 'models/User'

interface Props extends RouteProps {
  isLoggedIn: boolean
  user: TUser
  requiredRoles?: number[]
}

const PrivateRoute = ({ isLoggedIn, user, requiredRoles, component: Component, ...rest }: Props) => (
  <Route
    render={(props: RouteComponentProps<any>) => {
      if (isLoggedIn && Component) {
        return canAccess(user, requiredRoles) ? (
          <Component {...props} />
        ) : (
          <ViewNotFound {...props} />
        )
      }
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }}
    {...rest}
  />
)

const mapStateToProps = (state: IState, ownProps: Pick<Props, 'requiredRoles'>) => ({
  isLoggedIn: !!state.app.currentUser.id,
  user: state.app.currentUser,
  requiredRoles: ownProps.requiredRoles,
})

const PrivateRouteConnected = connect(mapStateToProps)(PrivateRoute)
export { PrivateRouteConnected as PrivateRoute }
