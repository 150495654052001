import { request } from '../base/utils/http/Request'
import { CONTACTS, GOALS } from 'config/paths'
import { map as mapGoal, TGoal } from 'models/Goal'
import { map as mapEvent } from 'models/Event'

export const fetchContacts = async (whanauId: string) => {
  const data = await request.get(`${CONTACTS}`, { whanau_id: whanauId })
  let result = []
  if (data && data.items) {
    result = data.items.map(mapEvent)
  }

  return result
}

export const fetchGoals = async (whanauId: string) => {
  const data = await request.get(`${GOALS}`, { whanau_id: whanauId })
  let result: TGoal[] = []
  if (data && data.items) {
    result = data.items.map(mapGoal)
  }

  return result
}

export const sortByTsFrom = (items: TGoal[], dir?: 'asc' | 'desc') => {
  return items.slice().sort((a: TGoal, b: TGoal) => {
    if (dir === 'asc') {
      return a.tsFrom - b.tsFrom
    }

    if (dir === 'desc') {
      return b.tsFrom - a.tsFrom
    }

    return 0
  })
}
