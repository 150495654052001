import React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { Modal } from 'base/components/ui/Modal'
import { layoutService } from 'services/LayoutService'

interface Props {
  open: boolean
  title: string
  content: string
  cancel: boolean
  actionLabel: string
  action: () => void
  close: () => void
}

const Dialog = ({ open, title, content, cancel, actionLabel, action, close }: Props) => {
  const onAction = () => {
    close()
    action()
  }

  return (
    <Modal
      open={open}
      title={title}
      content={content}
      actionLabel={actionLabel}
      onAction={onAction}
      close={close}
      cancel={cancel}
    />
  )
}

const DialogConnected = connect(
  (state: IState): Props => ({
    open: state.app.layout.dialog.open,
    title: state.app.layout.dialog.title,
    content: state.app.layout.dialog.content,
    cancel: state.app.layout.dialog.cancel,
    actionLabel: state.app.layout.dialog.actionLabel,
    action: state.app.layout.dialog.action,
    close: layoutService.closeDialog,
  })
)(Dialog)

export { DialogConnected as Dialog }
