import 'assets/scss/base.scss'
import * as React from 'react'
import { Provider } from 'react-redux'
import { store } from 'store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'config/muiTheme'
import Routes from 'components/Routes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ErrorBoundary } from 'base/components/ErrorBoundary'
import { Meta } from './shared/Meta'

export const App = React.memo(() => (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <Meta />
      <CssBaseline />
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </MuiThemeProvider>
  </Provider>
))
