import * as React from 'react'
import { connect } from 'react-redux'
import { history, IState } from 'store'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { PrivateRoute } from 'base/components/PrivateRoute'
import { ViewNotFound } from 'components/views/ViewNotFound'
import { ViewSplash } from 'components/views/ViewSplash'
import { ViewLoginContainer } from 'components/views/ViewLogin'
import { ViewActivateContainer } from 'components/views/ViewActivate'
import { ViewForgotPasswordContainer } from 'components/views/ViewForgotPassword'
import { ViewChangePasswordContainer } from 'components//views/ViewChangePassword'
import { ViewHome } from 'components/views/ViewHome'
import { ViewWhanauList } from 'components/views/ViewWhanauList'
import { ViewWhanauDetail } from 'components/views/ViewWhanauDetail'
import { ViewMember } from 'components/views/ViewMember'
import { ViewUserList } from 'components/views/ViewUserList'
import { ViewUserEdit } from 'components/views/ViewUserEdit'
import { ViewCollective } from 'components/views/ViewCollective'
import { ViewCohortStatus } from './views/ViewCohortStatus'
import { ViewCohortKpi } from './views/ViewCohortKpi'
import { ViewAbout } from './views/ViewAbout'

interface Props {
  isLoggedIn: boolean
}

export const Routes = React.memo(({ isLoggedIn }: Props) => (
  <ConnectedRouter history={history}>
    <Switch>
      {!isLoggedIn && <Route exact path="/" component={ViewSplash} />}
      <Route exact path="/login" component={ViewLoginContainer} />
      <Route exact path="/activateAccount" component={ViewActivateContainer} />
      <Route exact path="/forgotPassword" component={ViewForgotPasswordContainer} />
      <Route exact path="/resetPassword" component={ViewChangePasswordContainer} />
      <PrivateRoute exact path="/" component={ViewHome} />
      <PrivateRoute exact path="/about" component={ViewAbout} />
      <PrivateRoute exact path="/whanau" component={ViewWhanauList} requiredRoles={[99, 40, 35]} />
      <PrivateRoute exact path="/whanau/:id" component={ViewWhanauDetail} requiredRoles={[99, 40, 35]} />
      <PrivateRoute exact path="/whanau/:whanauId/member/:id" component={ViewMember} requiredRoles={[99, 40, 35]} />
      <PrivateRoute
        exact
        path="/collective-leadership"
        component={ViewCollective}
        requiredRoles={[99, 40, 35, 33, 15]}
      />
      <PrivateRoute exact path="/cohort-status" component={ViewCohortStatus} requiredRoles={[99, 40, 35, 33]} />
      <PrivateRoute exact path="/cohort-kpi" component={ViewCohortKpi} requiredRoles={[99, 40, 35, 33, 15]} />
      <PrivateRoute exact path="/users" component={ViewUserList} requiredRoles={[99, 40]} />
      <PrivateRoute exact path="/users/:id/:action" component={ViewUserEdit} requiredRoles={[99, 40]} />
      <PrivateRoute exact path="/users/create" component={ViewUserEdit} requiredRoles={[99, 40]} />
      <Route component={ViewNotFound} />
    </Switch>
  </ConnectedRouter>
))

const mapStateToProps = (state: IState) => ({
  isLoggedIn: !!state.app.currentUser.id,
})

export default connect(mapStateToProps)(Routes)
