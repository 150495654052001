import React from 'react'
import classNames from 'classnames'
import { CardKoru } from 'components/shared/ui/CardKoru'
import { Grid } from 'base/components/ui/Grid'
import { TMember } from 'models/Member'
import { IconMan } from 'components/shared/icons/IconMan'
import styles from './Card1.module.scss'

interface Props {
  member: TMember
  className?: string
}

export const ViewMemberCard1 = ({ member, className }: Props) => (
  <CardKoru rotate className={classNames(className, styles.card)} iconClass={styles.koru}>
    <div className={styles.root}>
      <div className={styles.icon}>
        <IconMan width={52} height={143} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{member.name}</div>
        <Grid container spacing={16} mt={16}>
          <Grid item xs={6}>
            <div className={styles.label}>Age</div>
            <div className={styles.value}>{member.ageCategoryName} ({member.age})</div>

            <div className={styles.label}>Iwi</div>
            <div className={styles.value}>{member.iwi}</div>

            <div className={styles.label}>Gender</div>
            <div className={styles.value}>{member.gender === 'M' ? 'Male' : 'Female'}</div>
          </Grid>

          <Grid item xs={6}>
            <div className={styles.label}>Ethnicity</div>
            <div className={styles.value}>{member.ethnicity}</div>

            <div className={styles.label}>Address</div>
            <div className={styles.value}>{member.address}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  </CardKoru>
)
