import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import styles from './ActionButton.module.scss'

interface Props extends ButtonProps, RouteComponentProps {
  to?: string
}

const ActionButton = (props: Props) => {
  const {
    to,
    onClick,
    history: { push },
    children,
  } = props

  const handleClick = (e: any) => {
    to ? push(to) : onClick && onClick(e)
  }

  return (
    <Button onClick={handleClick} classes={styles} variant={'outlined'}>
      {children}
    </Button>
  )
}

const ActionButtonConnected = withRouter(ActionButton)
export { ActionButtonConnected as ActionButton }
