import React from 'react'
import classNames from 'classnames'
import { IconKoruLeft, IconKoruRight } from 'components/shared/icons/IconKoru'
import { Paper } from '@material-ui/core'
import styles from './CardKoru.module.scss'

interface Props {
  children?: React.ReactNode
  rotate?: boolean
  className?: string
  iconClass?: string
}

export const CardKoru = ({ children, className, rotate, iconClass }: Props) => {
  const iconProps = {
    width: 128,
    height: 232,
    className: classNames(styles.icon, iconClass),
  }

  return (
    <Paper className={classNames(styles.root, className)}>
      {!rotate && <IconKoruLeft {...iconProps} />}
      {rotate && <IconKoruRight {...iconProps} />}
      <div className={styles.content}>{children}</div>
    </Paper>
  )
}
