import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconHome = React.memo(({ width, height, className }: Props) => (
  <svg
    className={`MuiSvgIcon-root ${className}`}
    viewBox="0 0 198 198"
    width={width}
    height={height}
    fill="currentColor"
  >
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.106 28.2307L184.209 85.6606L184.221 85.6494L196.232 97.7263C197.381 98.8811 198.019 100.447 198 102.063C197.985 103.694 197.316 105.245 196.148 106.375L195.297 107.198C192.93 109.482 189.178 109.467 186.837 107.158L99.0326 20.7219L11.1396 107.257C8.83273 109.527 5.14969 109.581 2.77353 107.386L1.95178 106.628C0.738949 105.507 0.0360023 103.941 0.00135001 102.291C-0.0333023 100.645 0.600339 99.0496 1.76367 97.8799L99.0277 0L127.106 28.2307ZM173.624 109.814L99.0326 35.4764L24.4411 109.814V198H74.3157V130.65H123.755V198H173.624V109.814ZM129.858 130.5H129.827V79.0714H129.858V130.5ZM93.9834 130.5H102.48V90.7126C115.912 92.9713 120.491 98.7272 121.85 102.924C123.383 107.645 121.312 113.059 117.54 114.137C113.222 115.361 110.061 110.844 111.558 110.21C112.721 113.088 117.402 112.381 117.256 108.184C117.155 105.153 113.179 102.691 109.894 103.908C107.415 104.826 105.707 108.214 106.223 111.492C106.914 115.878 112.248 121.321 119.357 119.354C126.458 117.387 130.383 108.782 127.206 98.9822C124.081 89.3502 116.246 82.9166 98.4389 80.9567C98.4348 80.9485 98.433 80.9403 98.4309 80.9308C98.4293 80.9235 98.4275 80.9153 98.4243 80.9057C98.3939 80.9091 98.365 80.9141 98.3354 80.9191L98.3354 80.9191C98.3016 80.925 98.2669 80.9309 98.2281 80.9348C98.1954 80.9312 98.1645 80.9257 98.1336 80.9203C98.1027 80.9148 98.0718 80.9093 98.0391 80.9057C98.0359 80.9153 98.0341 80.9235 98.0325 80.9309C98.0304 80.9403 98.0286 80.9485 98.0246 80.9567C80.2174 82.9166 72.3823 89.3502 69.2569 98.9822C66.0807 108.782 70.0056 117.387 77.1066 119.354C84.2149 121.321 89.5498 115.878 90.2403 111.492C90.7563 108.214 89.041 104.826 86.5698 103.908C83.2846 102.691 79.3089 105.153 79.2071 108.184C79.0618 112.381 83.7425 113.088 84.9054 110.21C86.4027 110.844 83.241 115.361 78.9237 114.137C75.1515 113.059 73.08 107.645 74.6136 102.924C75.9728 98.7272 80.5518 92.9713 93.9834 90.7126V130.5Z"
    />
  </svg>
))
