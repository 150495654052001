import React from 'react'
import { ResponsiveContainer, PieChart as RePieChart, Pie, Cell } from 'recharts'
import styles from './PieChart.module.scss'

interface TPie {
  name: string
  value: number
  color: string
}

interface Props {
  pies: TPie[]
  innerWidth: number
  outerWidth: number
  totalValue?: number
  children?: React.ReactNode
}

export const PieChart = ({ innerWidth, outerWidth, pies, totalValue, children }: Props) => {
  const size = outerWidth
  const center = outerWidth / 2
  const rest = totalValue ? totalValue - pies.reduce((sum, item) => sum + item.value, 0) : 0
  const restPie: TPie = { name: '', value: rest, color: '#CBCBCB' }
  const visiblePies = React.useMemo(() => [...pies, restPie], [pies, restPie])

  return (
    <div className={styles.root}>
      <ResponsiveContainer width={size} height={size}>
        <RePieChart width={size} height={size} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Pie
            dataKey={'value'}
            data={visiblePies}
            cx={center}
            cy={center}
            startAngle={90}
            endAngle={-270}
            innerRadius={innerWidth / 2}
            outerRadius={outerWidth / 2 - 1}
            paddingAngle={0}
          >
            {visiblePies.map(pie => (
              <Cell key={pie.name} stroke={pie.color} fill={pie.color} />
            ))}
          </Pie>
        </RePieChart>
      </ResponsiveContainer>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}
