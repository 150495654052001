import { appGetState, appUpdateState, initialState } from 'store'
import { TWhanau, create } from 'models/Whanau'
import { fetchList, fetchOneById } from 'api/WhanauApi'
import { IKWOData } from 'store/state'
import { fetchReport } from 'api/KWOApi'

export type TWhanauFilter = typeof initialState.whanau.list.filter

const comment = (m: string) => `WhanauService::${m}`

export const WHANAU_DETAIL_TAB1 = 'overview'
export const WHANAU_DETAIL_TAB2 = 'leading-healthy-lifestyles'
export const WHANAU_DETAIL_TAB3 = 'cohesive-resilient-nurturing'
export const WHANAU_DETAIL_TAB4 = 'participation-in-te-ao-maori'
export const WHANAU_DETAIL_TAB5 = 'participating-in-society'
export const WHANAU_DETAIL_TAB6 = 'economically-secure-and-nurturing'

class WhanauService {
  private appGetState = appGetState
  private appUpdateState = appUpdateState

  /**
   */
  public loadList = async (filter: TWhanauFilter) => {
    this.setFilter(filter)
    this.setIsLoaded(false)
    try {
      const listPromise = fetchList(filter)
      const { items, total } = await listPromise

      this.appUpdateState(s => {
        s.whanau.list.items = items
        s.whanau.list.total = total
      }, comment('loadList'))
    } catch (e) {
    }
    this.setIsLoaded(true)
  }

  /**
   */
  public loadMapList = async (filter: TWhanauFilter) => {
    this.setFilter(filter)
    this.setIsLoaded(false)
    try {
      filter.page = 0
      filter.size = 1000
      const listPromise = fetchList(filter)
      const { items } = await listPromise

      this.appUpdateState(s => {
        s.whanau.list.mapItems = items
      }, comment('loadMapList'))
    } catch (e) {
    }
    this.setIsLoaded(true)
  }

  /**
   */
  public loadDetail = async (id: string) => {
    const detail = this.appGetState().whanau.detail
    if (detail.whanau.id !== id) {
      this.setIsLoaded(false)
      try {
        this.setWhanauDetails(create())
        const whanauPromise = fetchOneById(id)

        const [
          whanau,
          tab1KWOData,
          tab2KWOData,
          tab3KWOData,
          tab4KWOData,
          tab5KWOData,
          tab6KWOData,
        ] = await Promise.all([
          whanauPromise,
          fetchReport(WHANAU_DETAIL_TAB1, id),
          fetchReport(WHANAU_DETAIL_TAB2, id),
          fetchReport(WHANAU_DETAIL_TAB3, id),
          fetchReport(WHANAU_DETAIL_TAB4, id),
          fetchReport(WHANAU_DETAIL_TAB5, id),
          fetchReport(WHANAU_DETAIL_TAB6, id),
        ])
        this.addKWO([tab1KWOData, tab2KWOData, tab3KWOData, tab4KWOData, tab5KWOData, tab6KWOData], whanau)
        this.setWhanauDetails(whanau)
      } catch (e) {}
      this.setIsLoaded(true)
    }
  }

  private addKWO = (kwo: IKWOData[][], whanau?: TWhanau) => {
    if (whanau) {
      kwo.forEach(i => {
        whanau.kwo.push(...i)
      })
    }
  }

  /**
   */
  private setIsLoaded = (isLoading: boolean): void => {
    this.appUpdateState(s => (s.whanau.isLoaded = isLoading), comment('setIsLoaded'))
  }

  /**
   */
  private setWhanauDetails = (item?: TWhanau): void => {
    this.appUpdateState(s => (s.whanau.detail.whanau = item ? { ...item } : create()), comment('setWhanauDetails'))
  }

  /**
   */
  private setFilter = (filter: TWhanauFilter): void => {
    this.appUpdateState(s => (s.whanau.list.filter = { ...filter }), comment('setFilter'))
  }
}

const whanauService = new WhanauService()
export { whanauService }
