import React from 'react'
import { Dayjs } from 'dayjs'
import styles from './Now.module.scss'

interface Props {
  minDay: Dayjs
  maxDay: Dayjs
}

export const GoalsPlanNow = React.memo(({ minDay, maxDay }: Props) => {
  const now = Date.now()
  const from = minDay.valueOf()
  const to = maxDay.valueOf()

  if (now < from || now > to) {
    return null
  }

  const left = `calc(${((now - from) / (to - from)) * 100}%)`

  return (
    <div className={styles.root}>
      <div className={styles.line} style={{ left }} />
    </div>
  )
})
