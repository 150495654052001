import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from '@material-ui/core'
import styles from './Actions.module.scss'

interface Props extends RouteComponentProps {
  isSubmitting: boolean
  submitText?: string
  cancelTo?: string
  onClickCancel?: () => void
  onClickSubmit?: () => void
}

const FormFrameActions = ({
  isSubmitting,
  submitText = 'Submit',
  cancelTo,
  onClickCancel,
  onClickSubmit,
  history: { push },
}: Props) => {
  const handleClickCancel = () => {
    cancelTo && push(cancelTo)
    onClickCancel && onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Button onClick={handleClickCancel}>Cancel</Button>

      <Button
        type="submit"
        disabled={isSubmitting}
        className={styles.submit}
        variant={'contained'}
        onClick={onClickSubmit}
      >
        {submitText}
      </Button>
    </div>
  )
}

const FormFrameActionsConnected = withRouter(FormFrameActions)
export { FormFrameActionsConnected as FormFrameActions }
