import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconPeople = React.memo(({ width, height, className }: Props) => (
  <svg
    className={`MuiSvgIcon-root ${className}`}
    viewBox="0 0 141 209"
    width={width}
    height={height}
    fill="currentColor"
  >
    <g opacity="0.3">
      <path d="M67.7852 142.834C76.7291 142.834 84.0074 135.555 84.0074 126.607C84.0074 117.662 76.7291 110.38 67.7852 110.38C58.8379 110.38 51.563 117.662 51.563 126.607C51.563 135.555 58.8379 142.834 67.7852 142.834Z" />
      <path d="M23.7028 80.3843C35.9376 80.3843 45.8943 70.4268 45.8943 58.1876C45.8943 45.9553 35.9376 35.9977 23.7028 35.9977C11.4646 35.9977 1.51123 45.9553 1.51123 58.1876C1.51123 70.4268 11.4646 80.3843 23.7028 80.3843Z" />
      <path d="M37.337 166.176C37.337 158.51 40.8702 151.507 46.7318 146.873C41.6643 141.61 38.5282 134.473 38.5282 126.605C38.5282 118.801 41.6172 111.714 46.6208 106.465C44.4841 99.7141 38.2085 94.9221 30.9201 94.9221H16.488C7.39943 94.9221 0 102.315 0 111.408V186.323C11.1849 194.349 23.7764 200.511 37.3336 204.377V166.176H37.337Z" />
      <path d="M52.308 207.669C62.5844 209.177 73.0493 209.405 83.3829 208.345C83.9482 208.288 84.5808 208.187 84.9106 207.729C85.1528 207.393 85.1697 206.952 85.173 206.538C85.348 193.882 85.3278 181.229 85.1024 168.572C85.0485 165.503 84.9341 162.259 83.2988 159.658C81.5423 156.878 78.2884 155.324 75.0312 154.866C69.3849 154.069 56.8271 153.984 52.7858 158.81C50.5549 161.472 50.4337 165.254 50.4236 168.731C50.3799 180.983 50.3395 193.236 50.2924 205.495C50.2891 205.976 50.2991 206.488 50.5717 206.892C50.9385 207.43 51.6619 207.575 52.308 207.669Z" />
      <path d="M97.0408 126.606C97.0408 134.467 93.9081 141.594 88.8506 146.861C94.5777 151.434 98.2286 158.467 98.2286 166.174V205.788C113.865 202.114 128.338 195.41 141 186.324V89.8349C141 78.4842 131.75 69.2434 120.38 69.2434H103.337C91.9867 69.2434 82.7534 78.4842 82.7534 89.8349V101.512C91.2935 106.627 97.0408 115.949 97.0408 126.606Z" />
      <path d="M111.868 55.0141C126.942 55.0141 139.214 42.7447 139.214 27.6654C139.214 12.5861 126.942 0.320064 111.868 0.320064C96.7928 0.320064 84.521 12.5895 84.521 27.6688C84.521 42.7481 96.7928 55.0141 111.868 55.0141Z" />
    </g>
  </svg>
))
