import React, { useState } from 'react'
import { getKWOMembers, TWhanau } from 'models/Whanau'
import { TGoal } from 'models/Goal'
import { TEvent } from 'models/Event'
import { Grid } from 'base/components/ui/Grid'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent1CardMemberList } from './tabContent1/CardMemberList'
import { TabContent1CardMemberTable } from './tabContent1/CardMemberTable'
import { TabContent1CardGoalsCurrent } from './tabContent1/CardGoalsCurrent'
import { TabContent1CardGoalsComplete } from './tabContent1/CardGoalsComplete'
import { TabContent1CardGoalsPlan } from './tabContent1/CardGoalsPlan'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchContacts, fetchGoals, sortByTsFrom } from 'api/GoalApi'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent1 = ({ whanau }: Props) => {
  const [goals, setGoals] = useState<TGoal[]>([])
  const [events, setEvents] = useState<TEvent[]>([])
  const [dataLoading, setDataLoading] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async (id: string) => {
      setDataLoading(true)
      const eventsPromise = fetchContacts(id)
      const goalsPromise = fetchGoals(id)
      setEvents(await eventsPromise)
      const goalSortedList = sortByTsFrom(await goalsPromise, 'asc')
      setGoals(goalSortedList)
      setDataLoading(false)
    }

    fetch(whanau.id).catch(window.logger.error)
  })

  const goalsComplete = goals.filter(i => !i.isCurrent)
  const goalsCurrent = goals.filter(i => i.isCurrent)
  return (
    <div>
      <Grid container mb={16}>
        <Heading3>Overview</Heading3>
      </Grid>

      <Grid container spacing={16} mb={48}>
        <Grid item xs={12} md={12} lg={6}>
          <TabContent1CardMemberList whanau={whanau} members={getKWOMembers(whanau)} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TabContent1CardMemberTable whanau={whanau} members={getKWOMembers(whanau)} />
        </Grid>
      </Grid>

      <Grid container mb={16}>
        <Heading3>Whānau Self Managing and Empowered Leaders</Heading3>
      </Grid>

      {(goals.length > 0 || events.length > 0) && (
        <>
          <Grid container mb={32}>
            <Grid item xs={12}>
              <TabContent1CardGoalsPlan goals={goals} events={events} isLoading={dataLoading} />
            </Grid>
          </Grid>

          {goals.length > 0 && (
            <Grid container spacing={16}>
              <Grid item xs={12} md={6} lg={8}>
                <TabContent1CardGoalsCurrent goals={goalsCurrent} isLoading={dataLoading} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TabContent1CardGoalsComplete goals={goalsComplete} isLoading={dataLoading} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  )
}
