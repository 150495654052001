import { authService } from 'services/AuthService'

/**
 * Application bootstrap
 * fetch initial state, verify tokens etc
 */
class Bootstrap {
  public async init(): Promise<void> {
    await authService.checkAuth()
  }
}

const bootstrap = new Bootstrap()
export { bootstrap }
