import * as React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styles from './PageTabs.module.scss'

type TabValue = number | string

interface TabItem<T> {
  value: T
  label: string
  content?: any
}

interface Props<T> {
  items: TabItem<T>[]
  initialTab?: T
  tab?: T
  onTabChange?: (tab: T) => void
}

interface State<T> {
  tab: T | null
}

export class PageTabs<T> extends React.PureComponent<Props<T>, State<T>> {
  public state: State<T> = {
    tab: this.props.initialTab || null,
  }

  public handleChange = (e: any, tab: T) => {
    const { onTabChange } = this.props
    onTabChange && onTabChange(tab)
    this.setState({ tab })
  }

  public render() {
    const { items } = this.props
    const tab = this.props.tab || this.state.tab

    return (
      <div className={styles.root}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            root: styles.header,
            indicator: styles.indicator,
            scrollButtons: styles.buttons,
            scroller: styles.scroller,
          }}
          value={tab}
          onChange={this.handleChange}
        >
          {items.map(item => (
            <Tab
              classes={{ root: styles.tab, selected: styles.selected }}
              key={item.value as any}
              value={item.value}
              label={item.label}
            />
          ))}
        </Tabs>

        {items.map(item => tab === item.value && <div key={item.value as any}>{item.content}</div>)}
      </div>
    )
  }
}
