import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import { getHowTheWhanauAreDoing, getTrafficColorLowest, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent3Card3 = ({ className, whanau }: Props) => (
  <CardLine
    title={String(getHowTheWhanauAreDoing(whanau))}
    content={'How the whānau are doing'}
    color={getTrafficColorLowest(whanau, 'how the whanau are doing')}
    className={className}
  />
)
