import { appUpdateState } from 'store'
import { fetchCollective } from '../api/CollectiveApi'

const comment = (m: string) => `CollectiveService::${m}`

class CollectiveService {
  private appUpdateState = appUpdateState

  /**
   */
  public load = async () => {
    this.setIsLoading(true)
    try {
      const items = await fetchCollective()
      this.appUpdateState(s => {
        s.collective.items = items
      }, comment('load'))
    } catch (e) {}
    this.setIsLoading(false)
  }

  /**
   */
  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(s => (s.collective.isLoading = isLoading), comment('setIsLoading'))
  }
}

const collectiveService = new CollectiveService()
export { collectiveService }
