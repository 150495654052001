import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import {
  getBenefitDuration,
  getBenefitType,
  getMSDFoodGrantsReceived,
  getNEET,
  getTotalDebtToGovernmentAgencies,
  getTrafficColorLowest,
  getTrainingReferral,
  getWorkObligation,
  TWhanau,
} from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent6Table = ({ className, whanau }: Props) => {
  return (
    <Card className={classNames(styles.root, className)}>
      <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
    </Card>
  )
}

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    { name: '16-24 year old NEET', code: '16-24 year old neet', value: getNEET(whanau), markers: { total: 1 } },
    {
      name: (
        <div className={styles.list}>
          <div>Benefit - Duration</div>
          <div>Work Obligation</div>
          <div>Training Referral</div>
        </div>
      ),
      code: 'training referral',
      value: (
        <div className={styles.list}>
          <div>
            {getBenefitType(whanau)} - {getBenefitDuration(whanau)}
          </div>
          <div>{getWorkObligation(whanau)}</div>
          <div>{getTrainingReferral(whanau)}</div>
        </div>
      ),
      markers: { total: 1 },
    },
    {
      name: 'MSD food grants received',
      code: 'msd food grants received',
      value: getMSDFoodGrantsReceived(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Total debt to government agencies',
      code: 'total debt to government agencies',
      value: getTotalDebtToGovernmentAgencies(whanau),
      markers: { total: 1 },
    },
  ]
}
