import React from 'react'
import { CardIcon } from 'components/shared/ui/CardIcon'
import { getTrafficColorLowest, trafficLightStatusByName, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent3Card1 = ({ className, whanau }: Props) => (
  <CardIcon
    iconType={3}
    color={trafficLightStatusByName('Family Harm Events', whanau)}
    title={'Cohesive, Resilient and Nurturing'}
    status={'Positive'}
    issues={[{ title: 'Family harm events', color: getTrafficColorLowest(whanau, 'family harm events') }]}
    className={className}
  />
)
