import * as React from 'react'
import classNames from 'classnames'
import styles from './Footer.module.scss'

interface Props {
  absolute?: boolean
}

export const Footer = React.memo(({ absolute }: Props) => (
  <div className={classNames(styles.root, absolute && styles.absolute)}>
    <div className={styles.links}>
      <button className={classNames(styles.btn, styles.link)}>
        About
      </button>
      <button className={classNames(styles.btn, styles.link)}>
        Terms of use
      </button>
      <button className={classNames(styles.btn, styles.link)}>
        Contact us
      </button>
    </div>
    <div className={styles.copy}>© Synergia {new Date().getFullYear()}. All rights reserved</div>
  </div>
))
