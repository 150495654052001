import React from 'react'
import { CardIcon } from 'components/shared/ui/CardIcon'
import { getTrafficColorLowest, trafficLightStatusByName, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent2Card1 = ({ className, whanau }: Props) => (
  <CardIcon
    iconType={1}
    color={trafficLightStatusByName('Enrolled At GP', whanau)}
    title={'Leading healthy lifestyles'}
    status={'Concerning'}
    issues={[
      { title: 'Enrolled at GP', color: getTrafficColorLowest(whanau, 'enrolled at gp') },
      { title: 'ED presentations', color: getTrafficColorLowest(whanau, 'ed presentations') },
    ]}
    className={className}
  />
)
