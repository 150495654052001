import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { Avatar } from 'base/components/ui/Avatar'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitIcon from '@material-ui/icons/ExitToApp'
import { TModel as TUser } from 'models/User'
import { authService } from 'services/AuthService'

export interface Props {
  user: TUser
}

class Profile extends React.PureComponent<Props> {
  public handleClickLogout = async () => {
    await authService.logout()
  }

  public render() {
    const { user } = this.props

    return (
      <>
        <ListItem dense>
          <Avatar user={user} />
          <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
        </ListItem>
        <Divider />
        <ListItem button onClick={this.handleClickLogout}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </>
    )
  }
}

const mapStateToProps = (state: IState): Props => ({
  user: state.app.currentUser,
})

const ProfileConnected = connect(mapStateToProps)(Profile)
export { ProfileConnected as Profile }
