import { request } from 'base/utils/http/Request'
import { ASSIGN_ROLE, CHANGE_SUSPEND_USER, SEND_PASSWORD_RESET, CHANGE_PASSWORD, USERS } from 'config/paths'
import { TModel as TUser, map, toServerObject } from 'models/User'
import { TUserFilter } from 'services/UserService'

export const fetchMany = async (filter: TUserFilter) => {
  if (!filter['user.role']) {
    delete filter['user.role']
  }
  if (!filter['user.email']) {
    delete filter['user.email']
  }

  const data = await request.get(USERS, filter)
  const total = data.total || 0
  const items = data.list.map(map)
  return { items, total }
}

export const fetchOneById = async (id: string): Promise<TUser> => {
  const data = await request.get(`${USERS}/${id}`)
  return map(data.user)
}

export const save = async (user: TUser, oldUser: TUser): Promise<TUser> => {
  const { username } = user.id
    ? await request.put(`${USERS}/${user.id}`, toServerObject(user))
    : await request.post(USERS, toServerObject(user))

  const nextUser = { ...user, id: username || user.id }
  if (oldUser.role !== user.role) {
    try {
      await request.put(ASSIGN_ROLE, { role: user.role, username: username || user.id })
    } catch (error) {
      nextUser.role = oldUser.role
    }
  }
  return nextUser
}

export const resetPassword = async (user: TUser): Promise<void> => {
  await request.put(SEND_PASSWORD_RESET, { email: user.email })
}

export const changePassword = async (email: string, newPassword: string, resetToken: string): Promise<TObjectAny> => {
  return await request.put(CHANGE_PASSWORD, { email, new_password: newPassword, reset_token: resetToken })
}

export const suspend = async (user: TUser, suspend: boolean): Promise<TUser> => {
  await request.put(CHANGE_SUSPEND_USER, { suspend, username: user.id })
  return { ...user, suspended: suspend }
}
