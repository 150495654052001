import React from 'react'
import {
  ResponsiveContainer,
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { Legend } from './Legend'
import styles from './ChartsCommon.module.scss'

interface TBar {
  name: string
  color: string
  field: string
}

interface TDataItem {
  name: string
  axisLabel: string[]
  [key: string]: any
}

interface Props {
  title?: string
  withLegend?: boolean
  stack?: boolean
  height: number
  data: TDataItem[]
  bars: TBar[]
  canvasMargin?: number | string
}

export const BarChart = ({ title, withLegend, data, bars, stack, height, canvasMargin }: Props) => (
  <div className={styles.root}>
    <div className={styles.head}>
      <div className={styles.title}>{title}</div>
      {withLegend && <Legend items={bars} />}
    </div>

    <div className={styles.canvas} style={{ margin: canvasMargin }}>
      <ResponsiveContainer width={'100%'} height={height}>
        <ReBarChart data={data} barGap={0} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis
            dataKey="name"
            stroke="#E0E0E0"
            padding={{ left: 32, right: 32 }}
            tickLine={false}
            height={60}
            tick={<XAxisTick data={data} />}
          />
          <YAxis
            stroke="#E0E0E0"
            tickLine={false}
            width={60}
            tick={<YAxisTick data={data} />}
          />
          <Tooltip cursor={false} />
          {bars.map(bar => (
            <Bar
              key={bar.name}
              dataKey={bar.field}
              stackId={stack ? 'a' : undefined}
              fill={bar.color}
              maxBarSize={70}
            />
          ))}
        </ReBarChart>
      </ResponsiveContainer>
    </div>
  </div>
)

const XAxisTick = ({ x, y, stroke, payload, data }: any) => {
  const item = data[payload.index]
  return (
    item && (
      <g transform={`translate(${x},${y + 24})`}>
        {item.axisLabel[0] && <XAxisTickText text={item.axisLabel[0]} />}
        {item.axisLabel[1] && <XAxisTickText text={item.axisLabel[1]} dy={24} />}
      </g>
    )
  )
}

const XAxisTickText = ({ text, dy = 0 }: any) =>
  text && (
    <text x={0} y={dy} textAnchor="middle" fill="#5f5f5f" fontSize={12}>
      {text}
    </text>
  )

const YAxisTick = ({ x, y, stroke, payload, data }: any) => (
  <g transform={`translate(${x - 10},${y})`}>
    <text x={0} y={0} textAnchor="end" fill="#5f5f5f" fontSize={12}>
      {payload.value}
    </text>
  </g>
)
