import React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { RouteComponentProps } from 'react-router'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Loader } from 'base/components/ui/Loader'
import { ViewWhanauDetailTabs } from 'components/views/viewWhanauDetail/Tabs'
import { ViewWhanauDetailTabContent1 } from './viewWhanauDetail/TabContent1'
import { ViewWhanauDetailTabContent2 } from './viewWhanauDetail/TabContent2'
import { ViewWhanauDetailTabContent3 } from './viewWhanauDetail/TabContent3'
import { ViewWhanauDetailTabContent4 } from './viewWhanauDetail/TabContent4'
import { ViewWhanauDetailTabContent5 } from './viewWhanauDetail/TabContent5'
import { ViewWhanauDetailTabContent6 } from './viewWhanauDetail/TabContent6'
import { TWhanau } from 'models/Whanau'
import { layoutService } from 'services/LayoutService'
import { whanauService } from 'services/WhanauService'
import { useOnMount } from 'base/hooks/useOnMount'
import { useQueryHashValue } from 'base/hooks/useQueryHashValue'

interface Props {
  id: string
  whanau: TWhanau
  isLoaded: boolean
  load: (id: string) => void
}

const ViewWhanauDetail = React.memo((props: Props & RouteComponentProps) => {
  const { id, whanau, isLoaded, load } = props
  const [tab, setTab] = useQueryHashValue('tab', 'tab1')

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Whānau', url: '/whanau' }, { label: id }])
    load(id)
  })
  return (
    <LayoutApp>
      <ViewWhanauDetailTabs tab={tab} onTabChange={setTab} />
      <PageFrame simple>
        {!isLoaded ? (
          <Loader absolute />
        ) : (
          <>
            {tab === 'tab1' && <ViewWhanauDetailTabContent1 whanau={whanau} />}
            {tab === 'tab2' && <ViewWhanauDetailTabContent2 whanau={whanau} />}
            {tab === 'tab3' && <ViewWhanauDetailTabContent3 whanau={whanau} />}
            {tab === 'tab4' && <ViewWhanauDetailTabContent4 whanau={whanau} />}
            {tab === 'tab5' && <ViewWhanauDetailTabContent5 whanau={whanau} />}
            {tab === 'tab6' && <ViewWhanauDetailTabContent6 whanau={whanau} />}
          </>
        )}
      </PageFrame>
    </LayoutApp>
  )
})

const mapStateToProps = (state: IState, ownProps: any): Props => ({
  id: ownProps.match.params.id,
  whanau: state.app.whanau.detail.whanau,
  isLoaded: state.app.whanau.isLoaded,
  load: whanauService.loadDetail,
})

const ViewWhanauDetailConnected = connect(mapStateToProps)(ViewWhanauDetail)

export { ViewWhanauDetailConnected as ViewWhanauDetail }
