import React from 'react'
import styles from 'components/shared/ui/CloseButton.module.scss'
import classNames from 'classnames'

interface Props {
  onClick: (e: React.MouseEvent) => void
  className?: string
}

export const CloseButton = ({ onClick, className }: Props) => (
  <div className={classNames(styles.root, className)} onClick={onClick} />
)
