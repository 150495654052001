import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Pagination } from 'base/components/ui/Pagination'
import { Table, TableBody, TableCell, TableFooter, TableRow } from '@material-ui/core'
import { IconManQueue } from 'components/shared/icons/IconManQueue'
import { TWhanau, getLink, trafficLights } from 'models/Whanau'
import { TableHeader } from './table/Header'
import styles from './Table.module.scss'
import { Loader } from 'base/components/ui/Loader'

interface Props {
  items: TWhanau[]
  page: number
  size: SizeOption
  total: number
  isLoaded: boolean
  onChange: (page: number, size: SizeOption) => void
}

export const ViewWhanauListTable = ({ items, page, size, total, onChange, isLoaded }: Props) => {
  return (
    <div className={styles.root}>
      <Table className={styles.table}>
        <TableHeader />
        <TableBody />
        <TableBody className={styles.body}>
          {!isLoaded && (
            <TableRow>
              <TableCell colSpan={10} className={styles.loader}>
                <Loader absolute />
              </TableCell>
            </TableRow>
          )}
          {isLoaded &&
            items.map(item => (
              <TableRow key={item.id}>
                <TableCell className={styles.name}>
                  <Link className={styles.link} to={getLink(item)}>
                    {item.name}
                  </Link>
                </TableCell>
                <TableCell>{item.time}</TableCell>
                <TableCell className={styles.men}>
                  <IconManQueue count={item.num} width={14} />
                </TableCell>
                <TableCell>{item.status}</TableCell>
                {trafficLights(item).map((i, idx) => (
                  <TableCell key={`${idx}:${i.name}`} className={styles.stat}>
                    <div
                      className={classNames(styles.statIcon, {
                        [styles.redAlert]: i.colorStatus === 'redAlert',
                        [styles.concerning]: i.colorStatus === 'concerning',
                        [styles.positive]: i.colorStatus === 'positive',
                      })}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination colSpan={10} page={page} size={size} total={total} onChange={onChange} />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  )
}
