import React from 'react'
import IconSearch from '@material-ui/icons/Search'
import {
  Checkbox,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormControl,
} from '@material-ui/core'
import styles from './Fields.module.scss'

interface BaseProps<T> {
  label: string
  name: keyof T
  isExtra?: boolean
  filter: T
  filterUpdate: (f: T) => void
}

interface SearchProps<T> extends BaseProps<T> {}

export function ListingFilterFieldSearch<T>({ label, name, isExtra, filter, filterUpdate }: SearchProps<T>) {
  const inputLabelProps = { classes: { root: styles.labelRoot, shrink: styles.labelShrink } }

  return (
    <div className={isExtra ? styles.rootExtra : styles.rootMain}>
      <TextField
        fullWidth
        label={label}
        type={'text'}
        value={filter[name] || ('' as any)}
        InputLabelProps={inputLabelProps}
        InputProps={{
          classes: { root: styles.inputRoot },
          endAdornment: (
            <InputAdornment className={styles.inputAdornment} position="end">
              <IconSearch />
            </InputAdornment>
          ),
        }}
        onChange={e => filterUpdate({ ...filter, [name]: e.target.value })}
      />
    </div>
  )
}

export interface FieldSelectOption {
  value: any
  label: string
}

interface FieldSelect<T> extends BaseProps<T> {
  options: FieldSelectOption[]
  withEmptyOption?: boolean
}

export function ListingFilterFieldSelect<T>({
  label,
  name,
  isExtra,
  options,
  filter,
  filterUpdate,
  withEmptyOption = true,
}: FieldSelect<T>) {
  const inputProps = { classes: { root: styles.inputRoot } }
  const inputLabelProps = { classes: { root: styles.labelRoot, shrink: styles.labelShrink } }
  const selectClasses = { root: styles.selectRoot, selectMenu: styles.selectMenu, icon: styles.selectIcon }
  const selectProps = { classes: selectClasses, displayEmpty: true }

  return (
    <div className={isExtra ? styles.rootExtra : styles.rootMain}>
      <TextField
        select
        fullWidth
        label={label}
        value={filter[name] || ('' as any)}
        InputLabelProps={inputLabelProps}
        SelectProps={selectProps}
        InputProps={inputProps}
        onChange={e => filterUpdate({ ...filter, [name]: e.target.value })}
      >
        {withEmptyOption && <MenuItem />}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export function ListingFilterFieldMultiSelect<T>({
  label,
  name,
  isExtra,
  options,
  filter,
  filterUpdate,
}: FieldSelect<T>) {
  const labelClasses = { root: styles.labelRoot, shrink: styles.labelShrink }
  return (
    <div className={isExtra ? styles.rootExtra : styles.rootMain}>
      <FormControl classes={{ root: styles.formControl }}>
        <InputLabel htmlFor="select-multiple" classes={labelClasses}>
          {label}
        </InputLabel>
        <Select
          multiple
          value={filter[name] || ([] as any)}
          onChange={e => filterUpdate({ ...filter, [name]: e.target.value })}
          input={<Input id="select-multiple" />}
          renderValue={selected => {
            let result: string[] = []
            if (Array.isArray(selected)) {
              result = selected
                .map(i => {
                  const option = options.find(o => o.value === i)
                  if (option) {
                    return option.label
                  }
                  return null
                })
                .filter(i => i !== null) as string[]
            }

            return result.join(', ')
          }}
        >
          {options.map(option => {
            const selected = filter[name]
            let isSelected = false
            if (Array.isArray(selected)) {
              isSelected = selected.findIndex(i => i === option.value) !== -1
            }
            return (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={isSelected} />
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

interface FieldCheckbox<T> extends BaseProps<T> {}

export function ListingFilterFieldCheckbox<T>({ label, name, isExtra, filter, filterUpdate }: FieldCheckbox<T>) {
  return (
    <div className={isExtra ? styles.rootExtra : styles.rootMain}>
      <FormControlLabel
        classes={{ root: styles.checkbox, label: styles.labelCheckbox }}
        control={
          <Checkbox
            checked={filter[name] || (false as any)}
            onChange={e => filterUpdate({ ...filter, [name]: e.target.checked ? true : null })}
            value={'checked'}
          />
        }
        label={label}
      />
    </div>
  )
}
