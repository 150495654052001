import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import { getNEET, getTrafficColorLowest, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent6Card3 = ({ className, whanau }: Props) => {
  return (
    <CardLine
      title={`${getNEET(whanau)} NEET`}
      content={'16-24 year old NEET'}
      color={getTrafficColorLowest(whanau, '16-24 year old neet')}
      className={className}
    />
  )
}
