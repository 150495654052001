import React from 'react'
import { Dayjs } from 'dayjs'
import { mem } from 'base/utils/mem'
import memoizeOne from 'memoize-one'
import { TGoal, TGoalArea, createArea } from 'models/Goal'
import { StatusGoalRow } from './status/GoalRow'
import styles from './Status.module.scss'

interface Props {
  goals: TGoal[]
  minDay: Dayjs
  maxDay: Dayjs
  onGoalClick: (id: string) => void
}

export const GoalsPlanStatus = mem(({ goals, minDay, maxDay, onGoalClick }: Props) => {
  const goalRows = getGoalRows(goals, minDay.valueOf(), maxDay.valueOf())
  // window.logger.info(goalRows.slice(0, 2))
  return (
    <div className={styles.root}>
      <div className={styles.label}>Goal status</div>
      {goalRows.map(goalRow => (
        <StatusGoalRow key={goalRow[0].item.id} goalRow={goalRow} onGoalClick={onGoalClick} />
      ))}
    </div>
  )
})

const getGoalRows = memoizeOne(
  (goals: TGoal[], start: number, end: number): TGoalArea[][] => {
    const goalRows: TGoalArea[][] = []
    const now = Date.now()

    goals.forEach(goal => {
      const goalArea = createArea({
        item: goal,
        dx: ((goal.tsFrom - start) / (end - start)) * 100,
        width: ((goal.tsTo - goal.tsFrom) / (end - start)) * 100,
        complete: now >= goal.tsTo,
        today: ((now - goal.tsFrom) / (goal.tsTo - goal.tsFrom)) * 100,
      })
      goalRows.push([goalArea])

      // if (!mergeGoalArea(goalArea, goalRows)) {
      //   goalRows.push([goalArea])
      // }
    })

    return goalRows
  }
)

export const mergeGoalArea = (goalArea: TGoalArea, goalRows: TGoalArea[][]): boolean => {
  const dx1 = goalArea.dx
  const dx2 = goalArea.dx + goalArea.width
  let merged = false

  goalRows.forEach(goalRow => {
    let hasEmptySpace = true

    goalRow.forEach(otherArea => {
      const odx1 = otherArea.dx - 10 // with gap
      const odx2 = otherArea.dx + otherArea.width + 10 // with gap

      if (odx2 >= dx1 && odx1 <= dx2) {
        hasEmptySpace = false
      }
    })

    if (hasEmptySpace) {
      goalRow.push(goalArea)
      merged = true
    }
  })

  return merged
}
