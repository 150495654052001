import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { HorizontalBarItem } from 'components/shared/ui/charts/HorizontalBarItem'
import styles from './TabContent4Common.module.scss'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

export const TabContent4Card5 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('enrolments-primary-intermediates')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card title={'Number of enrolments at primary and intermediate schools'} padding={24} className={styles.root}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        {data.map(item => (
          <HorizontalBarItem
            key={`${item.measure}-${item.dimension}`}
            title={item.dimension}
            titleWidth={'100%'}
            color={'#36B9B1'}
            value={item.num_val}
            renderValue={v => null}
            titlePosition={'top'}
            max={100}
            classes={{ root: styles.barItem, title: styles.title, bar: styles.bar }}
          />
        ))}
      </LoaderPane>
    </Card>
  )
}
