import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import { getEDPresentations, getTrafficColorLowest, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent2Card3 = ({ className, whanau }: Props) => (
  <CardLine
    title={String(getEDPresentations(whanau))}
    content={'ED presentations'}
    color={getTrafficColorLowest(whanau, 'ed presentations')}
    className={className}
  />
)
