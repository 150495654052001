import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Grid } from 'base/components/ui/Grid'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent3Card1 } from 'components/views/viewWhanauDetail/tabContent3/Card1'
import { TabContent3Card2 } from 'components/views/viewWhanauDetail/tabContent3/Card2'
import { TabContent3Card3 } from 'components/views/viewWhanauDetail/tabContent3/Card3'
import { TabContent3Table1 } from 'components/views/viewWhanauDetail/tabContent3/Table1'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent3 = (props: Props) => (
  <div>
    <Grid container mb={16}>
      <Heading3>Cohesive, Resilient and Nurturing</Heading3>
    </Grid>

    <Grid container spacing={16} mb={8}>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent3Card1 whanau={props.whanau} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent3Card2 whanau={props.whanau} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent3Card3 whanau={props.whanau} />
      </Grid>
    </Grid>

    <Grid container>
      <TabContent3Table1 whanau={props.whanau} />
    </Grid>
  </div>
)
