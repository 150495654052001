import dayjs from 'dayjs'

type TEventType = 'inperson' | 'phone' | 'email'

const initialData = {
  id: '',
  name: '',
  type: 'meeting' as TEventType,
  date: '1970-01-01',
  ts: 0,
}

export type TEvent = typeof initialData

export const create = (data?: Partial<TEvent>): TEvent => ({
  ...initialData,
  ...data,
  ts: Date.parse((data && data.date) || initialData.date),
})

export const map = (data: TObjectAny) => {
  return {
    id: data.id,
    name: data.navigator,
    type: String(data.type).toLowerCase(),
    date: dayjs(data.date).format('YYYY-MM-DD'),
    ts: data.date,
  }
}


// date: 1557100800000
// id: "13d5cce7-dfd6-4a7a-9c90-ac5ba13c32fd"
// navigator: "SHAY"
// number: 1
// type: "INPERSON"
// whanau_id: "FGN00100"
