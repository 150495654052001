import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Pagination } from 'base/components/ui/Pagination'
import { CardWhanauStats } from 'components/shared/ui/CardWhanauStats'
import { Card } from '@material-ui/core'
import styles from './Cards.module.scss'

interface Props {
  items: TWhanau[]
  page: number
  size: SizeOption
  total: number
  isLoaded?: boolean
  onChange: (page: number, size: SizeOption) => void
}

export const ViewWhanauListCards = ({ items, page, size, total, onChange }: Props) => {
  return (
    <div className={styles.root}>
      {items.map(i => (
        <Card key={i.id} className={styles.item}>
          <CardWhanauStats item={i} className={styles.card} />
        </Card>
      ))}

      <Pagination
        component={'div'}
        colSpan={10}
        page={page}
        size={size}
        total={total}
        onChange={onChange}
      />
    </div>
  )
}
