import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconHome = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 26 27" width={width} height={height} fill="currentColor">
    <path
      d="M11.25 22.7499V16.2499H15.75V22.7499H21.375V14.0833H24.75L13.5 4.33325L2.25 14.0833H5.625V22.7499H11.25Z"
      fill="#000"
      opacity="0.54"
    />
  </svg>
))
