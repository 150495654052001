import { request } from '../base/utils/http/Request'
import { REPORT_TABLES, CHART_DATA } from '../config/paths'
import { IChartData, IKWOData } from 'store/state'

export const fetchMemberChart = async (kwoId: string, name: string): Promise<IChartData[]> => {
  return (await request.get(`${CHART_DATA}`, {
    name,
    kwo_id: kwoId,
  })).dates as IChartData[]
}

export const fetchMember = async (kwoId: string, name: string): Promise<IKWOData[]> => {
  return (await request.get(`${REPORT_TABLES}`, {
    name,
    kwo_id: kwoId,
  })).rows as IKWOData[]
}
