import React from 'react'
import { Subheader } from './Subheader'
import {
  FieldSelectOption,
  ListingFilterFieldCheckbox,
  ListingFilterFieldMultiSelect,
  ListingFilterFieldSearch,
  ListingFilterFieldSelect,
} from './listingFilter/Fields'
import { ListingFilterExtra } from './listingFilter/Extra'
import { ListingFilterExtraSelected } from './listingFilter/ExtraSelected'
import { useWindowSize } from 'base/hooks/useWindowSize'

export interface TFilterSchemeItem<T> {
  type: 'search' | 'select' | 'multiselect' | 'checkbox'
  name: keyof T
  label: string
  options?: FieldSelectOption[]
  default?: any
  position: 'main' | 'extra'
}

export interface Props<T> {
  filter: T
  scheme: TFilterSchemeItem<T>[]
  className?: string
  staticPosition?: boolean
  filterUpdate: (filter: T) => void
}

export function ListingFilter<T>({ filter, scheme, className, staticPosition, filterUpdate }: Props<T>) {
  const { width } = useWindowSize()
  const isDesktop = width > 1024

  const extraMap = scheme
    .filter(i => i.position === 'extra' || !isDesktop)
    .map(i => ({ name: i.name, label: i.label, default: i.default, options: i.options, type: i.type }))

  return (
    <Subheader staticPosition={staticPosition} className={className}>
      {scheme.map(
        schemeItem =>
          schemeItem.position === 'main' &&
          isDesktop && (
            <ListingFilterItem<T>
              key={schemeItem.name as any}
              schemeItem={schemeItem}
              filter={filter}
              filterUpdate={filterUpdate}
            />
          )
      )}

      <ListingFilterExtraSelected
        filter={filter}
        extraMap={extraMap as any}
        filterUpdate={filterUpdate}
      />

      <ListingFilterExtra<T> filter={filter} filterUpdate={filterUpdate} label={isDesktop ? 'More Filters' : 'Filters'}>
        {(f, u) =>
          scheme.map(
            schemeItem =>
              (schemeItem.position === 'extra' || !isDesktop) && (
                <ListingFilterItem<T>
                  isExtra
                  key={schemeItem.name as any}
                  schemeItem={schemeItem}
                  filter={f}
                  filterUpdate={u}
                />
              )
          )
        }
      </ListingFilterExtra>
    </Subheader>
  )
}

interface ItemProps<T> {
  schemeItem: TFilterSchemeItem<T>
  isExtra?: boolean
  filter: T
  filterUpdate: (filter: T) => void
}

function ListingFilterItem<T>({ schemeItem, isExtra, filter, filterUpdate }: ItemProps<T>) {
  if (schemeItem.type === 'search') {
    return (
      <ListingFilterFieldSearch<T>
        isExtra={isExtra}
        name={schemeItem.name}
        label={schemeItem.label}
        filter={filter}
        filterUpdate={filterUpdate}
      />
    )
  }

  if (schemeItem.type === 'select') {
    return (
      <ListingFilterFieldSelect<T>
        isExtra={isExtra}
        name={schemeItem.name}
        label={schemeItem.label}
        options={schemeItem.options || []}
        filter={filter}
        filterUpdate={filterUpdate}
      />
    )
  }

  if (schemeItem.type === 'multiselect') {
    return (
      <ListingFilterFieldMultiSelect<T>
        isExtra={isExtra}
        name={schemeItem.name}
        label={schemeItem.label}
        options={schemeItem.options || []}
        filter={filter}
        filterUpdate={filterUpdate}
      />
    )
  }

  if (schemeItem.type === 'checkbox') {
    return (
      <ListingFilterFieldCheckbox<T>
        isExtra={isExtra}
        name={schemeItem.name}
        label={schemeItem.label}
        filter={filter}
        filterUpdate={filterUpdate}
      />
    )
  }

  return null
}
