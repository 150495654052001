import React from 'react'
import classNames from 'classnames'
import { connect, Omit } from 'react-redux'
import { IState } from 'store'
import styles from './Subheader.module.scss'

interface Props {
  isExpanded: boolean
  children: React.ReactNode
  className?: string
  staticPosition?: boolean
}

const Subheader = ({ isExpanded, className, staticPosition, children }: Props) => {
  const [ref, setRef] = React.useState(null as null | HTMLDivElement)
  return (
    <div style={staticPosition ? {} : { height: ref ? ref.clientHeight : 0 }}>
      <div
        ref={setRef}
        className={classNames(styles.root, isExpanded && styles.expanded, staticPosition && styles.static, className)}
      >
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = (state: IState, ownProps: Omit<Props, 'isExpanded'>): Props => ({
  children: ownProps.children,
  className: ownProps.className,
  staticPosition: ownProps.staticPosition,
  isExpanded: state.app.layout.isExpanded,
})

const SubheaderConnected = connect(mapStateToProps)(Subheader)

export { SubheaderConnected as Subheader }
