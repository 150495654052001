import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import classNames from 'classnames'
import { LayoutAppHeader } from './layoutApp/Header'
import { LayoutAppSidebar } from './layoutApp/Sidebar'
import { Footer } from 'components/shared/ui/Footer'
import { Dialog } from 'base/components/ui/Dialog'
import { Snackbar } from 'base/components/ui/Snackbar'
import { useWindowSize } from 'base/hooks/useWindowSize'
import { useScroll } from 'base/hooks/useScroll'
import { layoutService } from 'services/LayoutService'
import { TModel as TUser } from 'models/User'
import styles from './LayoutApp.module.scss'

interface Props {
  children: any
  isExpanded: boolean
  isHeaderLarge: boolean
  currentUser: TUser
}

const LayoutApp = ({ isExpanded, isHeaderLarge, currentUser, children }: Props) => {
  const [isOpened, setIsOpened] = React.useState(false)
  const { width } = useWindowSize()
  const scroll = useScroll()
  const isDesktop = width > 960
  const isLargeHeader = isDesktop && isHeaderLarge && scroll === 0

  const mainClasses = [
    styles.main,
    { [styles.mainExpanded]: isDesktop && isExpanded },
    { [styles.mainLarge]: isLargeHeader },
  ]

  return (
    <div className={styles.root}>
      <LayoutAppHeader
        isLarge={isLargeHeader}
        isDesktop={isDesktop}
        isExpanded={isDesktop && isExpanded}
        onOpenToggle={setIsOpened}
      />
      <LayoutAppSidebar
        currentUser={currentUser}
        isDesktop={isDesktop}
        isExpanded={!isDesktop || isExpanded}
        isOpened={isOpened}
        onExpandToggle={layoutService.expandSidebarToggle}
        onOpenToggle={setIsOpened}
      />
      <div className={classNames(mainClasses)}>
        <div className={styles.content}>{children}</div>
        <Footer />
        <Dialog />
        <Snackbar />
      </div>
    </div>
  )
}

const mapStateToProps = (state: IState, ownProps: Pick<Props, 'children'>): Props => ({
  currentUser: state.app.currentUser,
  isExpanded: state.app.layout.isExpanded,
  isHeaderLarge: state.app.layout.isHeaderLarge,
  children: ownProps.children,
})

const LayoutAppConnected = connect(mapStateToProps)(LayoutApp)
export { LayoutAppConnected as LayoutApp }
