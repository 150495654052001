import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { HorizontalBarItem } from 'components/shared/ui/charts/HorizontalBarItem'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { useOnMount } from 'base/hooks/useOnMount'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

const colorScheme = new Map<string, string>([
  ['pepi', '#26C6DA'],
  ['tamariki', '#36B9B1'],
  ['rangatahi', '#2B6A7D'],
  ['maatua', '#0A867E'],
  ['kaumatua', '#3C9F76'],
])

export const TabContent1AgeGroup = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('age-group')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  const max = Math.max(...data.map(i => i.num_val))

  return (
    <Card title={'Age group'} padding={24}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        {data.map(item => (
          <HorizontalBarItem
            key={`${item.measure}-${item.dimension}`}
            title={item.dimension}
            titleWidth={80}
            color={colorScheme.get(item.dimension.toLowerCase())}
            value={item.num_val}
            max={max}
          />
        ))}
      </LoaderPane>
    </Card>
  )
}
