import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { PieChart } from 'components/shared/ui/charts/PieChart'
import { Legend } from 'components/shared/ui/charts/Legend'
import styles from './Card1.module.scss'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

export const TabContent4Card1 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('enrollment')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  const pies = data ? getPies(data) : []

  return (
    <Card title={'School enrolment'} padding={24} className={styles.root}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
      <div className={styles.content}>
        <div className={styles.chart}>
          <PieChart
            pies={pies}
            outerWidth={170}
            innerWidth={140}
          />
        </div>
        <div className={styles.legend}>
          <Legend
            vertical
            items={pies}
          />
        </div>
      </div>
      </LoaderPane>
    </Card>
  )
}

const getPies = (data?: IReportBreakDown[]) => [
  { name: 'Enrolled - Primary School', value: 50, color: '#26C6DA' },
  { name: 'Enrolled - Intermediate or High School', value: 25, color: '#36B9B1' },
  { name: 'ECE - number of <5 year olds', value: 25, color: '#0A867E' },
  { name: 'Not enrolled', value: 0, color: '#9B9B9B' },
]
