import React from 'react'
import { Loader } from 'base/components/ui/Loader'
import { NoData } from './NoData'

interface Props {
  isLoaded: boolean
  isData: boolean
  noDataMessage?: string
}

export class LoaderPane extends React.PureComponent<Props> {
  public render() {
    const { isLoaded, isData, children, noDataMessage = 'No Data' } = this.props

    return (
      <>
        {!isLoaded ? (
          <Loader absolute />
        ) : isData ? (
          children
        ) : (
          <NoData message={noDataMessage} />
        )}
      </>
    )
  }
}
