import React from 'react'
import { mem } from 'base/utils/mem'
import { TGoalArea } from 'models/Goal'
import { GoalRowItem } from './goalRow/Item'
import styles from './GoalRow.module.scss'

interface Props {
  goalRow: TGoalArea[]
  onGoalClick: (id: string) => void
}

export const StatusGoalRow = mem(({ goalRow, onGoalClick }: Props) => (
  <div className={styles.root}>
    {goalRow.map(goalArea => (
      <GoalRowItem key={goalArea.item.id} goalArea={goalArea} onGoalClick={onGoalClick} />
    ))}
  </div>
))
