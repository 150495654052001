import React from 'react'
import classNames from 'classnames'
import { Grid } from 'base/components/ui/Grid'
import { Card } from 'base/components/ui/Card'
import { GrowProgress } from 'components/shared/ui/GrowProgress'
import styles from './Card.module.scss'

interface Props {
  title: string
  description: string
  score: number
}

export const ViewCollectiveCard = ({ title, score, description }: Props) => {
  const [status, statusStyle] = getStatus(score)

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card className={styles.root} title={title}>
        <div className={styles.head}>
          <div>
            <div className={classNames(styles.status, statusStyle)}>{status}</div>
          </div>
          <GrowProgress score={score} />
        </div>
        <div className={styles.description}>{description}</div>
        <div className={classNames(styles.border, statusStyle)} />
      </Card>
    </Grid>
  )
}

const getStatus = (score: number): [string, string] => {
  if (score < 5) {
    return ['None', styles.none]
  }
  if (score < 17) {
    return ['Emerging', styles.emerging]
  }
  return ['Mature', styles.mature]
}
