import React from 'react'
import MuiGrid, { GridProps } from '@material-ui/core/Grid'

type TSpacing = 0 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64

interface Props extends GridProps {
  mt?: TSpacing
  mb?: TSpacing
}

export const Grid = (props: Props) => {
  const styles: React.CSSProperties = {}
  if (props.mt) {
    styles.marginTop = props.mt
  }
  if (props.mb) {
    styles.marginBottom = props.mb
  }

  return (
    <MuiGrid {...props} style={styles}>
      {props.children}
    </MuiGrid>
  )
}
