import React from 'react'
import * as yup from 'yup'
import { Formik, FormikActions } from 'formik'
import { FormFrame } from 'base/components/ui/FormFrame'
import { FieldSelect, FieldText } from 'base/components/ui/formFrame/Fields'
import { FormFrameActions } from 'base/components/ui/formFrame/Actions'
import { TModel as TUser, roleOptions } from 'models/User'
import styles from './Form.module.scss'
import { Avatar } from '../../../base/components/ui/Avatar'

interface Props {
  item: TUser
  action: 'view' | 'edit'
  isLoading: boolean
  onSubmit: (item: TUser, oldItem: TUser) => void
}

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: 0,
}

const validationScheme = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  role: yup.number().required(),
})

type TFormValues = typeof formInitialValues
type TFormAction = FormikActions<TFormValues>

const cancelTo = (item: TUser, action: string) => {
  let result = `/users/${item.id}/view`

  if (!item.id || action === 'view') {
    result = `/users`
  }

  return result
}

export const ViewUserEditForm = ({ isLoading, item, onSubmit, action }: Props) => {
  const submit = async (values: TFormValues, { setSubmitting }: TFormAction) => {
    const nextItem = { ...item, ...values }
    await onSubmit(nextItem, item)
    setSubmitting(false)
  }

  const formValues = { ...formInitialValues, ...item }

  return (
    <Formik
      key={item.id}
      isInitialValid
      initialValues={formValues}
      validationSchema={validationScheme}
      onSubmit={submit}
    >
      {formProps => (
        <FormFrame isLoading={isLoading} formProps={formProps}>
          {!item.id && (
            <p>
              Enter the details of the user you wish to add. They will be sent an email with instructions on how to set
              up their account.
            </p>
          )}
          {item.id &&
          <div className={styles.row}>
            <Avatar user={item} className={styles.avatar} />
          </div>
          }
          <FieldText
            label={'First Name'}
            name={'firstName'}
            disabled={action === 'view'}
            required={action === 'edit'}
          />
          <FieldText label={'Last Name'} name={'lastName'} disabled={action === 'view'} required={action === 'edit'} />
          <FieldText label={'Email'} name={'email'} disabled={action === 'view'} required={action === 'edit'} />
          <FieldSelect
            label={'Role'}
            name={'role'}
            options={roleOptions}
            disabled={action === 'view'}
            required={action === 'edit'}
          />

          <FormFrameActions
            isSubmitting={ formProps.isSubmitting || action === 'view' }
            submitText={ item.id ? 'Save changes' : 'Create user' }
            cancelTo={ cancelTo(item, action) }
          />
        </FormFrame>
      )}
    </Formik>
  )
}
