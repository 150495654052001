import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Menu from '@material-ui/core/Menu'
import { Profile } from 'base/components/ui/Profile'
import styles from './Profile.module.scss'
import { TModel as TUser } from 'models/User'

export interface Props {
  user: TUser
}

interface State {
  btnAnchor: HTMLElement | null
}

class HeaderProfile extends React.PureComponent<Props, State> {
  public state: State = {
    btnAnchor: null,
  }

  public handleClickOpen = (e: any) => {
    this.setState({ btnAnchor: e.currentTarget })
  }

  public userLetters = () => {
    const { user } = this.props
    return `${user.firstName[0] || ''}${user.lastName[0] || ''}`.trim()
  }

  public render() {
    const { btnAnchor } = this.state

    return (
      <div>
        <IconButton className={styles.avatar} onClick={this.handleClickOpen}>
          {this.userLetters()}
        </IconButton>
        <Menu
          classes={{ paper: styles.menu }}
          anchorEl={btnAnchor}
          open={!!btnAnchor}
          onClose={() => this.setState({ btnAnchor: null })}
        >
          <Profile />
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state: IState): Props => ({
  user: state.app.currentUser,
})

const HeaderProfileConnected = connect(mapStateToProps)(HeaderProfile)
export { HeaderProfileConnected as HeaderProfile }
