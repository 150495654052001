import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import styles from './Numbers.module.scss'
import { IKWOData } from 'store/state'
import { getPeopleNumber, getReceivingWhanauDirectNumber, getWhanauNumber } from 'helpers/KWODataHelper'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchReport } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

export const TabContent1Numbers = () => {
  const [data, setData] = useState<IKWOData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReport('cohort-overview')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card title={'Numbers'} padding={24} className={styles.root}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <div className={styles.row}>
          <span className={styles.num}>{getWhanauNumber(data)}</span>
          Whānau
        </div>

        <div className={styles.row}>
          <span className={styles.num}>{getPeopleNumber(data)}</span>
          People
        </div>

        <div className={styles.row}>
          <span className={styles.num}>{getReceivingWhanauDirectNumber(data)}</span>
          Receiving Whānau Direct
        </div>
      </LoaderPane>
    </Card>
  )
}
