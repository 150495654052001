import * as React from 'react'
import classNames from 'classnames'
import styles from '../Common.module.scss'
import { Link } from 'react-router-dom'

interface Props {
  email: string
}

export class ForgotPasswordSent extends React.Component<Props> {
  public render() {
    const { email } = this.props
    return (
      <>
        <div className={classNames(styles.title, styles.titleSignIn)}>Forgot password</div>

        <p className={styles.subTitleInfo}>A password reset email has been sent to</p>

        <div className={styles.email}>{email}</div>

        <p className={styles.subTitleInfo}>
          If you don't receive an email in 5-10 min, please check your spam folder and verify that this email address is
          associated with an account.
        </p>

        <div className={styles.actions}>
          <Link className={styles.actionLink} to={'/login'}>
            Back to login
          </Link>
        </div>
      </>
    )
  }
}
