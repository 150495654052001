import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { TBreadcrumb } from 'services/LayoutService'
import styles from './Breadcrumbs.module.scss'
import { useRouter } from '../../../../../base/hooks/useRouter'

interface Props {
  isLarge: boolean
  isDesktop: boolean
  items: TBreadcrumb[]
}

const HeaderBreadcrumbs = ({ isLarge, isDesktop, items }: Props) => {
  const router = useRouter()
  const pathname = router.location.pathname

  const isRootPage = items.length === 1
  return (
    <div
      className={classNames(styles.root, { [`${styles.rootLarge}`]: isLarge, [`${styles.isRootPage}`]: isRootPage })}
    >
      {getItems(items, isDesktop).map(item => {
        if (item.url && items.length > 1) {
          return (
            <Link className={styles.item} key={item.label} to={item.url}>
              {item.label}
            </Link>
          )
        }

        if (items.length > 1) {
          return (
            <span className={styles.item} key={item.label}>
              {item.label}
            </span>
          )
        }

        if (items.length === 1) {
          return (
            <span className={classNames(styles.item, styles.bigLabel)} key={item.label}>
              <div className={styles.main}>{item.label}</div>
              <div>{subtitle(pathname)}</div>
            </span>
          )
        }

        return null
      })}
    </div>
  )
}

const subtitle = (pathname: string) => {
  let result = ''
  if (pathname === '/about') {
    result = 'About Kainga Whānau Ora '
  }

  if (pathname === '/') {
    result = 'Home'
  }

  return result
}

function getItems(items: TBreadcrumb[], isDesktop: boolean): TBreadcrumb[] {
  return isDesktop ? items : items.slice(-1)
}

const mapStateToProps = (state: IState, ownProps: Pick<Props, 'isLarge' | 'isDesktop'>): Props => ({
  items: state.app.layout.breadcrumbs,
  isDesktop: ownProps.isDesktop,
  isLarge: ownProps.isLarge,
})

const HeaderBreadcrumbsConnected = connect(mapStateToProps)(HeaderBreadcrumbs)
export { HeaderBreadcrumbsConnected as HeaderBreadcrumbs }
