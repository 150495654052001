import React from 'react'
import classNames from 'classnames'
import { CardKoru } from 'components/shared/ui/CardKoru'
import { IPeople, TWhanau } from 'models/Whanau'
import { getMemberLinkById, TMember } from 'models/Member'
import styles from './Card2.module.scss'
import { Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'

interface Props {
  whanau: TWhanau
  member: TMember
  className?: string
}

export const ViewMemberCard2 = ({ whanau, member, className }: Props) => (
  <CardKoru className={classNames(styles.root, className)}>
    <div className={styles.title}>
      Part of whanau: <span>{whanau.name}</span>
    </div>

    <div className={styles.name}>{member.name}</div>

    <div className={styles.members}>
      {whanau.peoples.map(i => {
        return <Member whanau={whanau} people={i} active={i.kwoId === member.id} key={i.kwoId} />
      })}
    </div>

    <div className={styles.info}>In programme since {member.inProgramSince}</div>
  </CardKoru>
)

interface MemberProps {
  whanau: TWhanau
  people: IPeople
  active?: boolean
}

const Member = ({ whanau, people, active }: MemberProps) => {
  return (
    <Tooltip title={people.kwoId}>
      <Link
        className={classNames(styles.member, active && styles.active)}
        to={getMemberLinkById(whanau.id, people.kwoId)}
      />
    </Tooltip>
  )
}
