import React, { useState } from 'react'
import classNames from 'classnames'
import { Card } from 'base/components/ui/Card'
import { BarChart } from 'components/shared/ui/charts/BarChart'
import styles from './Chart.module.scss'
import { TMember } from 'models/Member'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchMemberChart } from 'api/MemberApi'
import { prepareChartData } from 'helpers/ChartDataHelper'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

interface Props {
  className?: string
  member: TMember
}

const fields = [
  { code: 'ED Presentations', name: 'ED Presentations', color: '#0379A0' },
  { code: 'IP Admissions', name: 'IP Admissions', color: '#26C6DA' },
]

export const ViewMemberChart = ({ className, member }: Props) => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async (id: string) => {
      const result = await fetchMemberChart(id, 'ed-presentations-and-ip-admissions')
      setData(result)
      setIsLoaded(true)
    }

    if (member.id) {
      fetch(member.id).catch(window.logger.error)
    }
  })

  return (
    <Card className={classNames(styles.root, className)} title={'ED Presentations and Hospital Admissions'}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <BarChart
          stack
          withLegend
          height={200}
          bars={fields.map(i => ({ name: i.name, color: i.color, field: i.code }))}
          data={prepareChartData(fields, data)}
        />
      </LoaderPane>
    </Card>
  )
}
