import * as React from 'react'
import { LogoLightHouse } from 'components/shared/ui/LogoLightHouse'
import { Footer } from 'components/shared/ui/Footer'
import styles from './LayoutSimple.module.scss'

interface Props {
  children: any
}

export const LayoutSimple = React.memo(({ children }: Props) => (
  <div className={styles.root}>
    <LogoLightHouse className={styles.logo} />
    <Wave1 />
    <Wave2 />
    <Wave3 />
    {children}
    <Footer absolute />
  </div>
))

const Wave1 = () => (
  <div className={styles.wave1}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 2560 1380"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.04">
        <path
          d="M2.3313 0.218201C764.739 0.218201 1016.56 1083.67 1759.62 1083.67C2502.69 1083.67 2633.99 368.84 3221.9368.84C3809.81 368.84 3917.25 1381.21 4816.21 1381.21V1749.16H-1374.33C-1374.33 1749.16 -1844.44 1332.3 -1374.33 1332.3C-904.212 1332.3 -760.076 0.218201 2.3313 0.218201Z"
          fill="#fff"
        />
        <path
          d="M2.3313 0.218201C764.739 0.218201 1016.56 1083.67 1759.62 1083.67C2502.69 1083.67 2633.99 368.84 3221.9 368.84C3809.81 368.84 3917.25 1381.21 4816.21 1381.21V1749.16H-1374.33C-1374.33 1749.16 -1844.44 1332.3 -1374.33 1332.3C-904.212 1332.3 -760.076 0.218201 2.3313 0.218201Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1603.41"
          y1="0.218"
          x2="1603.41"
          y2="1749.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </div>
)

const Wave2 = () => (
  <div className={styles.wave2}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 2560 916"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M-166.629 0.945557C595.779 0.945557 847.601 777.849 1590.66 777.849C2333.73 777.849 2465.03 265.272 3052.94 265.272C3640.85 265.272 3748.29 991.204 4647.25 991.204V1255.05H-1543.29C-1543.29 1255.05 -2013.4 956.138 -1543.29 956.138C-1073.17 956.138 -929.036 0.945557 -166.629 0.945557Z"
        fill="#fff"
      />
    </svg>
  </div>
)

const Wave3 = () => (
  <div className={styles.wave3}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1769 421"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.14"
        d="M1,1 L1,421 L1769,421 L1769,27 C1679.66667,27.6666667 1590.33333,43.6666667 1501,75 C1367,122 1086,250 759,229 C432,208 284,104 139,51 C42.3333333,15.6666667 -3.66666667,-1 1,1 Z"
        fill="#FFF"
      />
    </svg>
  </div>
)
