import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent2Card1 } from './tabContent2/Card1'
import { TabContent2Card2 } from './tabContent2/Card2'
import { TabContent2Card3 } from './tabContent2/Card3'
import { TabContent2Table } from './tabContent2/Table'
import { TabContent2Chart } from './tabContent2/Chart'
import { Grid } from 'base/components/ui/Grid'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent2 = (props: Props) => {
  return (
    <div>
      <Grid container mb={16}>
        <Heading3>Leading healthy Lifestyles</Heading3>
      </Grid>

      <Grid container spacing={16} mb={8}>
        <Grid item xs={12} lg={4}>
          <TabContent2Card1 whanau={props.whanau} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TabContent2Card2 whanau={props.whanau} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TabContent2Card3 whanau={props.whanau} />
        </Grid>
      </Grid>

      <Grid container mb={16}>
        <TabContent2Table whanau={props.whanau} />
      </Grid>

      <Grid container>
        <TabContent2Chart whanau={props.whanau} />
      </Grid>
    </div>
  )
}
