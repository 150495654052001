import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { Block } from 'base/components/ui/Block'
import { Text } from 'base/components/ui/Text'
import { fetchReport } from 'api/KWOApi'
import { useOnMount } from 'base/hooks/useOnMount'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import { IKWOData } from 'store/state'
import {
  getNumberofWhanauInDebt, getTotalAverageDebt,
  getTotalDebt,
  getTotalNonRecoverable,
  getTotalRecoverable,
} from 'helpers/KWODataHelper'

export const TabContent6Card4 = () => {
  const [data, setData] = useState<IKWOData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReport('whanau-in-debt')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card title={'Whānau in debt'} padding={24}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <Block flex justifySpace maxWidth={360}>
          <Block>Total debt</Block>
          <Block width={120}>
            <Text bold>{getTotalDebt(data)}</Text>
          </Block>
        </Block>

        <Block flex justifySpace maxWidth={360} mt={8}>
          <Block>Recoverable</Block>
          <Block width={120}>{getTotalRecoverable(data)}</Block>
        </Block>

        <Block flex justifySpace maxWidth={360} mt={8}>
          <Block>Non Recoverable</Block>
          <Block width={120}>{getTotalNonRecoverable(data)}</Block>
        </Block>

        <Block flex justifySpace maxWidth={360} mt={24}>
          <Block>Number of whānau in debt</Block>
          <Block width={120}>
            <Text bold>{getNumberofWhanauInDebt(data)}</Text>
          </Block>
        </Block>

        <Block flex justifySpace maxWidth={360} mt={8}>
          <Block>Average debt per whānau</Block>
          <Block width={120}>
            <Text bold>{getTotalAverageDebt(data)}</Text>
          </Block>
        </Block>
      </LoaderPane>
    </Card>
  )
}
