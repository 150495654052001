import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconEventMeeting = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} width={width} height={height} viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="12" fill="#433072" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2933 6.41992L18.38 5.33325C20.9866 8.03325 20.98 12.0733 18.38 14.6666L17.2933 13.5799C19.14 11.4599 19.14 8.43325 17.2933 6.41992ZM13.6666 9.99992C13.6666 11.4727 12.4727 12.6666 11 12.6666C9.5272 12.6666 8.33329 11.4727 8.33329 9.99992C8.33329 8.52716 9.5272 7.33325 11 7.33325C12.4727 7.33325 13.6666 8.52716 13.6666 9.99992ZM5.66663 16.6666C5.66663 14.8933 9.21996 13.9999 11 13.9999C12.78 13.9999 16.3333 14.8933 16.3333 16.6666V17.9999H5.66663V16.6666ZM15.0533 8.69992C15.6133 9.48659 15.6133 10.5066 15.0533 11.2933L16.1733 12.4199C17.52 11.0733 17.52 9.03992 16.1733 7.57325L15.0533 8.69992Z"
      fill="#fff"
    />
  </svg>
))
