import React from 'react'
import styles from 'components/shared/icons/IconManQueue.module.scss'
import { IconMan } from 'components/shared/icons/IconMan'
import classNames from 'classnames'

interface Props {
  count: number
  width?: number | string
  height?: number | string
  ellipsis?: number
  className?: string
}

export const IconManQueue = React.memo(({ count, width, height, className, ellipsis = 5 }: Props) => {
  return (
    <div className={classNames(styles.root, className)}>
      {Array(count)
        .fill('')
        .slice(0, ellipsis)
        .map((i, idx) => (
          <IconMan key={idx} width={width} height={height} className={styles.icon} />
        ))}
      {count > ellipsis && <div className={styles.more}>+{count - ellipsis}</div>}
    </div>
  )
})
