import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Grid } from 'base/components/ui/Grid'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent6Card1 } from 'components/views/viewWhanauDetail/tabContent6/Card1'
import { TabContent6Card2 } from 'components/views/viewWhanauDetail/tabContent6/Card2'
import { TabContent6Card3 } from 'components/views/viewWhanauDetail/tabContent6/Card3'
import { TabContent6Table } from 'components/views/viewWhanauDetail/tabContent6/Table'
import { TabContent6Chart } from 'components/views/viewWhanauDetail/tabContent6/Chart'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent6 = (props: Props) => (
  <div>
    <Grid container mb={16}>
      <Heading3>Economically Secure</Heading3>
    </Grid>

    <Grid container spacing={16} mb={8}>
      <Grid item xs={12} lg={4}>
        <TabContent6Card1 whanau={props.whanau} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent6Card2 whanau={props.whanau} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent6Card3 whanau={props.whanau} />
      </Grid>
    </Grid>

    <Grid container mb={16}>
      <TabContent6Table whanau={props.whanau} />
    </Grid>

    <Grid container>
      <TabContent6Chart whanau={props.whanau} />
    </Grid>
  </div>
)
