import React from 'react'
import { Chip } from '@material-ui/core'
import styles from './ExtraSelected.module.scss'

export interface ExtraMapItem {
  name: string
  label: string
  default: any
  type: 'search' | 'select' | 'multiselect' | 'checkbox'
  options?: { value: string; label: string }[]
}

interface Props<T> {
  filter: T
  extraMap: ExtraMapItem[]
  filterUpdate: (filter: T) => void
}

const multiselectLabelsMap = (i: string | number, options: { value: string; label: string }[]) => {
  const option = options.find(j => j.value === i)
  if (option) {
    return option.label
  }
  return null
}

export function ListingFilterExtraSelected<T extends any>({ filter, extraMap, filterUpdate }: Props<T>) {
  const onDelete = (name: string) => () => filterUpdate({ ...filter, [name]: null })

  const label = (item: ExtraMapItem) => {
    let result = item.label
    if (item.type === 'multiselect' && item.options && Array.isArray(filter[item.name])) {
      const labels = filter[item.name]
        .map((i: string | number) => multiselectLabelsMap(i, item.options || []))
        .filter((i: any) => i !== null)

      if (labels.length) {
        result = `${item.label}: ${labels.join(', ')}`
      }
    }

    if (item.type === 'select' && item.options) {
      const option = item.options.find(j => j.value === filter[item.name])
      if (option) {
        result = `${item.label}: ${option.label}`
      }
    }

    return result
  }

  return (
    <div className={styles.root}>
      {extraMap.map(
        item =>
          filter[item.name] && (
            <Chip key={item.name} className={styles.chip} label={label(item)} onDelete={onDelete(item.name)} />
          )
      )}
    </div>
  )
}
