import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { layoutService } from 'services/LayoutService'
import { Meta } from 'components/shared/Meta'
import { PageFrame } from 'base/components/ui/PageFrame'
import { IconKoru } from 'components/shared/icons/IconKoru'
import { IconMaze } from 'components/shared/icons/IconMaze'
import { IconHome } from 'components/shared/icons/IconHome'
import { IconPeople } from 'components/shared/icons/IconPeople'
import { IconLeaf } from 'components/shared/icons/IconLeaf'
import { IconGears } from 'components/shared/icons/IconGears'
import { IconMolecule } from 'components/shared/icons/IconMolecule'
import { ViewHomeCard } from './viewHome/Card'
import { ViewHomePartners } from './viewHome/Partners'
import { canAccess, TModel as TUser } from 'models/User'
import { Grid } from '@material-ui/core'

interface Props {
  currentUser: TUser
}

const ViewHome = React.memo(({ currentUser }: Props) => {
  React.useEffect(() => {
    layoutService.setHeaderLarge(true)
    layoutService.setBreadcrumbs([])
  }, [])

  return (
    <LayoutApp>
      <Meta title="Home" />
      <PageFrame simple>
        <Grid container spacing={16}>
          {canAccess(currentUser, [99, 40, 35]) && (
            <Grid item xs={12} sm={6}>
              <ViewHomeCard
                to={'/whanau'}
                name={'Whānau and individual status'}
                iconLeft={<IconKoru width={199} height={320} />}
                iconRight={<IconHome width={199} height={198} />}
                gradient={['#5DA6C6', '#5DC0C6']}
              />
            </Grid>
          )}
          {canAccess(currentUser, [99, 40, 35, 33]) && (
            <Grid item xs={12} sm={6}>
              <ViewHomeCard
                to={'/cohort-status'}
                name={'Cohort status'}
                iconLeft={<IconKoru width={199} height={320} />}
                iconRight={<IconPeople width={141} height={209} />}
                gradient={['#588AD5', '#76A7D5']}
              />
            </Grid>
          )}
          {canAccess(currentUser, [99, 40, 35, 33, 15]) && (
            <Grid item xs={12} sm={6}>
              <ViewHomeCard
                to={'/collective-leadership'}
                name={'Collective leadership'}
                iconLeft={<IconKoru width={199} height={320} />}
                iconRight={<IconLeaf width={151} height={205} />}
                gradient={['#81D47A', '#8CD4B6']}
              />
            </Grid>
          )}
          {canAccess(currentUser, [99, 40, 35, 33, 15]) && (
            <Grid item xs={12} sm={6}>
              <ViewHomeCard
                to={'/cohort-kpi'}
                name={'Cohort KPI'}
                iconLeft={<IconKoru width={199} height={320} />}
                iconRight={<IconMolecule width={230} height={230} />}
                gradient={['#8B75C8', '#7991D1']}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ViewHomeCard
              to={'/about'}
              name={'About Kāinga Whānau Ora'}
              iconLeft={<IconKoru width={199} height={320} />}
              gradient={['#4DB199', '#6EB1C6']}
            />
          </Grid>
          {canAccess(currentUser, [99, 40]) && (
            <Grid item xs={12} sm={6}>
              <ViewHomeCard
                to={'/users'}
                name={'Administration'}
                iconLeft={<IconMaze width={199} height={320} />}
                iconRight={<IconGears width={198} height={171} />}
                gradient={['#EE9A5E', '#EE785E']}
              />
            </Grid>
          )}
        </Grid>
      </PageFrame>

      <ViewHomePartners />
    </LayoutApp>
  )
})

const mapStateToProps = (state: IState): Props => ({
  currentUser: state.app.currentUser,
})

const ViewHomeConnected = connect(mapStateToProps)(ViewHome)

export { ViewHomeConnected as ViewHome }
