import { appGetState, appNextState, appUpdateState } from 'store'
import { initialState } from 'store/state'
import { create as createUser } from 'models/User'
import { login, logout, me } from 'api/AuthApi'
import { changePassword, resetPassword } from 'api/UserApi'

const comment = (m: string) => `AuthService::${m}`

class AuthService {
  private appGetState = appGetState
  private appNextState = appNextState
  private appUpdateState = appUpdateState

  /**
   */
  public async checkAuth() {
    try {
      const user = await me()
      if (user) {
        this.appUpdateState(s => (s.currentUser = user), comment('checkAuth'))
      }
    } catch (e) {}
  }

  /**
   * @return false or continue function
   */
  public async login(email: string, password: string): Promise<boolean | (() => void)> {
    if (!(await login(email, password))) {
      return false
    }
    const currentUser = await me()
    if (!currentUser) {
      return false
    }

    return () => {
      this.appUpdateState(s => (s.currentUser = currentUser), comment('login'))
    }
  }

  /**
   */
  public async sendPasswordReset(email: string): Promise<boolean> {
    try {
      await resetPassword(createUser({ email }))
      return true
    } catch (e) {
      return false
    }
  }

  /**
   */
  public async sendPasswordChange(email: string, newPassword: string, resetToken: string): Promise<boolean> {
    try {
      await changePassword(email, newPassword, resetToken)
      return true
    } catch (e) {
      return false
    }
  }

  /**
   */
  public async logout(): Promise<void> {
    this.appNextState({ ...initialState }, comment('logout'))
    await logout()
  }
}

const authService = new AuthService()
export { authService }
