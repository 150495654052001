import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconEventPhone = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} width={width} height={height} viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="12" fill="#6249AA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3334 4.66675H7.66671C6.93337 4.66675 6.33337 5.26675 6.33337 6.00008V18.0001C6.33337 18.7334 6.93337 19.3334 7.66671 19.3334H14.3334C15.0667 19.3334 15.6667 18.7334 15.6667 18.0001V6.00008C15.6667 5.26675 15.0667 4.66675 14.3334 4.66675ZM17.7334 9.80008L18.4 9.13341C20.0667 10.7334 20.0667 13.2667 18.4 14.8001L17.7334 14.1334C18.9334 12.8667 18.9334 11.0001 17.7334 9.80008ZM16.3334 11.2001L17 10.5334C17.8 11.4001 17.8 12.6001 17 13.4001L16.3334 12.7334C16.6667 12.2667 16.6667 11.6667 16.3334 11.2001ZM7.66671 17.3334H14.3334V6.66675H7.66671V17.3334Z"
      fill="#fff"
    />
  </svg>
))
