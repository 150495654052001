import React, { useState } from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent5Card1 } from './tabContent5/Card1'
import { TabContent5Card2 } from './tabContent5/Card2'
import { TabContent5Card3 } from './tabContent5/Card3'
import { TabContent5Card4 } from './tabContent5/Card4'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { useOnMount } from 'base/hooks/useOnMount'
import {
  getComfortWithCulture,
  getKnowledgeOfIwiAndHapu,
  getKohangaParticipation,
  getLearningTeReo,
} from 'helpers/BreakDownDataHelper'

export const ViewCohortStatusTabContent5 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('participation-in-te-ao-maori')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <div>
      <PageFrame simple>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            <TabContent5Card1 data={getLearningTeReo(data)} isLoaded={isLoaded} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TabContent5Card2 data={getComfortWithCulture(data)} isLoaded={isLoaded} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TabContent5Card3 data={getKnowledgeOfIwiAndHapu(data)} isLoaded={isLoaded} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TabContent5Card4 data={getKohangaParticipation(data)} isLoaded={isLoaded} />
          </Grid>
        </Grid>
      </PageFrame>
    </div>
  )
}
