const initialData = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  role: 0,
  avatar: '',
  created: 0,
  updated: 0,
  activated: false,
  suspended: false,
}

export type TModel = typeof initialData

export const create = (data?: Partial<TModel>): TModel => ({ ...initialData, ...data })

export const map = (data: TObjectAny): TModel => ({
  id: data.username || initialData.id,
  email: data.email || initialData.email,
  firstName: data.first_name || initialData.firstName,
  lastName: data.last_name || initialData.lastName,
  role: parseInt(data.role) || 0,
  avatar: `${data.avatar}`,
  created: parseInt(data.created) || initialData.created,
  updated: parseInt(data.updated) || initialData.updated,
  activated: !!data.activated,
  suspended: !!data.suspended,
})

export const canAccess = (user: TModel, roles?: number[]) => {
  return !roles || (roles.length && roles.includes(user.role))
}

export const toServerObject = (item: TModel) => ({
  username: item.id,
  email: item.email,
  first_name: item.firstName,
  last_name: item.lastName,
  role: item.role,
  avatar: item.avatar,
  activated: item.activated,
  suspended: item.suspended,
})

export const roleOptions = [
  { value: 13, label: 'Whanau', color: '#57a468' },
  { value: 15, label: 'Guest', color: '#5ce2b2' },
  { value: 33, label: 'Partner', color: '#50B4A1' },
  { value: 35, label: 'Navigator', color: '#5096B4' },
  { value: 40, label: 'Tenant Admin', color: '#836189' },
  { value: 99, label: 'Super Admin', color: '#893e56' },
]
