import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Block } from 'base/components/ui/Block'
import { Text } from 'base/components/ui/Text'
import { PieChart } from 'components/shared/ui/charts/PieChart'
import styles from './Card.module.scss'

interface Props {
  name: string
  text: string
  value: number
  percent: number
  color: string
  url: string
}

export const TabContent1Card = ({ name, text, value, percent, color, url }: Props) => {
  return (
    <Card title={name} className={styles.root} padding={24}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <Text block color={color} size={36} bold>{value}</Text>
          <Text block size={18} mt={8}>{text}</Text>
        </div>
        <div className={styles.chart}>
          <PieChart
            pies={[{ color, name: 'Main', value: 50 }]}
            totalValue={100}
            innerWidth={102}
            outerWidth={123}
          >
            <Block absolute flex justifyCenter width={'100%'} height={118}>
              <Text color={color} size={28}>{percent}%</Text>
            </Block>
          </PieChart>
        </div>
      </div>
    </Card>
  )
}
