import * as React from 'react'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { layoutService } from 'services/LayoutService'
import { Meta } from 'components/shared/Meta'
import { PageFrame } from 'base/components/ui/PageFrame'
import { ViewHomePartners } from './viewHome/Partners'
import { Grid } from '@material-ui/core'
import styles from './ViewAbout.module.scss'
import { Card } from '../../base/components/ui/Card'
import { Heading2 } from '../shared/ui/Heading2'

export const ViewAbout = React.memo(() => {
  React.useEffect(() => {
    layoutService.setHeaderLarge(true)
    layoutService.setBreadcrumbs([])
  }, [])

  return (
    <LayoutApp>
      <Meta title="About" />
      <PageFrame simple>
        <Card className={styles.root}>
          <Grid container spacing={16} direction={'column'}>
            <Grid item xs={12}>
              <Heading2 className={styles.heading2}>What</Heading2>
              <p>
                Kāinga Whānau Ora is Te Tihi’s Collective Impact initiative which is concentrating on engaging with 100
                households who live in Housing New Zealand homes in Palmerston North. Our Whānau Ora Navigation Service
                is integral to the success of this initiative as they support whānau to develop plans for their
                identified goals and aspirations
              </p>
            </Grid>
            <Grid item xs={12}>
              <Heading2 className={styles.heading2}>How</Heading2>
              <ul className={styles.list}>
                <li>
                  All whānau live in structurally safe, warm, dry homes within flourishing communities in Palmerston
                  North
                </li>
                <li>
                  These same whānau have access to workshops and educational opportunities which increases knowledge and
                  skills to make improved decisions
                </li>
                <li>Whānau living in loving and respectful relationships free of violence.</li>
              </ul>
            </Grid>
          </Grid>
        </Card>
      </PageFrame>

      <ViewHomePartners />
    </LayoutApp>
  )
})
