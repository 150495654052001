import React from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent6Card1 } from './tabContent6/Card1'
import { TabContent6Card2 } from './tabContent6/Card2'
import { TabContent6Card3 } from './tabContent6/Card3'
import { TabContent6Card4 } from './tabContent6/Card4'

export const ViewCohortStatusTabContent6 = () => (
  <div>
    <PageFrame simple>
      <Grid container spacing={16}>
        <Grid item xs={12} lg={6}>
          <TabContent6Card1 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent6Card2 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent6Card3 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent6Card4 />
        </Grid>
      </Grid>
    </PageFrame>
  </div>
)
