import { request } from 'base/utils/http/Request'
import { CHART_DATA, REPORT_BREAK_DOWN, REPORT_TABLES } from 'config/paths'
import { IChartData, IKWOData } from 'store/state'

export interface IReportBreakDown {
  measure: string
  dimension: string
  num_val: number
  num_total: number
  num_perc: number
}

export const fetchReportBreakDown = async (name: string): Promise<any[]> => {
  return (await request.get(`${REPORT_BREAK_DOWN}`, {
    name,
  })).Rows as IReportBreakDown[]
}
export const fetchChart = async (name: string, whanauId?: string): Promise<IChartData[]> => {
  return (await request.get(`${ CHART_DATA }`, {
    name,
    whanau_id: whanauId,
  })).dates as IChartData[]
}
export const fetchReport = async (name: string, whanauId?: string): Promise<IKWOData[]> => {
  return (await request.get(`${ REPORT_TABLES }`, {
    name,
    whanau_id: whanauId,
  })).rows as IKWOData[]
}
