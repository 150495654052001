import { map, TWhanau } from 'models/Whanau'
import { TWhanauFilter } from 'services/WhanauService'
import { request } from '../base/utils/http/Request'
import { NAVIGATORS, WHANAU_LIST } from 'config/paths'

const removeEmpty = (object: TObjectAny) => {
  Object.entries(object).forEach(([key, val]) => {
    if (val === null || val === '') {
      delete object[key]
    }
  })
}

export const fetchNavigatorList = async (): Promise<string[]> => {
  return (await request.get(`${NAVIGATORS}`)) as string[]
}

export const fetchList = async (filter: TWhanauFilter) => {
  const payload = {
    page: filter.page,
    size: filter.size,
    id: filter.field1,
    programme_entry_date: filter.field2,
    navigator: filter.field3,
    number_of_people: filter.field4,
    status: filter.field5,
  }
  removeEmpty(payload)
  const data = await request.get(`${WHANAU_LIST}`, payload)

  const items = data.list.map(map)
  const total = data.total
  return { items, total }
}

export const fetchSimpleList = async (limit: number = 20) => {
  const payload = {
    page: 0,
    size: limit,
  }
  removeEmpty(payload)
  const data = await request.get(`${WHANAU_LIST}`, payload)

  const items = data.list.map(map)
  return items
}

export const fetchOneById = async (id: string): Promise<TWhanau | undefined> => {
  const data = await request.get(`${WHANAU_LIST}`, {
    id,
    page: 0,
    size: 1,
  })

  const items: TWhanau[] = data.list.map(map)
  return items.length === 1 ? items[0] : undefined
}

