import React from 'react'
import styles from './Heading3.module.scss'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className?: string
}

export const Heading3 = ({ children, className }: Props) => (
  <div className={classNames(styles.root, className)}>{children}</div>
)
