import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconKoru = React.memo(({ width, height, className }: Props) => (
  <svg
    className={`MuiSvgIcon-root ${className}`}
    viewBox="0 0 199 320"
    width={width}
    height={height}
    fill="currentColor"
  >
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="199" height="320">
      <rect width="199" height="320" rx="5" />
    </mask>
    <g opacity="0.2" mask="url(#mask0)">
      <path d="M4.39781 495.217C56.9059 494.614 142.005 450.556 148.102 351.876C154.19 253.345 103.25 220.799 75.8111 208.945C47.0736 196.529 29.3128 207.08 29.3128 222.226C29.3128 222.226 30.4341 215.682 37.9775 212.896C45.5199 210.11 55.4457 209.877 65.295 218.498C83.0599 234.049 70.6712 285.958 100.098 308.925C117.668 322.639 134.481 314.336 131.818 303.31C126.232 305.183 122.298 300.305 124.762 295.019C128.561 286.873 139.377 294.014 140.686 299.307C142.845 308.046 138.519 333.163 109.977 325.697C81.4357 318.231 72.3376 288.579 68.8229 269.529C65.462 251.319 66.8075 234.111 59.3134 225.489C50.3309 215.155 36.9578 221.527 39.0757 226.654C39.0757 226.654 41.5204 222.516 45.5199 225.839C48.2543 228.11 50.3541 235.966 42.6558 239.237C34.5684 242.674 20.8563 235.801 21.7483 217.537C22.4895 202.383 35.5489 191.25 58.9936 193.028C81.4357 194.731 117.372 205.44 144.774 255.747C172.389 306.446 169.079 398.966 128.727 447.538C96.1363 486.768 51.774 505.175 6.20798 510.91C6.20798 510.91 -8.17786 502.558 4.39781 495.217Z" />
      <path d="M157.603 339.503C105.095 338.899 19.9943 294.841 13.898 196.161C7.81084 97.6305 58.7501 65.0846 86.1893 53.23C114.927 40.8142 132.688 51.3655 132.688 66.5116C132.688 66.5116 131.565 59.9669 124.023 57.1812C116.481 54.3946 106.555 54.1622 96.7054 62.7837C78.9406 78.3341 91.3292 130.243 61.9018 153.21C44.3321 166.924 27.5196 158.621 30.1816 147.595C35.7669 149.468 39.701 144.59 37.2382 139.305C33.4399 131.157 22.6231 138.299 21.3147 143.592C19.1546 152.331 23.4819 177.448 52.0233 169.982C80.5647 162.516 89.6618 132.865 93.1776 113.814C96.5374 95.6041 95.1919 78.3964 102.687 69.774C111.669 59.4399 125.043 65.8127 122.925 70.9396C122.925 70.9396 120.48 66.8013 116.481 70.124C113.745 72.3957 111.646 80.2509 119.345 83.5223C127.431 86.9596 141.144 80.0859 140.251 61.8223C139.511 46.6681 126.452 35.5356 103.006 37.3136C80.5647 39.0161 44.6277 49.7253 17.2267 100.032C-10.3884 150.731 -7.07984 243.251 33.2729 291.823C65.8641 331.053 110.226 349.461 155.791 355.195C155.791 355.195 170.178 346.843 157.603 339.503Z" />
      <path d="M159.392 255.996C159.392 255.996 121.578 225.652 117.459 195.126C113.28 164.159 121.578 149.258 137.386 143.944C147.518 140.539 155.587 146.589 157.298 153.299C159.009 160.009 155.757 166.553 149.178 167.137C143.464 167.644 139.668 163.223 141.161 159.356C142.759 155.217 148.218 154.868 149.038 157.113C149.038 157.113 149.193 151.684 142.185 152.22C135.409 152.738 124.204 160.636 127.183 187.312C130.354 215.708 158.746 242.329 168.935 244.754C168.935 244.754 174.703 255.996 159.392 255.996Z" />
      <path d="M2.60777 411.71C2.60777 411.71 40.4223 381.367 44.5414 350.841C48.7199 319.874 40.4223 304.972 24.6144 299.658C14.4825 296.253 6.41316 302.303 4.70255 309.014C2.99193 315.724 6.2432 322.267 12.8222 322.852C18.5363 323.359 22.3316 318.938 20.8392 315.071C19.2412 310.932 13.7826 310.583 12.962 312.827C12.962 312.827 12.8071 307.399 19.8155 307.935C26.5926 308.453 37.7965 316.35 34.8178 343.026C31.646 371.423 3.25441 398.043 -6.93485 400.469C-6.93485 400.469 -12.7033 411.71 2.60777 411.71Z" />
      <path d="M4.39781 184.09C56.9059 183.487 142.005 139.428 148.102 40.7486C154.19 -57.7821 103.25 -90.328 75.8111 -102.183C47.0736 -114.598 29.3128 -104.047 29.3128 -88.901C29.3128 -88.901 30.4341 -95.4457 37.9775 -98.2314C45.5199 -101.017 55.4457 -101.25 65.295 -92.6289C83.0599 -77.0785 70.6712 -25.1698 100.098 -2.20274C117.668 11.5113 134.481 3.20869 131.818 -7.81728C126.232 -5.94476 122.298 -10.8222 124.762 -16.1079C128.561 -24.2547 139.377 -17.1135 140.686 -11.8208C142.845 -3.08167 138.519 22.0355 109.977 14.5695C81.4357 7.10356 72.3376 -22.5481 68.8229 -41.5981C65.462 -59.8085 66.8075 -77.0162 59.3134 -85.6386C50.3309 -95.9727 36.9578 -89.5999 39.0757 -84.473C39.0757 -84.473 41.5204 -88.6113 45.5199 -85.2886C48.2543 -83.0169 50.3541 -75.1617 42.6558 -71.8903C34.5684 -68.453 20.8563 -75.3267 21.7483 -93.5903C22.4895 -108.744 35.5489 -119.877 58.9936 -118.099C81.4357 -116.396 117.372 -105.687 144.774 -55.3806C172.389 -4.68166 169.079 87.8383 128.727 136.41C96.1363 175.641 51.774 194.048 6.20798 199.782C6.20798 199.782 -8.17786 191.43 4.39781 184.09Z" />
      <path d="M157.603 28.3758C105.095 27.7724 19.9943 -16.2862 13.898 -114.966C7.81084 -213.496 58.7501 -246.042 86.1893 -257.897C114.927 -270.313 132.688 -259.761 132.688 -244.615C132.688 -244.615 131.565 -251.16 124.023 -253.946C116.481 -256.731 106.555 -256.965 96.7054 -248.343C78.9406 -232.793 91.3292 -180.884 61.9018 -157.917C44.3321 -144.203 27.5196 -152.506 30.1816 -163.532C35.7669 -161.659 39.701 -166.537 37.2382 -171.822C33.4399 -179.969 22.6231 -172.828 21.3147 -167.535C19.1546 -158.796 23.4819 -133.679 52.0233 -141.145C80.5647 -148.611 89.6618 -178.262 93.1776 -197.312C96.5374 -215.523 95.1919 -232.731 102.687 -241.353C111.669 -251.687 125.043 -245.314 122.925 -240.187C122.925 -240.187 120.48 -244.326 116.481 -241.003C113.745 -238.731 111.646 -230.876 119.345 -227.605C127.431 -224.167 141.144 -231.041 140.251 -249.305C139.511 -264.459 126.452 -275.591 103.006 -273.813C80.5647 -272.111 44.6277 -261.402 17.2267 -211.095C-10.3884 -160.396 -7.07984 -67.8761 33.2729 -19.3041C65.8641 19.9263 110.226 38.3338 155.791 44.068C155.791 44.068 170.178 35.7161 157.603 28.3758Z" />
      <path d="M2.60777 100.583C2.60777 100.583 40.4223 70.2397 44.5414 39.7141C48.7199 8.74711 40.4223 -6.15463 24.6144 -11.4685C14.4825 -14.8736 6.41316 -8.82363 4.70255 -2.11293C2.99193 4.59678 6.2432 11.1406 12.8222 11.7248C18.5363 12.2317 22.3316 7.81085 20.8392 3.94412C19.2412 -0.195143 13.7826 -0.54411 12.962 1.7005C12.962 1.7005 12.8071 -3.728 19.8155 -3.19199C26.5926 -2.67407 37.7965 5.22331 34.8178 31.8992C31.646 60.2958 3.25441 86.9164 -6.93485 89.342C-6.93485 89.342 -12.7033 100.583 2.60777 100.583Z" />
    </g>
  </svg>
))

export const IconKoruLeft = React.memo(({ width, height, className }: Props) => {
  return (
    <svg
      className={`MuiSvgIcon-root ${className}`}
      viewBox="0 0 128 232"
      width={width}
      height={height}
      fill="currentColor"
    >
      <g opacity="0.2">
        <path d="M11.7681 -71.971C50.4928 -71.526 113.253 -39.0327 117.75 33.7434C122.24 106.41 84.6718 130.412 64.4354 139.155C43.2415 148.312 30.1429 140.53 30.1429 129.36C30.1429 129.36 30.9699 134.187 36.5331 136.241C42.0956 138.296 49.4159 138.468 56.6798 132.109C69.7814 120.641 60.6447 82.3583 82.3467 65.4201C95.3051 55.3059 107.704 61.4291 105.74 69.5608C101.621 68.1798 98.7198 71.7769 100.537 75.6751C103.338 81.6834 111.315 76.4168 112.28 72.5134C113.873 66.0683 110.682 47.5444 89.6328 53.0505C68.5836 58.5567 61.8737 80.4247 59.2816 94.4742C56.8029 107.904 57.7953 120.595 52.2683 126.954C45.6438 134.575 35.7811 129.875 37.343 126.094C37.343 126.094 39.146 129.146 42.0956 126.696C44.1122 125.02 45.6608 119.227 39.9834 116.815C34.0189 114.28 23.9062 119.349 24.5641 132.818C25.1107 143.995 34.742 152.205 52.0325 150.894C68.5836 149.638 95.0863 141.74 115.295 104.639C135.661 67.2483 133.221 -0.985182 103.461 -36.807C79.4253 -65.7395 46.7081 -79.315 13.1031 -83.5439C13.1031 -83.5439 2.49353 -77.3844 11.7681 -71.971Z" />
        <path d="M124.757 42.8684C86.0319 43.3134 23.2705 75.8066 18.7745 148.583C14.2851 221.249 51.8528 245.252 72.0893 253.995C93.2832 263.151 106.382 255.37 106.382 244.199C106.382 244.199 105.554 249.026 99.9915 251.081C94.429 253.136 87.1088 253.307 79.8449 246.949C66.7433 235.48 75.8799 197.198 54.1772 180.259C41.2196 170.145 28.8204 176.268 30.7835 184.4C34.9028 183.019 37.8042 186.616 35.9878 190.514C33.1866 196.523 25.2092 191.256 24.2443 187.353C22.6512 180.908 25.8426 162.384 46.8918 167.89C67.9411 173.396 74.6502 195.264 77.2431 209.314C79.721 222.744 78.7287 235.434 84.2563 241.793C90.8809 249.415 100.744 244.715 99.1816 240.934C99.1816 240.934 97.3786 243.986 94.429 241.535C92.4117 239.86 90.8638 234.067 96.5413 231.654C102.505 229.119 112.618 234.188 111.96 247.658C111.414 258.834 101.783 267.044 84.4914 265.733C67.9411 264.477 41.4376 256.579 21.2294 219.478C0.863182 182.088 3.30328 113.854 33.0634 78.0324C57.0994 49.0999 89.8166 35.5244 123.421 31.2954C123.421 31.2954 134.031 37.455 124.757 42.8684Z" />
        <path d="M126.077 104.455C126.077 104.455 98.1883 126.833 95.1505 149.346C92.0688 172.184 98.1883 183.174 109.847 187.093C117.319 189.604 123.27 185.143 124.532 180.193C125.794 175.245 123.395 170.419 118.543 169.988C114.329 169.614 111.53 172.875 112.631 175.726C113.809 178.779 117.835 179.036 118.44 177.381C118.44 177.381 118.555 181.385 113.386 180.989C108.388 180.607 100.125 174.783 102.322 155.11C104.661 134.167 125.6 114.534 133.114 112.745C133.114 112.745 137.368 104.455 126.077 104.455Z" />
        <path d="M10.448 -10.3845C10.448 -10.3845 38.3362 11.9938 41.3741 34.5064C44.4557 57.3446 38.3362 68.3346 26.6779 72.2536C19.2056 74.7649 13.2545 70.303 11.9929 65.3539C10.7313 60.4055 13.1291 55.5794 17.9811 55.1485C22.1953 54.7747 24.9943 58.0351 23.8937 60.8868C22.7152 63.9395 18.6894 64.1969 18.0842 62.5415C18.0842 62.5415 17.97 66.545 23.1387 66.1497C28.1368 65.7677 36.3997 59.9434 34.2029 40.2699C31.8637 19.3274 10.9249 -0.305254 3.4103 -2.09416C3.4103 -2.09416 -0.843905 -10.3845 10.448 -10.3845Z" />
        <path d="M11.7681 157.485C50.4928 157.93 113.253 190.423 117.75 263.199C122.24 335.866 84.6718 359.868 64.4354 368.611C43.2415 377.768 30.1429 369.986 30.1429 358.816C30.1429 358.816 30.9699 363.643 36.5331 365.697C42.0956 367.752 49.4159 367.924 56.6798 361.565C69.7814 350.097 60.6447 311.814 82.3467 294.876C95.3051 284.762 107.704 290.885 105.74 299.017C101.621 297.636 98.7198 301.233 100.537 305.131C103.338 311.139 111.315 305.873 112.28 301.969C113.873 295.524 110.682 277 89.6328 282.507C68.5836 288.013 61.8737 309.881 59.2816 323.93C56.8029 337.36 57.7953 350.051 52.2683 356.41C45.6438 364.031 35.7811 359.331 37.343 355.55C37.343 355.55 39.146 358.602 42.0956 356.152C44.1122 354.477 45.6608 348.683 39.9834 346.271C34.0189 343.736 23.9062 348.805 24.5641 362.274C25.1107 373.451 34.742 381.661 52.0325 380.35C68.5836 379.094 95.0863 371.196 115.295 334.095C135.661 296.704 133.221 228.471 103.461 192.649C79.4253 163.717 46.7081 150.141 13.1031 145.912C13.1031 145.912 2.49353 152.072 11.7681 157.485Z" />
        <path d="M10.448 219.072C10.448 219.072 38.3362 241.45 41.3741 263.963C44.4557 286.801 38.3362 297.791 26.6779 301.71C19.2056 304.221 13.2545 299.759 11.9929 294.81C10.7313 289.862 13.1291 285.036 17.9811 284.605C22.1953 284.231 24.9943 287.491 23.8937 290.343C22.7152 293.396 18.6894 293.653 18.0842 291.998C18.0842 291.998 17.97 296.001 23.1387 295.606C28.1368 295.224 36.3997 289.4 34.2029 269.726C31.8637 248.784 10.9249 229.151 3.4103 227.362C3.4103 227.362 -0.843905 219.072 10.448 219.072Z" />
        <path d="M124.757 -186.588C86.0319 -186.143 23.2705 -153.649 18.7745 -80.8733C14.2851 -8.20689 51.8528 15.7957 72.0893 24.5385C93.2832 33.6951 106.382 25.9136 106.382 14.7433C106.382 14.7433 105.554 19.5701 99.9915 21.6245C94.429 23.6789 87.1088 23.851 79.8449 17.4927C66.7433 6.02424 75.8799 -32.2584 54.1772 -49.1966C41.2196 -59.3108 28.8204 -53.1876 30.7835 -45.0559C34.9028 -46.4369 37.8042 -42.8398 35.9878 -38.9416C33.1866 -32.9326 25.2092 -38.1999 24.2443 -42.1033C22.6512 -48.5484 25.8426 -67.0723 46.8918 -61.5662C67.9411 -56.06 74.6502 -34.192 77.2431 -20.1425C79.721 -6.71242 78.7287 5.97827 84.2563 12.3373C90.8809 19.9587 100.744 15.2587 99.1816 11.4777C99.1816 11.4777 97.3786 14.5297 94.429 12.0792C92.4117 10.4038 90.8638 4.61061 96.5413 2.19797C102.505 -0.337038 112.618 4.73225 111.96 18.2017C111.414 29.3779 101.783 37.5881 84.4914 36.2769C67.9411 35.0212 41.4376 27.1232 21.2294 -9.97799C0.863182 -47.3684 3.30328 -115.602 33.0634 -151.424C57.0994 -180.356 89.8166 -193.932 123.421 -198.161C123.421 -198.161 134.031 -192.001 124.757 -186.588Z" />
      </g>
    </svg>
  )
})

export const IconKoruRight = React.memo(({ width, height, className }: Props) => {
  return (
    <svg
      className={`MuiSvgIcon-root ${className}`}
      viewBox="0 0 128 232"
      width={width}
      height={height}
      fill="currentColor"
    >
      <g opacity="0.2">
        <path d="M124.757 417.223C86.0321 416.778 23.2714 384.285 18.7747 311.509C14.2854 238.842 51.8531 214.84 72.0895 206.097C93.2834 196.94 106.382 204.722 106.382 215.892C106.382 215.892 105.555 211.065 99.9918 209.011C94.4293 206.956 87.109 206.784 79.8451 213.143C66.7435 224.611 75.8802 262.894 54.1782 279.832C41.2198 289.946 28.8206 283.823 30.7845 275.691C34.9038 277.072 37.8051 273.475 35.9881 269.577C33.1868 263.569 25.2094 268.835 24.2445 272.739C22.6521 279.184 25.8428 297.708 46.8921 292.201C67.9413 286.695 74.6512 264.827 77.2433 250.778C79.722 237.348 78.7296 224.657 84.2566 218.298C90.8811 210.677 100.744 215.377 99.1819 219.158C99.1819 219.158 97.3789 216.106 94.4293 218.556C92.4127 220.231 90.8641 226.025 96.5415 228.437C102.506 230.972 112.619 225.903 111.961 212.434C111.414 201.257 101.783 193.047 84.4924 194.358C67.9413 195.614 41.4386 203.512 21.2296 240.613C0.863409 278.004 3.30425 346.237 33.0637 382.059C57.0996 410.991 89.8168 424.567 123.422 428.796C123.422 428.796 134.031 422.636 124.757 417.223Z" />
        <path d="M11.7678 302.383C50.4925 301.938 113.254 269.445 117.75 196.669C122.239 124.003 84.6716 99.9999 64.4352 91.2571C43.2413 82.1005 30.1427 89.8821 30.1427 101.052C30.1427 101.052 30.9704 96.2256 36.5329 94.1712C42.0954 92.116 49.4156 91.9447 56.6795 98.303C69.7811 109.771 60.6445 148.054 82.3472 164.992C95.3049 175.106 107.704 168.983 105.741 160.852C101.622 162.233 98.7202 158.635 100.537 154.737C103.338 148.728 111.315 153.996 112.28 157.899C113.873 164.344 110.682 182.868 89.6326 177.362C68.5833 171.856 61.8742 149.988 59.2813 135.938C56.8034 122.508 57.7957 109.817 52.2681 103.458C45.6435 95.8369 35.7808 100.537 37.3428 104.318C37.3428 104.318 39.1458 101.266 42.0954 103.716C44.1127 105.392 45.6606 111.185 39.9831 113.598C34.0194 116.133 23.906 111.063 24.5646 97.594C25.1104 86.4178 34.7417 78.2075 52.033 79.5188C68.5833 80.7744 95.0868 88.6724 115.295 125.774C135.661 163.164 133.221 231.398 103.461 267.219C79.425 296.152 46.7078 309.727 13.1036 313.956C13.1036 313.956 2.49329 307.797 11.7678 302.383Z" />
        <path d="M10.4482 240.797C10.4482 240.797 38.3364 218.418 41.3743 195.906C44.4559 173.068 38.3364 162.077 26.6782 158.159C19.2059 155.647 13.2547 160.109 11.9931 165.058C10.7308 170.007 13.1294 174.833 17.9814 175.264C22.1955 175.637 24.9946 172.377 23.8939 169.525C22.7154 166.473 18.6897 166.215 18.0845 167.871C18.0845 167.871 17.9702 163.867 23.1389 164.262C28.1363 164.644 36.3999 170.469 34.2024 190.142C31.8639 211.085 10.9251 230.717 3.41055 232.506C3.41055 232.506 -0.843654 240.797 10.4482 240.797Z" />
        <path d="M124.757 187.767C86.0319 187.322 23.2712 154.828 18.7744 82.0522C14.2851 9.38584 51.8528 -14.6168 72.0892 -23.3596C93.2831 -32.5162 106.382 -24.7346 106.382 -13.5643C106.382 -13.5643 105.555 -18.3911 99.9915 -20.4455C94.429 -22.4999 87.1088 -22.672 79.8449 -16.3137C66.7433 -4.84529 75.8799 33.4374 54.178 50.3756C41.2195 60.4897 28.8203 54.3665 30.7843 46.2349C34.9035 47.6158 37.8049 44.0188 35.9878 40.1206C33.1865 34.1123 25.2092 39.3789 24.2442 43.2823C22.6519 49.7274 25.8425 68.2513 46.8918 62.7451C67.9411 57.239 74.651 35.3709 77.2431 21.3215C79.7217 7.89138 78.7294 -4.79932 84.2563 -11.1584C90.8809 -18.7798 100.744 -14.0798 99.1816 -10.2988C99.1816 -10.2988 97.3786 -13.3507 94.429 -10.9003C92.4124 -9.22482 90.8638 -3.43166 96.5413 -1.01902C102.506 1.51599 112.618 -3.55329 111.961 -17.0227C111.414 -28.1989 101.783 -36.4092 84.4922 -35.0979C67.9411 -33.8423 41.4383 -25.9443 21.2294 11.1569C0.863165 48.5474 3.304 116.781 33.0634 152.603C57.0994 181.535 89.8166 195.111 123.422 199.34C123.422 199.34 134.031 193.18 124.757 187.767Z" />
        <path d="M11.7681 72.9273C50.4928 72.4823 113.254 39.989 117.75 -32.7871C122.24 -105.454 84.6718 -129.456 64.4354 -138.199C43.2415 -147.356 30.1429 -139.574 30.1429 -128.404C30.1429 -128.404 30.9706 -133.23 36.5331 -135.285C42.0956 -137.339 49.4159 -137.511 56.6798 -131.153C69.7813 -119.685 60.6447 -81.402 82.3474 -64.4638C95.3051 -54.3496 107.704 -60.4728 105.741 -68.6045C101.622 -67.2235 98.7205 -70.8206 100.537 -74.7188C103.338 -80.7271 111.315 -75.4605 112.28 -71.5571C113.873 -65.112 110.682 -46.5881 89.6328 -52.0942C68.5835 -57.6004 61.8744 -79.4684 59.2816 -93.5179C56.8036 -106.948 57.796 -119.639 52.2683 -125.998C45.6438 -133.619 35.7811 -128.919 37.343 -125.138C37.343 -125.138 39.146 -128.19 42.0956 -125.74C44.113 -124.064 45.6608 -118.271 39.9834 -115.858C34.0196 -113.323 23.9062 -118.393 24.5648 -131.862C25.1107 -143.038 34.742 -151.249 52.0332 -149.937C68.5835 -148.682 95.0871 -140.784 115.295 -103.682C135.661 -66.292 133.221 1.94148 103.461 37.7633C79.4252 66.6958 46.7081 80.2713 13.1038 84.5002C13.1038 84.5002 2.49353 78.3407 11.7681 72.9273Z" />
        <path d="M126.077 126.18C126.077 126.18 98.1885 103.802 95.1506 81.289C92.069 58.4508 98.1885 47.4608 109.847 43.5418C117.319 41.0305 123.27 45.4924 124.532 50.4415C125.793 55.39 123.396 60.216 118.544 60.6469C114.329 61.0207 111.53 57.7603 112.631 54.9086C113.809 51.8559 117.835 51.5985 118.44 53.2539C118.44 53.2539 118.555 49.2504 113.386 49.6457C108.388 50.0277 100.125 55.852 102.322 75.5255C104.661 96.468 125.6 116.101 133.114 117.89C133.114 117.89 137.369 126.18 126.077 126.18Z" />
      </g>
    </svg>
  )
})
