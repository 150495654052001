import * as React from 'react'
import classNames from 'classnames'
import { History } from 'history'
import posed from 'react-pose'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import { Meta } from 'components/shared/Meta'
import { LayoutSimple } from 'components/shared/layouts/LayoutSimple'
import { LogoProject } from 'components/shared/ui/LogoProject'
import styles from './Common.module.scss'

export interface Props {
  history: History
}

interface State {
  finish: boolean
}

export class ViewSplash extends React.Component<Props, State> {
  public state: State = { finish: false }

  public goToLogin = () => {
    this.setState({ finish: true })
  }

  public onAnimationComplete = (pose: any) => {
    if (pose === 'finish') {
      this.props.history.push('/login')
    }
  }

  public render() {
    return (
      <LayoutSimple>
        <Meta title="Welcome" />
        <Animated
          className={styles.root}
          initialPose="start"
          pose={this.state.finish ? 'finish' : 'active'}
          onPoseComplete={this.onAnimationComplete}
        >
          <Paper className={styles.paper}>
            <LogoProject className={styles.logo} />
            <div className={classNames(styles.title)}>Kāinga Whānau Ora</div>
            <div className={styles.subTitle}>Collective Impact Platform</div>
            <Fab className={styles.button} variant="extended" onClick={this.goToLogin}>
              Sign In
            </Fab>
          </Paper>
        </Animated>
      </LayoutSimple>
    )
  }
}

const Animated = posed.div({
  start: { x: '-50%', y: '-60%', opacity: 0 },
  active: { x: '-50%', y: '-50%', opacity: 1, transition: { duration: 300 } },
  finish: { x: '-50%', y: '-60%', opacity: 0, transition: { duration: 300 } },
})
