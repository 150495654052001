import React from 'react'
import { useOnMount } from 'base/hooks/useOnMount'
import { useQueryHashValue } from 'base/hooks/useQueryHashValue'
import { Meta } from 'components/shared/Meta'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageTabs } from 'base/components/ui/PageTabs'
import { ViewCohortStatusTabContent1 } from './viewCohortStatus/TabContent1'
import { ViewCohortStatusTabContent2 } from './viewCohortStatus/TabContent2'
import { ViewCohortStatusTabContent3 } from './viewCohortStatus/TabContent3'
import { ViewCohortStatusTabContent4 } from './viewCohortStatus/TabContent4'
import { ViewCohortStatusTabContent5 } from './viewCohortStatus/TabContent5'
import { ViewCohortStatusTabContent6 } from './viewCohortStatus/TabContent6'
import { ViewCohortStatusTabContent7 } from './viewCohortStatus/TabContent7'
import { layoutService } from 'services/LayoutService'

interface Props {
}

export const ViewCohortStatus = (props: Props) => {
  const [tab, setTab] = useQueryHashValue('tab', '1')

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Cohort status' }])
  })

  return (
    <LayoutApp>
      <Meta title="Cohort status" />
      <PageTabs
        initialTab={tab}
        tab={tab}
        items={[
          { value: '1', label: 'Overview', content: <ViewCohortStatusTabContent1 /> },
          { value: '2', label: 'Whānau self managing', content: <ViewCohortStatusTabContent2 /> },
          { value: '3', label: 'Whānau healthy lifestyles', content: <ViewCohortStatusTabContent3 /> },
          { value: '4', label: 'Whānau Cohesive', content: <ViewCohortStatusTabContent7 /> },
          { value: '5', label: 'Participation in Te Ao Māori', content: <ViewCohortStatusTabContent5 /> },
          { value: '6', label: 'Participating in society', content: <ViewCohortStatusTabContent4 /> },
          { value: '7', label: 'Economically secure', content: <ViewCohortStatusTabContent6 /> },
        ]}
        onTabChange={setTab}
      />
    </LayoutApp>
  )
}
