import React from 'react'
import styles from './NoData.module.scss'

interface Props {
  message?: string
}

export const NoData = ({ message = 'No Data' }: Props) => (
  <div className={styles.root}>
    {message}
  </div>
)
