import * as React from 'react'
import classNames from 'classnames'
import IconArrow from '@material-ui/icons/KeyboardArrowDown'
import styles from './Expander.module.scss'

interface Props {
  isExpanded: boolean
  onClick: () => void
}

export const SidebarExpander = React.memo(({ isExpanded, onClick }: Props) => {
  return (
    <div className={styles.expander} onClick={onClick}>
      <div className={styles.expanderInner}>
        <div className={styles.expanderText}>
          <span>Click to {isExpanded ? 'collapse' : 'expand'}</span>
          <IconArrow className={classNames([styles.expanderIcon, { [styles.rotateIcon]: isExpanded }])} />
        </div>
      </div>
    </div>
  )
})
