import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconArrow = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 50 51" width={width} height={height} fill="currentColor">
    <path d="M24.8965 8.96826L21.998 11.8668L33.4688 23.3582H8.45093V27.4696H33.4688L21.998 38.961L24.8965 41.8595L41.3422 25.4139L24.8965 8.96826Z" />
  </svg>
))
