import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import { LogoProject } from 'components/shared/ui/LogoProject'
import { HeaderProfile } from './header/Profile'
import { HeaderBreadcrumbs } from './header/Breadcrumbs'
import layoutStyles from '../LayoutApp.module.scss'
import styles from './Header.module.scss'

export interface Props {
  isLarge: boolean
  isDesktop: boolean
  isExpanded: boolean
  onOpenToggle: (isOpened: boolean) => void
}

export class LayoutAppHeader extends React.PureComponent<Props> {
  public openSidebar = () => this.props.onOpenToggle(true)

  public render() {
    const { isLarge, isDesktop, isExpanded } = this.props

    const headerClasses = [
      layoutStyles.header,
      { [layoutStyles.headerLarge]: isLarge },
      { [layoutStyles.headerExpanded]: isExpanded },
    ]

    return (
      <div className={classNames(headerClasses)}>
        <Waves isLarge={isLarge} />
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={this.openSidebar}
          className={layoutStyles.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classNames(styles.left, isLarge && styles.leftLarge)}>
          <LogoProject className={classNames(styles.logo, isLarge && styles.logoLarge)} />
          <HeaderBreadcrumbs isLarge={isLarge} isDesktop={isDesktop} />
        </div>
        <div className={styles.spacer} />
        {isDesktop && <HeaderProfile />}
      </div>
    )
  }
}

const Waves = ({ isLarge }: { isLarge: boolean }) => (
  <div className={classNames(styles.waves, isLarge && styles.wavesLarge)}>
    <svg preserveAspectRatio="none" viewBox="0 0 467 202" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        d="M0,202 C84,202 156.333333,186.333333 217,155 C277.666667,123.666667 340.333333,72 405,0 L467,0 L467,202 L0,202 Z"
        fillOpacity="0.1"
        fill="#E29EF9"
      />
      <path
        d="M254,202 C264.666667,200.666667 290,188.333333 330,165 C370,141.666667 415.666667,103.666667 467,51 L467,202 L254,202 Z"
        fillOpacity="0.12"
        fill="#9780F4"
      />
      <path
        d="M368,202 C385.333333,192.666667 400.666667,183.666667 414,175 C427.333333,166.333333 445,152.333333 467,133 L467,202 L368,202 Z"
        fillOpacity="0.15"
        fill="#78ADFC"
      />
    </svg>
  </div>
)
