import React from 'react'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import styles from 'components/views/viewWhanauDetail/Table.module.scss'
import classNames from 'classnames'
import {
  getChildInfections,
  getEnrolledInGPCount,
  getEnrolledInGPTotalCount,
  getHeatingSource,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'
import { ViewWhanauDetailMarkers } from '../Markers'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent2Table = ({ className, whanau }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <Table hideHeader={true} className={styles.table} columns={getColumns(whanau)} items={prepareItems(whanau)} />
  </Card>
)

const getColumns = (whanau: TWhanau): TColumn[] => {
  return [
    {
      label: '',
      render: (item: any) => item.name,
    },
    {
      label: '',
      render: (item: any) => item.value,
    },
    {
      label: '',
      render: (item: any) => {
        return (
          <ViewWhanauDetailMarkers
            total={item.markers.total}
            count={item.markers.count}
            color={getTrafficColorLowest(whanau, item.code)}
          />
        )
      },
    },
  ]
}

const prepareItems = (whanau: TWhanau) => {
  return [
    {
      name: 'Enrolled at GP',
      code: 'enrolled at gp',
      value: `${getEnrolledInGPCount(whanau)}/${getEnrolledInGPTotalCount(whanau)}`,
      markers: { count: getEnrolledInGPCount(whanau), total: getEnrolledInGPTotalCount(whanau) },
    },
    {
      name: 'Immunised',
      code: 'Immunised',
      value: 'TODO evaluate',
      markers: { total: 1 },
    },
    {
      name: 'Child infections (respiratory and skin)',
      code: 'child infections',
      value: getChildInfections(whanau),
      markers: { total: 1 },
    },
    {
      name: 'Home insulated',
      code: 'Home insulated',
      value: 'TODO evaluate',
      markers: { total: 1 },
    },
    {
      name: 'Heating source',
      code: 'heating source',
      value: getHeatingSource(whanau),
      markers: { total: 1 },
    },
  ]
}
