import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { LogoLightHouse } from 'components/shared/ui/LogoLightHouse'
import { Profile } from 'base/components/ui/Profile'
import { SidebarExpander } from './sidebar/Expander'
import layoutStyles from '../LayoutApp.module.scss'
import styles from './Sidebar.module.scss'
import { sidebarNav, TSidebarNav, TSidebarNavItem } from 'config/sidebarNav'
import { canAccess, TModel as TUser } from 'models/User'

export interface Props extends RouteComponentProps {
  isDesktop: boolean
  isExpanded: boolean
  isOpened: boolean
  onExpandToggle: (isExpanded: boolean) => void
  onOpenToggle: (isOpened: boolean) => void
  currentUser: TUser
}

class LayoutAppSidebar extends React.PureComponent<Props> {
  public closeSidebar = () => this.props.onOpenToggle(false)

  public expandToggleSidebar = () => this.props.onExpandToggle(!this.props.isExpanded)

  public render() {
    const { isDesktop, isOpened, isExpanded } = this.props

    return (
      <Drawer
        className={styles.root}
        classes={{
          paper: classNames(layoutStyles.sidebar, isExpanded && layoutStyles.sidebarExpanded, styles.drawerPaper),
        }}
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={isOpened}
        onClose={this.closeSidebar}
      >
        {isDesktop && (
          <>
            <ListItem className={styles.logoItem} component={({ innerRef, ...props }) => <Link {...props} to={'/'} />}>
              <LogoLightHouse mini onlyIcon={!isExpanded} />
            </ListItem>
            <Divider />
          </>
        )}
        <div className={classNames(styles.listBlock, { [styles.listBlockMobile]: !isDesktop })}>
          <List className={styles.list}>{this.renderNav()}</List>
        </div>

        {isDesktop ? (
          <SidebarExpander isExpanded={isExpanded} onClick={this.expandToggleSidebar} />
        ) : (
          <>
            <List>
              <Profile />
            </List>
          </>
        )}
      </Drawer>
    )
  }

  private renderNav() {
    const { currentUser, isExpanded } = this.props

    const authorizedNav = sidebarNav.reduce(
      (n, s) => {
        const items = s.items.filter(
          i => canAccess(currentUser, i.requiredRoles)
        )
        if (items.length) {
          n.push({ ...s, items })
        }
        return n
      },
      [] as TSidebarNav
    )

    return authorizedNav.map((section, i) => (
      <React.Fragment key={`${i}:${section.sectionName}`}>
        {section.sectionName && (
          <li className={classNames(styles.listSection, { [styles.sectionExpanded]: isExpanded })}>
            {section.sectionName}
          </li>
        )}
        {section.items.map((item, k) => (
          <ListItem
            key={`${k}-${item.name}`}
            component={({ innerRef, ...props }) => <Link {...props} to={item.url} />}
            className={classNames(styles.listItem, { [styles.listItemActive]: this.isItemActive(item) })}
            button
          >
            <ListItemIcon className={styles.listIcon}>{item.icon}</ListItemIcon>
            <ListItemText className={styles.listText} primary={item.name} />
          </ListItem>
        ))}
      </React.Fragment>
    ))
  }

  private isItemActive(item: TSidebarNavItem): boolean {
    const {
      location: { pathname },
    } = this.props

    return item.url === '/' ? item.url === pathname : pathname.indexOf(item.url) === 0
  }
}

const LayoutAppSidebarConnected = withRouter(LayoutAppSidebar)
export { LayoutAppSidebarConnected as LayoutAppSidebar }
