import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconGoalComplete = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 31 30" width={width} height={height} fill="currentColor">
    <path
      d="M31 14.9928L27.5618 11.0081L28.0409 5.7334L22.9541 4.55805L20.2909 0L15.5 2.09269L10.7091 0L8.04591 4.55805L2.95909 5.71906L3.43818 10.9938L0 14.9928L3.43818 18.9775L2.95909 24.2666L8.04591 25.4419L10.7091 30L15.5 27.893L20.2909 29.9857L22.9541 25.4276L28.0409 24.2523L27.5618 18.9775L31 14.9928ZM12.6818 22.1596L7.04545 16.4262L9.03227 14.4052L12.6818 18.1032L21.9677 8.65743L23.9545 10.6928L12.6818 22.1596Z"
      fill="#08D6B1"
    />
  </svg>
))
