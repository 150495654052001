import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { RouteComponentProps } from 'react-router'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Loader } from 'base/components/ui/Loader'
import { Grid } from 'base/components/ui/Grid'
import { ViewMemberCard1 } from './viewMember/Card1'
import { ViewMemberCard2 } from './viewMember/Card2'
import { ViewMemberCard3 } from './viewMember/Card3'
import { ViewMemberChart } from './viewMember/Chart'
import { TMember } from 'models/Member'
import { TWhanau, getLink } from 'models/Whanau'
import { useOnMount } from 'base/hooks/useOnMount'
import { layoutService } from 'services/LayoutService'
import { memberService } from 'services/MemberService'

interface Props {
  id: string
  whanauId: string
  member: TMember
  whanau: TWhanau
  isMemberLoading: boolean
  loadMember: (whanauId: string, id: string) => void
}

const ViewMember = React.memo((props: Props & RouteComponentProps) => {
  const { id, whanauId, member, whanau, isMemberLoading, loadMember } = props
  const isLoading = isMemberLoading

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
  })

  useEffect(() => {
    loadMember(whanauId, id)
  }, [whanauId, id, loadMember])

  React.useEffect(() => {
    layoutService.setBreadcrumbs([
      { label: 'Whānau', url: '/whanau' },
      { label: whanau.name || whanauId, url: getLink(whanau) },
      { label: member.name || id },
    ])
  }, [whanau, member, whanauId, id])

  return (
    <LayoutApp>
      <PageFrame simple>
        {
          isLoading
            ? (
              <Loader absolute />
            ) : (
              <>
                <Grid container mb={40} spacing={16}>
                  <Grid item xs={12} md={6} lg={8}>
                    <ViewMemberCard1 member={member} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <ViewMemberCard2 whanau={whanau} member={member} />
                  </Grid>
                </Grid>
                <Grid container mb={40}>
                  <Grid item xs={12}>
                    <ViewMemberCard3 member={member} />
                  </Grid>
                </Grid>
                <Grid container>
                  <ViewMemberChart member={member} />
                </Grid>
              </>
            )
        }
      </PageFrame>
    </LayoutApp>
  )
})

const mapStateToProps = (state: IState, ownProps: any): Props => ({
  id: ownProps.match.params.id,
  whanauId: ownProps.match.params.whanauId,
  member: state.app.members.detail.member,
  whanau: state.app.members.detail.whanau,
  isMemberLoading: state.app.members.isLoading,
  loadMember: memberService.loadDetail,
})

const ViewMemberConnected = connect(mapStateToProps)(ViewMember)

export { ViewMemberConnected as ViewMember }
