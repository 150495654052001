import React from 'react'
import { Card } from 'base/components/ui/Card'
import styles from './Info.module.scss'

interface Props {
  info: string[]
}

export const ViewCollectiveInfo = ({ info }: Props) => (
  <Card className={styles.root} title={'Explanation'}>
    {info.map(text => (
      <div key={text} className={styles.info}>{text}</div>
    ))}
  </Card>
)
