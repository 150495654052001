import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import { getChangesInSchool, trafficLightStatusByName, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent5Card2 = ({ className, whanau }: Props) => (
  <CardLine
    title={String(getChangesInSchool(whanau))}
    content={'Changes in schools'}
    color={trafficLightStatusByName('Changes in School', whanau)}
    className={className}
  />
)
