import React, { useState } from 'react'
import classNames from 'classnames'
import { Card } from 'base/components/ui/Card'
import { AreaChart } from 'components/shared/ui/charts/AreaChart'
import { formatMoney } from 'base/utils/functions'
import styles from './Chart.module.scss'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { TWhanau } from 'models/Whanau'
import { Loader } from 'base/components/ui/Loader'
import { fetchChart } from 'api/KWOApi'
import { prepareChartData } from 'helpers/ChartDataHelper'

interface Props {
  className?: string
  whanau: TWhanau
}

const fields = [
  { code: 'MSD Total', name: 'MSD Total', color: '#9B7DA0', props: { fillOpacity: 1 } },
  { code: 'MSD Previous Benefit Debt', name: 'MSD Previous Benefit Debt', color: '#5F4164', props: { fillOpacity: 1 } },
  { code: 'HNZ Debt', name: 'HNZ Debt', color: '#36B9B1', props: { fillOpacity: 1 } },
  {
    code: 'average debt',
    name: 'Average Debt',
    color: '#C4C4C4',
    props: {
      fillOpacity: 0,
      stroke: '#C4C4C4',
      strokeWidth: 8,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  },
]

export const TabContent6Chart = ({ className, whanau }: Props) => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async (id: string) => {
      const result = await fetchChart('economically-secure-and-nurturing', id)
      setData(result)
      setIsLoaded(true)
    }

    fetch(whanau.id).catch(window.logger.error)
  })

  return (
    <Card className={classNames(styles.root, className)}>
      {!isLoaded ? (
        <Loader absolute />
      ) : data.length > 0 ? (
        <AreaChart
          withLegend
          height={400}
          areas={fields.map(i => ({ name: i.name, color: i.color, field: i.code, props: i.props }))}
          data={prepareChartData(fields, data)}
          renderAxisValue={v => `${formatMoney(v)}`}
        />
      ) : (
        <div className={styles.noData}>No Data</div>
      )}
    </Card>
  )
}
