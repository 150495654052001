import React from 'react'
import { mem } from 'base/utils/mem'
import dayjs, { Dayjs } from 'dayjs'
import { TGoal } from 'models/Goal'
import { TEvent } from 'models/Event'
import { GoalsPlanMonthLine } from './goalsPlan/MonthLine'
import { GoalsPlanStatus } from './goalsPlan/Status'
import { GoalPlanEvents } from './goalsPlan/Events'
import { GoalsPlanNow } from './goalsPlan/Now'
import { GoalModal } from './GoalModal'
import styles from './GoalsPlan.module.scss'

const MONTH_WIDTH = 150

interface Props {
  goals: TGoal[]
  events: TEvent[]
}

export const GoalsPlan = mem(({ goals, events }: Props) => {
  const [openGoal, setOpenGoal] = React.useState<TGoal | null>(null)
  const minDay = getMinDay(goals, events)
  const maxDay = getMaxDay(goals, events)
  const monthDays = getMonthList(minDay, maxDay)

  const onGoalClick = (id: string) => {
    const goal = goals.find(i => i.id === id)
    setOpenGoal(goal || null)
  }

  return (
    <div className={styles.root}>
      <GoalPlanEvents events={ events } minDay={ minDay } maxDay={ maxDay }/>

      <GoalsPlanMonthLine monthDays={monthDays} monthWidth={MONTH_WIDTH} />

      {goals.length > 0 && (
        <>
          <GoalsPlanStatus goals={goals} minDay={minDay} maxDay={maxDay} onGoalClick={onGoalClick} />

          <GoalsPlanMonthLine monthDays={monthDays} monthWidth={MONTH_WIDTH} />
          <GoalsPlanNow minDay={minDay} maxDay={maxDay} />
          <GoalModal goal={openGoal} close={() => setOpenGoal(null)} />
        </>
      )}
    </div>
  )
})

const getMinDay = (goals: TGoal[], events: TEvent[]) =>
  dayjs(Math.min(...goals.map(i => i.tsFrom), ...events.map(i => i.ts))).date(1)

const getMaxDay = (goals: TGoal[], events: TEvent[]) =>
  dayjs(Math.max(...goals.map(i => i.tsTo), ...events.map(i => i.ts)))
    .add(1, 'month')
    .date(-1)

const getMonthList = (minDay: Dayjs, maxDay: Dayjs): Dayjs[] => {
  const result: Dayjs[] = []
  let day = minDay.clone()
  while (day.isBefore(maxDay, 'month') || day.isSame(maxDay, 'month')) {
    result.push(day)
    day = day.add(1, 'month')
  }
  return result
}
