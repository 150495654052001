import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Grid } from 'base/components/ui/Grid'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent4Table1 } from 'components/views/viewWhanauDetail/tabContent4/Table1'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent4 = ({ whanau }: Props) => (
  <div>
    <Grid container mb={16}>
      <Heading3>Participation in Te Ao Māori</Heading3>
    </Grid>

    <Grid container>
      <TabContent4Table1 whanau={whanau} />
    </Grid>
  </div>
)
