import React from 'react'
import { TGoal } from 'models/Goal'
import { TEvent } from 'models/Event'
import { Card } from 'base/components/ui/Card'
import styles from './CardGoalsPlan.module.scss'
import { GoalsPlan } from 'components/shared/ui/GoalsPlan'
import { Loader } from 'base/components/ui/Loader'

interface Props {
  isLoading: boolean
  goals: TGoal[]
  events: TEvent[]
}

export const TabContent1CardGoalsPlan = ({ goals, events, isLoading }: Props) => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null)
  scrollToCenter(ref)

  return (
    <Card ref={setRef} className={styles.root}>
      {isLoading ? <Loader absolute /> : <GoalsPlan goals={goals} events={events} />}
    </Card>
  )
}

const scrollToCenter = (div: HTMLDivElement | null) => {
  if (div) {
    const child1 = div.children[0]
    if (child1) {
      const child2 = child1.children[0]
      if (child2) {
        child1.scroll((child2.clientWidth - child1.clientWidth) / 2, 0)
      }
    }
  }
}
