import { IReportBreakDown } from 'api/KWOApi'

export const getComfortWithCulture = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'comfort with culture')
}

export const getKnowledgeOfIwiAndHapu = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'knowledge of iwi and hapu')
}

export const getLearningTeReo = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'learning te reo')
}

export const getKohangaParticipation = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'kohanga participation')
}

export const getDebt = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'debt') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getBenefit = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'benefit') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getEnrolledatGP = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'enrolled at gp') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getEDpresentations = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'ed presentations') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getConnectionWithServices = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'connection with services') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getNEET = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'neet') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getWhanauEmployed = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'whanau employed') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

export const getFamilyHarm = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'family harm') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}
export const getEventsCorrections = (rawData: IReportBreakDown[]) => {
  return findData(rawData, 'corrections') || { measure: '', dimension: '', num_val: 0, num_perc: 0 }
}

const findData = (rawData: IReportBreakDown[], measure: string) => {
  let result
  const data = rawData.find(i => i.measure.toLowerCase() === measure)
  if (data) {
    result = data
  }

  return result
}
