import React from 'react'
import { useOnMount } from 'base/hooks/useOnMount'
import { Meta } from 'components/shared/Meta'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { layoutService } from 'services/LayoutService'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { ViewCollectiveCard } from './viewCollective/Card'
import { ViewCollectiveInfo } from './viewCollective/Info'
import { collectiveService } from 'services/CollectiveService'
import { connect } from 'react-redux'
import { IState } from 'store'
import { TCollective } from 'models/Collective'

interface Props {
  items: TCollective[]
  isLoading: boolean
}

const ViewCollective = (props: Props) => {
  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Collective leadership' }])

    collectiveService.load().catch(window.logger.error)
  })
  return (
    <LayoutApp>
      <Meta title="Collective leadership" />
      <PageFrame simple>
        <Grid container spacing={16} mb={32}>
          {props.items.map(i => (
            <ViewCollectiveCard key={i.id} title={i.area} description={i.description} score={i.score} />
          ))}
        </Grid>

        <ViewCollectiveInfo info={data.info} />
      </PageFrame>
    </LayoutApp>
  )
}

const data = {
  info: ['Updated annually. Last update: August 2018'],
}

interface Props {}

const mapStateToProps = (state: IState): Props => ({
  items: state.app.collective.items,
  isLoading: state.app.collective.isLoading,
})

const ViewCollectiveConnected = connect(mapStateToProps)(ViewCollective)
export { ViewCollectiveConnected as ViewCollective }
