import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { AreaChart } from 'components/shared/ui/charts/AreaChart'
import { formatMoney } from 'base/utils/functions'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchChart } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import { prepareChartData } from 'helpers/ChartDataHelper'

const fields = [
  { code: 'MSD Previous Debt Amount', name: 'MSD Previous Debt Amount', color: '#5F4164', props: { fillOpacity: 1 } },
  { code: 'MSD total', name: 'MSD total', color: '#9B7DA0', props: { fillOpacity: 1 } },
  { code: 'HNZ debt', name: 'HNZ debt', color: '#36B9B1', props: { fillOpacity: 1 } },
  {
    code: 'average debt',
    name: 'Average debt',
    color: '#C4C4C4',
    props: {
      fillOpacity: 0,
      stroke: '#C4C4C4',
      strokeWidth: 4,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  },
]

export const TabContent6Card2 = () => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchChart('debt')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card padding={24} title={'Debt over time'}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <AreaChart
          withLegend
          height={200}
          areas={fields.map(i => ({ name: i.name, color: i.color, field: i.code, props: i.props }))}
          data={prepareChartData(fields, data)}
          renderAxisValue={v => `${formatMoney(v)}`}
        />
      </LoaderPane>
    </Card>
  )
}
