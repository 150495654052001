import React from 'react'
import classNames from 'classnames'
import { Paper } from '@material-ui/core'
import { IconWhanau1 } from 'components/shared/icons/domain/IconWhanau1'
import { IconWhanau2 } from 'components/shared/icons/domain/IconWhanau2'
import { IconWhanau3 } from 'components/shared/icons/domain/IconWhanau3'
import { IconWhanau4 } from 'components/shared/icons/domain/IconWhanau4'
import { IconWhanau5 } from 'components/shared/icons/domain/IconWhanau5'
import { IconWhanau6 } from 'components/shared/icons/domain/IconWhanau6'
import styles from './CardIcon.module.scss'
import { getTeReoStatus } from 'helpers/KWODataHelper'

export type TColor = 'redAlert' | 'concerning' | 'positive' | 'none'

interface Props {
  color: TColor
  iconType: number
  title: string
  status: string
  issues: { title: string; color: TColor }[]
  className?: string
}

export const CardIcon = ({ color, iconType, title, status, issues, className }: Props) => {
  const statusClass = classNames(styles.status, styles[`${color}Color`])
  const teReoStatus = getTeReoStatus(status)

  return (
    <Paper className={classNames(styles.root, className)}>
      <div className={classNames(styles.left)}>{getIcon(iconType, color)}</div>
      <div className={styles.right}>
        <div className={styles.title}>{title}</div>
        <div className={statusClass}>{teReoStatus}</div>
        <div className={styles.issues}>
          {issues.map((i, index) => {
            return (
              <div key={index} className={styles.issue}>
                <div className={classNames(styles.point, styles[i.color])} />
                {i.title}
              </div>
            )
          })}
        </div>
      </div>
    </Paper>
  )
}

const iconSize = 107
const getIcon = (type: number, color: TColor) => {
  switch (type) {
    case 1:
      return <IconWhanau1 color={color} width={iconSize} height={iconSize} />
    case 2:
      return <IconWhanau2 color={color} width={iconSize} height={iconSize} />
    case 3:
      return <IconWhanau3 color={color} width={iconSize} height={iconSize} />
    case 4:
      return <IconWhanau4 color={color} width={iconSize} height={iconSize} />
    case 5:
      return <IconWhanau5 color={color} width={iconSize} height={iconSize} />
    case 6:
      return <IconWhanau6 color={color} width={iconSize} height={iconSize} />
    default:
      return null
  }
}
