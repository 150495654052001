import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { HorizontalBarItem } from 'components/shared/ui/charts/HorizontalBarItem'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { useOnMount } from 'base/hooks/useOnMount'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

const colorScheme = new Map<string, string>([
  ['gp enrolments', '#26C6DA'],
  ['immunised', '#36B9B1'],
  ['home insulated', '#2B6A7D'],
])
export const TabContent3Card1 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('health-status')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card title={'Health Status: Proportions of all whānau'} padding={24}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
      {data.map(item => (
        <HorizontalBarItem
          key={`${item.measure}-${item.dimension}`}
          title={item.dimension}
          titleWidth={80}
          color={colorScheme.get(item.dimension.toLowerCase())}
          areaColor={'#EAEAEA'}
          value={item.num_perc}
          renderValue={v => `${v}%`}
          valuePosition={'outside'}
          titlePosition={'top'}
          max={100}
        />
      ))}
      </LoaderPane>
    </Card>
  )
}
