import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import {
  getEnrolledInGPCount,
  getEnrolledInGPTotalCount,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent2Card2 = ({ className, whanau }: Props) => (
  <CardLine
    title={`${getEnrolledInGPCount(whanau)}/${getEnrolledInGPTotalCount(whanau)} Enrolled at a GP`}
    content={'Enrolled at a GP'}
    color={getTrafficColorLowest(whanau, 'enrolled at gp')}
    className={className}
  />
)
