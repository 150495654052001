import React from 'react'
import { Dayjs } from 'dayjs'
import styles from './MonthLine.module.scss'

interface Props {
  monthWidth: number
  monthDays: Dayjs[]
}

export const GoalsPlanMonthLine = ({ monthWidth, monthDays }: Props) => (
  <div className={styles.root}>
    {monthDays.map(monthDay => (
      <div key={monthDay.toString()} className={styles.month} style={{ width: monthWidth }}>
        <span>{monthDay.format('MMMM')}</span>
        {!monthDay.month() && <span>{monthDay.format('YYYY')}</span>}
      </div>
    ))}
  </div>
)
