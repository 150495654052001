import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import styles from './Modal.module.scss'

interface Props {
  open: boolean
  title: string
  content: React.ReactNode
  cancel?: boolean
  actionLabel: string
  onAction?: () => void
  close: () => void
  classNamePaper?: string
}

export const Modal = ({ open, title, content, actionLabel, onAction, cancel, close, classNamePaper }: Props) => {
  return (
    <Dialog className={styles.root} classes={{ paper: classNamePaper }} open={open} onClose={close}>
      <DialogTitle classes={{ root: styles.title }}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className={styles.actions}>
        {cancel && (
          <Button onClick={close} className={styles.cancelButton}>
            Cancel
          </Button>
        )}
        <Button onClick={onAction} className={styles.submitButton} variant={'contained'} autoFocus>
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
