import React from 'react'
import { CardLine } from 'components/shared/ui/CardLine'
import {
  getTotalDebtToGovernmentAgencies,
  getTrafficColorLowest,
  TWhanau,
} from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent6Card2 = ({ className, whanau }: Props) => (
  <CardLine
    title={`${getTotalDebtToGovernmentAgencies(whanau)}`}
    content={'Total debts and fines'}
    color={getTrafficColorLowest(whanau, 'total debt to government agencies')}
    className={className}
  />
)
