import * as React from 'react'
import { History } from 'history'
import posed from 'react-pose'
import Paper from '@material-ui/core/Paper'
import { LayoutSimple } from 'components/shared/layouts/LayoutSimple'
import styles from './Common.module.scss'
import Fab from '@material-ui/core/Fab'
import classNames from 'classnames'

export interface Props {
  history: History
}

interface State {
  finish: boolean
}

export class ViewNotFound extends React.Component<Props, State> {
  public state: State = { finish: false }

  public goToMain = () => {
    this.setState({ finish: true })
  }

  public onAnimationComplete = (pose: any) => {
    if (pose === 'finish') {
      this.props.history.push('/')
    }
  }

  public render() {
    return (
      <LayoutSimple>
        <Animated
          className={styles.root}
          initialPose="start"
          pose={this.state.finish ? 'finish' : 'active'}
          onPoseComplete={this.onAnimationComplete}
        >
          <Paper className={styles.paper}>
            <div className={styles.title}>Page not found</div>
            <Fab className={classNames(styles.button, styles.linkNotFound)} variant="extended" onClick={this.goToMain}>
              Go to main page
            </Fab>
          </Paper>
        </Animated>
      </LayoutSimple>
    )
  }
}

const Animated = posed.div({
  start: { x: '-50%', y: '-60%', opacity: 0 },
  active: { x: '-50%', y: '-50%', opacity: 1, transition: { duration: 300 } },
  finish: { x: '-50%', y: '-60%', opacity: 0, transition: { duration: 300 } },
})
