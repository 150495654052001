import { appGetState, appNextState, appUpdateState, initialState } from 'store'
import { TModel as TUser, create } from 'models/User'
import { fetchMany, fetchOneById, resetPassword, save, suspend } from 'api/UserApi'
import { layoutService } from 'services/LayoutService'

export type TUserFilter = typeof initialState.users.list.filter

const comment = (m: string) => `UserService::${m}`

class UserService {
  private appGetState = appGetState
  private appNextState = appNextState
  private appUpdateState = appUpdateState

  /**
   */
  public loadList = async (filter: TUserFilter) => {
    this.setFilter(filter)
    this.setIsLoading(true)
    try {
      const { items, total } = await fetchMany(filter)
      this.appUpdateState(s => {
        s.users.list.items = items
        s.users.list.total = total
      }, comment('loadList'))
    } catch (e) {}
    this.setIsLoading(false)
  }

  /**
   */
  public clearList = () => {
    this.appUpdateState(s => {
      s.users.list.items = []
      s.users.list.total = 0
      s.users.list.filter.page = 0
    }, comment('clearList'))
  }

  public clearDetail = () => {
    this.setUserDetails(create())
  }

  /**
   */
  public loadDetail = async (id: string) => {
    if (this.appGetState().users.detail.user.id !== id) {
      this.setIsLoading(true)
      try {
        this.setUserDetails(create())
        const user = await fetchOneById(id)
        this.setUserDetails(user)
      } catch (e) {}
      this.setIsLoading(false)
    }
  }

  /**
   */
  public saveDetail = async (user: TUser, oldUser: TUser): Promise<TUser> => {
    try {
      const nextUser = await save(user, oldUser)

      if (nextUser.role !== user.role) {
        layoutService.showSnackbar('error', 'Unable to update user role.')
      }

      layoutService.showSnackbar(
        'success',
        user.id ? 'The user was successfully updated!' : 'The user was successfully added!'
      )
      this.clearList()
      this.setUserDetails(nextUser)
      return nextUser
    } catch (error) {
      layoutService.showSnackbar('error', error.serverMessage || 'Error')
      return oldUser
    }
  }

  /**
   */
  public suspend = async (suspended: boolean, customUser?: TUser): Promise<TUser> => {
    const user = customUser || this.appGetState().users.detail.user

    try {
      const nextUser = await suspend(user, suspended)
      if (!customUser) {
        this.setUserDetails(nextUser)
      }
      layoutService.showSnackbar(
        'success',
        suspended ? `${getName(user)} was successfully deleted!` : `${getName(user)} was successfully restored!`
      )
      return nextUser
    } catch (error) {
      layoutService.showSnackbar(
        'error',
        error.serverMessage ||
          (suspend
            ? 'Unable to suspend user. Please try again later!'
            : 'Unable to restore user. Please try again later!')
      )
      return user
    }
  }

  /**
   */
  public resetPassword = async (user: TUser): Promise<void> => {
    try {
      await resetPassword(user)
      layoutService.showSnackbar('success', `${getName(user)}'s password has been reset!`)
    } catch (error) {
      layoutService.showSnackbar('error', error.serverMessage || 'Unable to reset password. Please try again later!')
    }
  }

  /**
   */
  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(s => (s.users.isLoading = isLoading), comment('setIsLoading'))
  }

  /**
   */
  private setUserDetails = (user: TUser): void => {
    this.appUpdateState(s => (s.users.detail.user = { ...user }), comment('setUserDetails'))
  }

  /**
   */
  private setFilter = (filter: TUserFilter): void => {
    this.appUpdateState(s => (s.users.list.filter = { ...filter }), comment('setFilter'))
  }
}

export const getName = (user: TUser) => `${user.firstName} ${user.lastName}`

export const confirmResetPassword = (user: TUser) => {
  layoutService.confirm(
    'Reset Password',
    `Are you sure you wish to reset ${getName(user)}'s password?`,
    'Reset Password',
    () => userService.resetPassword(user),
    true
  )
}

export const confirmDeleteUser = (customUser?: TUser) => {
  const user = customUser || appGetState().users.detail.user

  layoutService.confirm(
    'Delete User',
    `Are you sure you wish to delete ${getName(user)}? They will no longer be able to access this system.`,
    'Delete User',
    () => userService.suspend(true, customUser)
  )
}

const userService = new UserService()
export { userService }
