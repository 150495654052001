// const DEV_API = 'https://dev2.mates-and-dates.synergia.nz/api/v3'
const DEV_API = 'https://dev.tetihi.synergia.nz/api/v3'
const PROD_API = `${window.location.origin}/api/v3`
const API = process.env.NODE_ENV === 'development' ? DEV_API : PROD_API

export const LOGIN = `${API}/sign-in`
export const LOGOUT = `${API}/sign-out`
export const ME = `${API}/me`

export const SEND_PASSWORD_RESET = `${API}/send-password-reset`
export const CHANGE_PASSWORD = `${API}/change-password`

export const USERS = `${API}/users`
export const ASSIGN_ROLE = `${API}/assign-role`
export const CHANGE_SUSPEND_USER = `${API}/change-suspend-user`
export const REPORT_TABLES = `${API}/report-tables`
export const REPORT_BREAK_DOWN = `${API}/report-break-down`
export const CHART_DATA = `${API}/report-time-series`
export const WHANAU_LIST = `${API}/whanau-list`
export const NAVIGATORS = `${API}/navigators`
export const CONTACTS = `${API}/contacts`
export const GOALS = `${API}/goals`
