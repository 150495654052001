import React from 'react'
import { CardIcon } from 'components/shared/ui/CardIcon'
import { getTrafficColorLowest, trafficLightStatusByName, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent5Card1 = ({ className, whanau }: Props) => (
  <CardIcon
    iconType={3}
    color={trafficLightStatusByName('Changes in School', whanau)}
    title={'Participating in Society'}
    status={'Concerning'}
    issues={[
      { title: 'Changes in schools', color: getTrafficColorLowest(whanau, 'changes in school') },
      { title: 'Employment', color: getTrafficColorLowest(whanau, 'employment') },
    ]}
    className={className}
  />
)
