import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconMaze = React.memo(({ width, height, className }: Props) => (
  <svg
    className={`MuiSvgIcon-root ${className}`}
    viewBox="0 0 199 320"
    width={width}
    height={height}
    fill="currentColor"
  >
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="199" height="320">
      <rect width="199" height="320" rx="5" fill="#FF6565" />
    </mask>
    <g opacity="0.2" mask="url(#mask0)">
      <path d="M146.915 -1.55402H106.625V13.1891H146.915V-1.55402Z" />
      <path d="M146.915 56.1171H106.625V70.8602H146.915V56.1171Z" />
      <path d="M146.915 113.79H106.625V128.533H146.915V113.79Z" />
      <path d="M172.957 27.9321H132.667V42.6752H172.957V27.9321Z" />
      <path d="M172.957 85.6033H132.667V100.346H172.957V85.6033Z" />
      <path d="M172.957 143.274H132.667V158.017H172.957V143.274Z" />
      <path d="M198.999 57.4169H157.102V72.16H198.999V57.4169Z" />
      <path d="M198.999 115.088H157.102V129.831H198.999V115.088Z" />
      <path d="M198.999 172.761H157.102V187.504H198.999V172.761Z" />
      <path d="M146.916 13.1891H132.667V27.9323H146.916V13.1891Z" />
      <path d="M146.916 70.8605H132.667V85.6036H146.916V70.8605Z" />
      <path d="M146.916 128.531H132.667V143.274H146.916V128.531Z" />
      <path d="M122.476 27.9324H106.623V56.1187H122.476V27.9324Z" />
      <path d="M122.476 85.603H106.623V113.789H122.476V85.603Z" />
      <path d="M172.957 42.6741H157.102V57.4172H172.957V42.6741Z" />
      <path d="M172.957 100.346H157.102V115.089H172.957V100.346Z" />
      <path d="M172.957 158.018H157.102V172.761H172.957V158.018Z" />
      <path d="M199 72.1604H183.145V101.46H199V72.1604Z" />
      <path d="M199 129.831H183.145V159.131H199V129.831Z" />
      <path d="M199 187.504H183.145V216.803H199V187.504Z" />
      <path d="M52.085 -1.55402H92.376V13.1891H52.085V-1.55402Z" />
      <path d="M52.085 56.1171H92.376V70.8602H52.085V56.1171Z" />
      <path d="M52.085 113.79H92.376V128.533H52.085V113.79Z" />
      <path d="M26.0422 27.9321H66.3319V42.6752H26.0422V27.9321Z" />
      <path d="M26.0422 85.6033H66.3319V100.346H26.0422V85.6033Z" />
      <path d="M26.0422 143.274H66.3319V158.017H26.0422V143.274Z" />
      <path d="M0 57.4169H41.8974V72.16H0V57.4169Z" />
      <path d="M0 115.088H41.8974V129.831H0V115.088Z" />
      <path d="M0 172.761H41.8974V187.504H0V172.761Z" />
      <path d="M52.085 13.1891H66.332V27.9323H52.085V13.1891Z" />
      <path d="M52.085 70.8605H66.332V85.6036H52.085V70.8605Z" />
      <path d="M52.085 128.531H66.332V143.274H52.085V128.531Z" />
      <path d="M76.521 27.9324H92.3757V56.1187H76.521V27.9324Z" />
      <path d="M76.521 85.603H92.3757V113.789H76.521V85.603Z" />
      <path d="M26.0422 42.6741H41.8971V57.4172H26.0422V42.6741Z" />
      <path d="M26.0422 100.346H41.8971V115.089H26.0422V100.346Z" />
      <path d="M26.0422 158.018H41.8971V172.761H26.0422V158.018Z" />
      <path d="M0 72.1604H15.8547V101.46H0V72.1604Z" />
      <path d="M0 129.831H15.8547V159.131H0V129.831Z" />
      <path d="M0 187.504H15.8547V216.803H0V187.504Z" />
      <path d="M106.623 27.9321H92.3748V42.6752H106.623V27.9321Z" />
      <path d="M106.623 85.6033H92.3748V100.346H106.623V85.6033Z" />
      <path d="M122.476 315.168H106.623V343.354H122.476V315.168Z" />
      <path d="M76.521 315.168H92.3757V343.354H76.521V315.168Z" />
      <path d="M106.623 315.168H92.3748V329.911H106.623V315.168Z" />
      <path d="M198.999 0.306695H157.102V15.0498H198.999V0.306695Z" />
      <path d="M172.957 -14.4367H157.102V0.306396H172.957V-14.4367Z" />
      <path d="M199 15.0496H183.145V44.3489H199V15.0496Z" />
      <path d="M0 0.306695H41.8974V15.0498H0V0.306695Z" />
      <path d="M26.0422 -14.4367H41.8971V0.306396H26.0422V-14.4367Z" />
      <path d="M0 15.0496H15.8547V44.3489H0V15.0496Z" />
      <path d="M146.915 170.9H106.625V185.643H146.915V170.9Z" />
      <path d="M146.915 228.571H106.625V243.314H146.915V228.571Z" />
      <path d="M146.915 286.244H106.625V300.987H146.915V286.244Z" />
      <path d="M172.957 200.386H132.667V215.13H172.957V200.386Z" />
      <path d="M172.957 258.057H132.667V272.8H172.957V258.057Z" />
      <path d="M172.957 315.729H132.667V330.472H172.957V315.729Z" />
      <path d="M198.999 229.871H157.102V244.615H198.999V229.871Z" />
      <path d="M198.999 287.542H157.102V302.285H198.999V287.542Z" />
      <path d="M146.916 185.643H132.667V200.386H146.916V185.643Z" />
      <path d="M146.916 243.314H132.667V258.057H146.916V243.314Z" />
      <path d="M146.916 300.986H132.667V315.729H146.916V300.986Z" />
      <path d="M122.476 142.714H106.623V170.9H122.476V142.714Z" />
      <path d="M122.476 200.386H106.623V228.573H122.476V200.386Z" />
      <path d="M122.476 258.058H106.623V286.244H122.476V258.058Z" />
      <path d="M172.957 215.128H157.102V229.871H172.957V215.128Z" />
      <path d="M172.957 272.801H157.102V287.544H172.957V272.801Z" />
      <path d="M199 244.614H183.145V273.914H199V244.614Z" />
      <path d="M199 302.285H183.145V331.585H199V302.285Z" />
      <path d="M52.085 170.9H92.376V185.643H52.085V170.9Z" />
      <path d="M52.085 228.571H92.376V243.314H52.085V228.571Z" />
      <path d="M52.085 286.244H92.376V300.987H52.085V286.244Z" />
      <path d="M26.0422 200.386H66.3319V215.13H26.0422V200.386Z" />
      <path d="M26.0422 258.057H66.3319V272.8H26.0422V258.057Z" />
      <path d="M26.0422 315.729H66.3319V330.472H26.0422V315.729Z" />
      <path d="M0 229.871H41.8974V244.615H0V229.871Z" />
      <path d="M0 287.542H41.8974V302.285H0V287.542Z" />
      <path d="M52.085 185.643H66.332V200.386H52.085V185.643Z" />
      <path d="M52.085 243.314H66.332V258.057H52.085V243.314Z" />
      <path d="M52.085 300.986H66.332V315.729H52.085V300.986Z" />
      <path d="M76.521 142.714H92.3757V170.9H76.521V142.714Z" />
      <path d="M76.521 200.386H92.3757V228.573H76.521V200.386Z" />
      <path d="M76.521 258.058H92.3757V286.244H76.521V258.058Z" />
      <path d="M26.0422 215.128H41.8971V229.871H26.0422V215.128Z" />
      <path d="M26.0422 272.801H41.8971V287.544H26.0422V272.801Z" />
      <path d="M0 244.614H15.8547V273.914H0V244.614Z" />
      <path d="M0 302.285H15.8547V331.585H0V302.285Z" />
      <path d="M106.623 142.714H92.3748V157.457H106.623V142.714Z" />
      <path d="M106.623 200.386H92.3748V215.13H106.623V200.386Z" />
      <path d="M106.623 258.057H92.3748V272.8H106.623V258.057Z" />
    </g>
  </svg>
))
