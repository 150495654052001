export const getCurrencySymbol = (currencyCode: string) => {
  const options = {
    currency: 'NZD',
    style: 'currency',
  }
  try {
    const format = new Intl.NumberFormat([], options)
    return String(format.format(0))
      .replace('0,00', '')
      .replace('0.00', '')
  } catch (e) {
    window.logger.error(e)
  }
  return '$'
}
