import React from 'react'
import { TColor } from 'components/shared/ui/CardIcon'
import { IconTick } from 'components/shared/icons/IconTick'
import classNames from 'classnames'
import styles from './Markers.module.scss'

interface Props {
  total: number
  color: TColor
  count?: number
}

export const ViewWhanauDetailMarkers = ({ total, count, color }: Props) => (
  <div className={styles.root}>
    {Array(total)
      .fill('')
      .map((i, index) => {
        return (
          <div className={classNames(styles.round, styles[color])} key={index}>
            {count && index < count && <IconTick width={12} height={12} />}
          </div>
        )
      })}
  </div>
)
