import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconAbout = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 28 28" width={width} height={height} fill="currentColor">
    <path
      d="M19.8333 13.9999V3.49992C19.8333 3.1905 19.7103 2.89375 19.4915 2.67496C19.2728 2.45617 18.976 2.33325 18.6666 2.33325H3.49992C3.1905 2.33325 2.89375 2.45617 2.67496 2.67496C2.45617 2.89375 2.33325 3.1905 2.33325 3.49992V19.8333L6.99992 15.1666H18.6666C18.976 15.1666 19.2728 15.0437 19.4915 14.8249C19.7103 14.6061 19.8333 14.3093 19.8333 13.9999ZM24.4999 6.99992H22.1666V17.4999H6.99992V19.8333C6.99992 20.1427 7.12284 20.4394 7.34163 20.6582C7.56042 20.877 7.85717 20.9999 8.16659 20.9999H20.9999L25.6666 25.6666V8.16659C25.6666 7.85717 25.5437 7.56042 25.3249 7.34163C25.1061 7.12284 24.8093 6.99992 24.4999 6.99992Z"
      fill="#6F6F6F"
    />
  </svg>
))
