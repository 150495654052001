import { IKWOData } from '../store/state'
import { TAgeCategory, TGender } from '../models/Member'
import { getCurrencySymbol } from './MoneyHelper'
import dayjs from 'dayjs'

export const getInProgramSince = (rawData?: IKWOData[]) => {
  let result = '0'
  if (rawData) {
    const data = findData(rawData, 'in program since')
    if (data && data.num_val) {
      result = dayjs(data.num_val).format('MMMM YYYY')
    }
  }

  return result
}
export const getNeet = (rawData?: IKWOData[]) => {
  return getString('16-24 year old neet', rawData)
}
export const getBenefit = (rawData?: IKWOData[]) => {
  return getString('benefit', rawData)
}
export const getWorkObligation = (rawData?: IKWOData[]) => {
  return getString('work obligation', rawData)
}
export const getTrainingReferral = (rawData?: IKWOData[]) => {
  return getString('training referral', rawData)
}
export const getEmployment = (rawData?: IKWOData[]) => {
  return getString('employment', rawData)
}

export const getCurrentSchools = (rawData?: IKWOData[]) => {
  return getString('current schools', rawData)
}
export const getTotalDebt = (rawData?: IKWOData[]) => {
  if (rawData) {
    return getTotalMoney('total debt', rawData)
  }
  return ''
}

export const getGender = (rawData?: IKWOData[]) => {
  let result: TGender
  if (rawData) {
    const data = findData(rawData, 'gender')
    if (data) {
      result = data.str_val.toLowerCase() === 'male' ? 'M' : 'F'
    }
  }

  return result
}

export const getAge = (rawData?: IKWOData[]) => {
  let result: number = 0
  if (rawData) {
    const data = findData(rawData, 'age')
    if (data) {
      result = data.num_val
    }
  }

  return result
}

export const getEnrolled = (rawData?: IKWOData[]) => {
  return getString('enrolled gp practice', rawData)
}

export const getEthnicity = (rawData?: IKWOData[]) => {
  return getString('ethnicity', rawData)
}

export const getIWI = (rawData?: IKWOData[]) => {
  return getString('iwi', rawData) || 'Non recorded'
}

export const getAddress = (rawData?: IKWOData[]) => {
  return getString('address', rawData)
}

export const getAgeCategory = (age: number) => {
  let result: TAgeCategory = 0
  if (age <= 5) {
    result = 0
  }

  if (age > 5 && age <= 12) {
    result = 1
  }

  if (age > 12 && age <= 17) {
    result = 2
  }

  if (age > 17 && age <= 64) {
    result = 3
  }

  if (age > 64) {
    result = 4
  }

  return result
}

export const getAgeCategoryName = (value: TAgeCategory) => {
  let result = ''
  switch (value) {
    case 0:
      result = 'Pepi'
      break
    case 1:
      result = 'Tamariki'
      break
    case 2:
      result = 'Rangatahi'
      break
    case 3:
      result = 'Maatua'
      break
    case 4:
      result = 'Kaumatua'
      break
    default:
      result = ''
  }

  return result
}

export const getWhanauNumber = (rawData?: IKWOData[]) => {
  return getNumber('whanau', rawData)
}

export const getPeopleNumber = (rawData?: IKWOData[]) => {
  return getNumber('people', rawData)
}

export const getReceivingWhanauDirectNumber = (rawData?: IKWOData[]) => {
  return getNumber('receiving whanau direct', rawData)
}

export const getTotalRecoverable = (rawData?: IKWOData[]) => {
  if (rawData) {
    return getTotalMoney('recoverable', rawData)
  }
  return ''
}

export const getTotalNonRecoverable = (rawData?: IKWOData[]) => {
  if (rawData) {
    return getTotalMoney('non recoverable', rawData)
  }
  return ''
}

export const getTotalAverageDebt = (rawData?: IKWOData[]) => {
  if (rawData) {
    return getTotalMoney('Average Debt', rawData)
  }
  return ''
}

export const getNumberofWhanauInDebt = (rawData?: IKWOData[]) => {
  return getNumber('number of whanau in debt', rawData)
}

const getString = (measure: string, rawData?: IKWOData[]) => {
  let result = ''
  if (rawData) {
    const data = findData(rawData, measure)
    if (data) {
      result = data.str_val
    }
  }

  return result
}

const getNumber = (measure: string, rawData?: IKWOData[]) => {
  let result
  if (rawData) {
    const data = findData(rawData, measure)
    if (data) {
      result = data.num_val
    }
  }

  return result
}

const getTotalMoney = (measure: string, rawData: IKWOData[]) => {
  const data = findData(rawData, measure)
  if (data) {
    return `${getCurrencySymbol(data.units)} ${data.num_val}`
  }
  return ''
}
const findData = (rawData: IKWOData[], measure: string) => {
  let result
  const data = rawData.find(i => i.measure.toLowerCase() === measure)
  if (data) {
    result = data
  }

  return result
}

export const getTeReoStatus = (rawStatus: string) => {
  const def: { [index: string]: any } = {
    concerning: 'MAURI OHO',
    positive: 'MAURI RERE',
    negative: 'MAURI NOHO',
  }
  return def[rawStatus.toLowerCase()] || rawStatus
}
