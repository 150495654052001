import React from 'react'
import classNames from 'classnames'
import styles from './Legend.module.scss'

interface TItem {
  name: string
  color: string
}

interface Props {
  items: TItem[]
  vertical?: boolean
  className?: string
}

export const Legend = ({ items, vertical, className }: Props) => (
  <div className={classNames(styles.root, vertical && styles.vertical, className)}>
    {items.map(item => (
      <div key={item.name} className={styles.item}>
        <div className={styles.round} style={{ background: item.color }} />
        {item.name}
      </div>
    ))}
  </div>
)
