import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Paper } from '@material-ui/core'
import styles from './Card.module.scss'

interface Props extends RouteComponentProps {
  to: string
  name: string
  iconLeft: JSX.Element
  iconRight?: JSX.Element
  gradient: string[]
}

const ViewHomeCard = ({ name, gradient, iconLeft, iconRight, to, history: { push } }: Props) => {
  const handleClick = () => {
    push(to)
  }

  return (
    <Paper className={styles.root} onClick={handleClick}>
      <div
        className={styles.content}
        style={{ background: `linear-gradient(90deg, ${gradient[0]} 3.09%, ${gradient[1]} 100%)` }}
      >
        <div className={styles.icons}>
          {iconLeft}
          <div className={styles.right}>{iconRight}</div>
        </div>
      </div>
      <div className={styles.footer} style={{ color: gradient[1] }}>
        {name}
      </div>
    </Paper>
  )
}

const ViewHomeCardConnected = withRouter(ViewHomeCard)
export { ViewHomeCardConnected as ViewHomeCard }
