import React from 'react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { mem } from 'base/utils/mem'
import { TGoalArea } from 'models/Goal'
import styles from './Item.module.scss'
import { Text } from 'base/components/ui/Text'
import { IconGoalCurrent } from 'components/shared/icons/IconGoalCurrent'
import { IconGoalComplete } from 'components/shared/icons/IconGoalComplete'
import { useMultiFocus } from 'hooks/useMultiFocus'

interface Props {
  goalArea: TGoalArea
  onGoalClick: (id: string) => void
}

export const GoalRowItem = mem(({ goalArea, onGoalClick }: Props) => {
  const [ref, setRef] = React.useState(null as null | HTMLDivElement)
  const { isFocused, setFocus } = useMultiFocus('goals')

  const classes = classNames(
    styles.root,
    !goalArea.complete && styles.notCompleted,
    isFocused(goalArea.item.id) && styles.focus
  )

  return (
    <div
      ref={setRef}
      key={goalArea.item.id}
      className={classes}
      style={{
        left: `${goalArea.dx}%`,
        width: `${goalArea.width}%`,
      }}
      onMouseOver={() => setFocus(goalArea.item.id, true)}
      onMouseOut={() => setFocus(goalArea.item.id, false)}
      onClick={() => onGoalClick(goalArea.item.id)}
    >
      <div className={styles.line}>{ref && <SvgLine el={ref} today={goalArea.today} />}</div>
      <div className={styles.start} data-for={`goal:start:${goalArea.item.id}`} data-tip={''}>
        <IconGoalCurrent width={24} height={24} />
        <ReactTooltip id={`goal:start:${goalArea.item.id}`} effect="solid">
          <Text noWrap size={10}>{goalArea.item.from}</Text>
        </ReactTooltip>
      </div>

      {goalArea.complete && (
        <div className={styles.end} data-for={`goal:end:${goalArea.item.id}`} data-tip={''}>
          <IconGoalComplete width={36} height={36} />
          <ReactTooltip id={`goal:end:${goalArea.item.id}`} effect="solid">
            <Text noWrap size={10}>{goalArea.item.to}</Text>
          </ReactTooltip>
        </div>
      )}
    </div>
  )
})

const SvgLine = ({ el, today }: { el: HTMLDivElement; today: number }) => (
  <svg width={el.clientWidth} height={'100%'} viewBox={`0 0 ${el.clientWidth} 2`}>
    {today > 100 && (
      <path d={`M12,1 L${el.clientWidth - 16},1`} stroke="currentColor" strokeLinecap={'round'} strokeWidth={2} />
    )}
    {today < 0 && (
      <path
        d={`M12,1 L${el.clientWidth - 16},1`}
        stroke="currentColor"
        strokeLinecap={'round'}
        strokeDasharray="7"
        strokeWidth={2}
      />
    )}
    {today >= 0 && today <= 100 && (
      <>
        <path
          d={`M12,1 L${(el.clientWidth / 100) * today},1`}
          stroke="currentColor"
          strokeLinecap={'round'}
          strokeWidth={2}
        />
        }
        <path
          d={`M${(el.clientWidth / 100) * today},1 L${el.clientWidth - 16},1`}
          stroke="currentColor"
          strokeLinecap={'round'}
          strokeDasharray="7"
          strokeWidth={2}
        />
        }
      </>
    )}
  </svg>
)
