import React from 'react'
import classNames from 'classnames'
import { useWindowSize } from 'base/hooks/useWindowSize'
import { PageTabs } from 'base/components/ui/PageTabs'
import styles from './Tabs.module.scss'
import { IconWhanauRangatiratanga } from 'components/shared/icons/domain/colorfull/IconWhanauRangatiratanga'
import { IconWhanauHauoranga } from 'components/shared/icons/domain/colorfull/IconWhanauHauoranga'
import { IconWhanauKotahitanga } from 'components/shared/icons/domain/colorfull/IconWhanauKotahitanga'
import { IconWhanauTuakiritanga } from 'components/shared/icons/domain/colorfull/IconWhanauTuakiritanga'
import { IconWhanauPaporitanga } from 'components/shared/icons/domain/colorfull/IconWhanauPaporitanga'
import { IconWhanauPukenga } from 'components/shared/icons/domain/colorfull/IconWhanauPukenga'
import { TooltipIconButton } from 'base/components/ui/TooltipIconButton'

interface Props {
  tab: string
  onTabChange: (tab: string) => void
}

export const ViewWhanauDetailTabs = ({ tab, onTabChange }: Props) => {
  const { width } = useWindowSize()
  const iconSize = 100
  const tabsData = React.useMemo(() => getTabsData(iconSize), [iconSize])

  return (
    <div className={styles.root}>
      {width <= 960 && <PageTabs initialTab={tab} tab={tab} items={tabsData} onTabChange={onTabChange} />}

      {width > 960 && (
        <div className={styles.tabs}>
          {tabsData.map(tabData => (
            <div
              key={tabData.value}
              className={classNames(styles.tab, tabData.value === tab && styles.active)}
              onClick={() => onTabChange(tabData.value)}
            >
              {tabData.icon}
              <div className={styles.label}>{tabData.html}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const getTabsData = (iconSize: number) => [
  {
    value: 'tab1',
    label: 'Whānau Self Managing - Rangatiratanga',
    html: (
      <>
        Rangatiratanga
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauRangatiratanga width={iconSize} height={iconSize} />}
        tooltipText={'Whānau Self Managing'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
  {
    value: 'tab2',
    label: 'Whānau Healthy Lifestyles - Hauoranga',
    html: (
      <>
        Hauoranga
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauHauoranga width={iconSize} height={iconSize} />}
        tooltipText={'Whānau Healthy Lifestyles'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
  {
    value: 'tab3',
    label: 'Whānau Cohesion - Kotahitanga',
    html: (
      <>
        Kotahitanga
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauKotahitanga width={iconSize} height={iconSize} />}
        tooltipText={'Whānau Cohesion'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
  {
    value: 'tab4',
    label: 'Participation in te Ao Maori - Tuakiritanga',
    html: (
      <>
        Tuakiritanga
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauTuakiritanga width={iconSize} height={iconSize} />}
        tooltipText={'Participation in te Ao Māori'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
  {
    value: 'tab5',
    label: 'Participation in Society - Paporitanga',
    html: (
      <>
        Paporitanga
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauPaporitanga width={iconSize} height={iconSize} />}
        tooltipText={'Participation in Society'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
  {
    value: 'tab6',
    label: 'Economic Security and Wealth Creation - Pukenga Rawa',
    html: (
      <>
        Pukenga Rawa
      </>
    ),
    icon: (
      <TooltipIconButton
        icon={<IconWhanauPukenga width={iconSize} height={iconSize} />}
        tooltipText={'Economic Security and Wealth Creation'}
        className={styles.icon}
        placement={'top'}
      />
    ),
  },
]
