import React from 'react'
import { CardIcon } from 'components/shared/ui/CardIcon'
import { getTrafficColorLowest, trafficLightStatusByName, TWhanau } from 'models/Whanau'

interface Props {
  className?: string
  whanau: TWhanau
}

export const TabContent6Card1 = ({ className, whanau }: Props) => (
  <CardIcon
    iconType={1}
    color={trafficLightStatusByName('Total Debt to Governmet Agencies', whanau)}
    title={'Economically Secure'}
    status={'Negative'}
    issues={[
      { title: 'Total debt and fines', color: getTrafficColorLowest(whanau, 'total debt to government agencies') },
      { title: '16-24 year old NEET', color: getTrafficColorLowest(whanau, '16-24 year old neet') },
    ]}
    className={className}
  />
)
