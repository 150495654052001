import React from 'react'
import { useOnMount } from 'base/hooks/useOnMount'
import { useQueryHashValue } from 'base/hooks/useQueryHashValue'
import { Meta } from 'components/shared/Meta'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageTabs } from 'base/components/ui/PageTabs'
import { ViewCohortKpiTabContent1 } from './viewCohortKpi/TabContent1'
import { layoutService } from 'services/LayoutService'

interface Props {
}

export const ViewCohortKpi = (props: Props) => {
  const [tab, setTab] = useQueryHashValue('tab', '1')

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Cohort KPI' }])
  })

  return (
    <LayoutApp>
      <Meta title="Cohort KPI" />
      <PageTabs
        initialTab={tab}
        tab={tab}
        items={[
          { value: '1', label: 'Overview', content: <ViewCohortKpiTabContent1 /> }
        ]}
        onTabChange={setTab}
      />
    </LayoutApp>
  )
}
