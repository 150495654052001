import * as React from 'react'
import { mem } from 'base/utils/mem'
import classNames from 'classnames'
import { TableRow } from '@material-ui/core'
import { Table, TColumn } from './Table'
import { Pagination } from './Pagination'
import styles from './Listing.module.scss'

interface Props {
  columns: TColumn[]
  items: TObjectAny[]
  page: number
  size: SizeOption
  total: number
  isLoading?: boolean
  onChange: (page: number, size: SizeOption) => void
  classes?: {
    table?: string
  }
}

export const Listing = mem(({ columns, items, page, size, total, isLoading, classes, onChange }: Props) => (
  <div className={styles.root}>
    <Table
      className={classNames(classes && classes.table, isLoading && styles.loading)}
      columns={columns}
      items={items}
      footer={
        <TableRow>
          <Pagination
            colSpan={columns.length}
            page={page}
            size={size}
            total={total}
            onChange={onChange}
          />
        </TableRow>
      }
    />
  </div>
))
