import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import IconArrow from '@material-ui/icons/ArrowForward'
import { TWhanau } from 'models/Whanau'
import { TMember, getLink } from 'models/Member'
import { useMultiFocus } from 'hooks/useMultiFocus'
import styles from './Item.module.scss'

interface Props {
  whanau: TWhanau
  member: TMember
}

export const CardMemberListItem = ({ whanau, member }: Props) => {
  const { isFocused, setFocus } = useMultiFocus('members')

  return (
    <Link
      to={getLink(whanau, member)}
      className={classNames(styles.root, isFocused(member.id) && styles.focus)}
      onMouseOver={() => setFocus(member.id, true)}
      onMouseOut={() => setFocus(member.id, false)}
    >
      {member.name} <IconArrow />
    </Link>
  )
}
