import React from 'react'
import classNames from 'classnames'
import { Paper } from '@material-ui/core'
import styles from './PageFrame.module.scss'

interface Props {
  simple?: boolean
  title?: string
  actions?: React.ReactNode
  children: React.ReactNode
  classes?: {
    root?: string
    paper?: string
  }
}

export const PageFrame = React.memo(({ simple, title, actions, children, classes }: Props) => {
  const paperClassName = classNames(styles.paper, classes && classes.paper)

  const renderInner = () => (
    <>
      {(title || actions) && (
        <div className={styles.head}>
          {title && <div className={styles.title}>{title}</div>}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      )}
      {children}
    </>
  )

  return (
    <div className={classNames(styles.root, classes && classes.root)}>
      {simple ? (
        <div className={paperClassName}>{children}</div>
      ) : (
        <Paper className={paperClassName}>{renderInner()}</Paper>
      )}
    </div>
  )
})
