import React from 'react'
import { mem } from 'base/utils/mem'
import { IconButton, TablePagination } from '@material-ui/core'
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import styles from './Pagination.module.scss'
import { useWindowSize } from '../../hooks/useWindowSize'

const DEFAULT_SIZE = 5

interface Props {
  colSpan: number
  total: number
  page: number
  size: number
  component?: any
  onChange: (page: number, size: SizeOption) => void
}

export const Pagination = mem(({ colSpan, total, page, size, component, onChange }: Props) => {
  const { width } = useWindowSize()
  let rowsPerPageOptions = [5, 10, 25]
  if (width < 480) {
    rowsPerPageOptions = []
  }

  const onChangePage = React.useCallback((e: any, page: number) => {
    onChange(page, size as SizeOption)
  }, [onChange, size])

  const onChangeRpp = React.useCallback((e: any) => {
    const size = (parseInt(e.target.value) as SizeOption) || DEFAULT_SIZE
    onChange(page, size)
  }, [onChange, page])

  return (
    <TablePagination
      component={component}
      className={styles.root}
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={colSpan}
      count={total}
      page={page}
      rowsPerPage={size}
      SelectProps={{ native: true }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRpp}
      ActionsComponent={Paginator}
    />
  )
})

export const Paginator = mem(({ page, rowsPerPage, count, onChangePage }: TablePaginationActionsProps) => {
  const handleFirst = (e: any) => onChangePage(e, 0)
  const handleBack = (e: any) => onChangePage(e, page - 1)
  const handleNext = (e: any) => onChangePage(e, page + 1)
  const handleLast = (e: any) => onChangePage(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))

  return (
    <div className={styles.paginator}>
      <IconButton onClick={handleFirst} disabled={page === 0} aria-label="First Page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBack} disabled={page === 0} aria-label="Previous Page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNext} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLast} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
        <LastPageIcon />
      </IconButton>
    </div>
  )
})
