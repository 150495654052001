import * as React from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { mem } from 'base/utils/mem'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import { Meta } from 'components/shared/Meta'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageFrame } from 'base/components/ui/PageFrame'
import { ActionButton } from 'base/components/ui/ActionButton'
import { Listing } from 'base/components/ui/Listing'
import { TColumn } from 'base/components/ui/Table'
import { ListingActions } from 'base/components/ui/ListingActions'
import { Avatar } from 'base/components/ui/Avatar'
import { layoutService } from 'services/LayoutService'
import { userService, confirmResetPassword, getName, TUserFilter } from 'services/UserService'
import { roleOptions, TModel as TUser } from 'models/User'
import { ListingFilter } from 'base/components/ui/ListingFilter'
import { useOnMount } from 'base/hooks/useOnMount'
import { useQueryHashValue } from 'base/hooks/useQueryHashValue'
import styles from './ViewUserList.module.scss'

export interface Props {
  items: TUser[]
  total: number
  isLoading: boolean
  filter: TUserFilter
  filterUpdate: (filter: TUserFilter) => void
}

const ViewUserList = mem((props: Props) => {
  const { items, total, isLoading, filter, filterUpdate } = props
  const [queryHashFilter, setQueryHashFilter] = useQueryHashValue('filter', filter)

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Users' }])
    if (!total) {
      filterUpdate(queryHashFilter)
    }
  })

  const onFilterUpdate = (filter: TUserFilter) => {
    setQueryHashFilter(filter)
    filterUpdate(filter)
  }

  const onListingChange = (page: number, size: SizeOption) => {
    onFilterUpdate({ ...filter, page, size })
  }

  return (
    <LayoutApp>
      <Meta title="Users" />
      <ListingFilter
        filter={queryHashFilter}
        scheme={[
          { type: 'search', name: 'user.email', label: 'Email', position: 'main' },
          { type: 'select', name: 'user.role', label: 'Role', position: 'main', options: roleOptions },
          { type: 'checkbox', name: 'user.suspended', label: 'Suspended', position: 'extra', default: false },
        ]}
        filterUpdate={onFilterUpdate}
      />

      <PageFrame title={'Manage Users'} actions={<ActionButton to={'/users/create'}>Create user</ActionButton>}>
        <Listing
          classes={{ table: styles.table }}
          columns={columns}
          items={items}
          page={queryHashFilter.page}
          size={queryHashFilter.size}
          total={total}
          isLoading={isLoading}
          onChange={onListingChange}
        />
      </PageFrame>
    </LayoutApp>
  )
})

const columns: TColumn[] = [
  {
    label: '',
    thProps: { className: styles.cellAvatar },
    hiddenProps: { smDown: true },
    render: (item: TUser) => <Avatar user={item} />,
  },
  {
    label: 'Name',
    tdProps: { className: styles.cellName },
    render: (item: TUser) => (
      <Link to={`/users/${item.id}/view`} className="link">
        {getName(item)}
      </Link>
    ),
  },
  {
    label: 'Role',
    hiddenProps: { xsDown: true },
    render: (item: TUser) => roleOptions.reduce((r, i) => (i.value === item.role ? i.label : r), ''),
  },
  {
    label: '',
    thProps: { className: styles.cellActions },
    render: (item: TUser) => (
      <ListingActions
        mainActions={[{ to: `/users/${item.id}/view`, icon: <EditIcon />, tooltipText: 'View/Edit User' }]}
        menuActions={[{ label: 'Reset Password', onClick: () => confirmResetPassword(item) }]}
      />
    ),
  },
]

const ViewUserListConnected = connect(
  (state: IState): Props => ({
    items: state.app.users.list.items,
    total: state.app.users.list.total,
    filter: state.app.users.list.filter,
    isLoading: state.app.users.isLoading,
    filterUpdate: userService.loadList,
  })
)(ViewUserList)

export { ViewUserListConnected as ViewUserList }
