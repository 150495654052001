import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconWahnauList = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 28 28" width={width} height={height} fill="currentColor">
    <path
      d="M19.2499 13.9999C19.6329 13.9999 20.0122 13.9245 20.3661 13.7779C20.7199 13.6313 21.0415 13.4165 21.3123 13.1456C21.5832 12.8748 21.798 12.5533 21.9446 12.1994C22.0911 11.8455 22.1666 11.4663 22.1666 11.0833C22.1666 10.3097 21.8593 9.56784 21.3123 9.02086C20.7653 8.47388 20.0235 8.16659 19.2499 8.16659C18.4764 8.16659 17.7345 8.47388 17.1875 9.02086C16.6405 9.56784 16.3333 10.3097 16.3333 11.0833C16.3333 11.4663 16.4087 11.8455 16.5553 12.1994C16.7018 12.5533 16.9167 12.8748 17.1875 13.1456C17.7345 13.6926 18.4764 13.9999 19.2499 13.9999ZM10.4999 12.8333C11.4282 12.8333 12.3184 12.4645 12.9748 11.8081C13.6312 11.1517 13.9999 10.2615 13.9999 9.33325C13.9999 8.40499 13.6312 7.51476 12.9748 6.85838C12.3184 6.202 11.4282 5.83325 10.4999 5.83325C9.57166 5.83325 8.68142 6.202 8.02505 6.85838C7.36867 7.51476 6.99992 8.40499 6.99992 9.33325C6.99992 10.2615 7.36867 11.1517 8.02505 11.8081C8.68142 12.4645 9.57166 12.8333 10.4999 12.8333ZM19.2499 16.3333C17.1149 16.3333 12.8333 17.4066 12.8333 19.5416V22.1666H25.6666V19.5416C25.6666 17.4066 21.3849 16.3333 19.2499 16.3333ZM10.4999 15.1666C7.78159 15.1666 2.33325 16.5316 2.33325 19.2499V22.1666H10.4999V19.5416C10.4999 18.5499 10.8849 16.8116 13.2649 15.4933C12.2499 15.2833 11.2699 15.1666 10.4999 15.1666Z"
      fill="#6F6F6F"
    />{' '}
  </svg>
))
