import React from 'react'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import { TEvent } from 'models/Event'
import { IconEventPhone } from 'components/shared/icons/IconEventPhone'
import { IconEventEmail } from 'components/shared/icons/IconEventEmail'
import { IconEventMeeting } from 'components/shared/icons/IconEventMeeting'
import ReactTooltip from 'react-tooltip'
import styles from './Item.module.scss'

interface Props {
  event: TEvent
  minDay: Dayjs
  maxDay: Dayjs
  position: 'top' | 'bottom'
  hint?: string
}

export const EventsItem = ({ event, minDay, maxDay, position, hint }: Props) => (
  <>
    <div
      className={classNames(styles.root, styles[position])}
      style={{ left: getLeftOffset(event, minDay, maxDay) }}
      data-tip={''}
      data-for={`event:${event.id}`}
    >
      {event.type === 'phone' && <IconEventPhone width={24} height={25} />}
      {event.type === 'email' && <IconEventEmail width={24} height={25} />}
      {event.type === 'inperson' && <IconEventMeeting width={24} height={25} />}
    </div>
    <ReactTooltip id={`event:${event.id}`} effect="solid">
      <span>{hint}</span>
    </ReactTooltip>
  </>
)

const getLeftOffset = (event: TEvent, minDay: Dayjs, maxDay: Dayjs): string => {
  return `${((event.ts - minDay.valueOf()) / (maxDay.valueOf() - minDay.valueOf())) * 100}%`
}
