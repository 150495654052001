import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { LineChart } from 'components/shared/ui/charts/LineChart'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchChart } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import { prepareChartData } from 'helpers/ChartDataHelper'

const fields = [
  { code: 'Changes in school', name: 'Changes in school', color: '#7D5285' },
]

export const TabContent4Card3 = () => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchChart('changes-in-schools')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card
      padding={24}
      title={'Total number of changes in schools for 5 - 18 year olds'}
    >
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
      <LineChart
        height={200}
        lines={fields.map(i => ({ name: i.name, color: i.color, field: i.code }))}
        data={prepareChartData(fields, data)}
      />
      </LoaderPane>
    </Card>
  )
}
