import React from 'react'
import { TWhanau } from 'models/Whanau'
import { Grid } from 'base/components/ui/Grid'
import { Heading3 } from 'components/shared/ui/Heading3'
import { TabContent5Card1 } from 'components/views/viewWhanauDetail/tabContent5/Card1'
import { TabContent5Card2 } from 'components/views/viewWhanauDetail/tabContent5/Card2'
import { TabContent5Table1 } from 'components/views/viewWhanauDetail/tabContent5/Table1'
import { TabContent5Table2 } from 'components/views/viewWhanauDetail/tabContent5/Table2'

interface Props {
  whanau: TWhanau
}

export const ViewWhanauDetailTabContent5 = ({ whanau }: Props) => (
  <div>
    <Grid container mb={16}>
      <Heading3>Participating in Society</Heading3>
    </Grid>

    <Grid container spacing={16} mb={8}>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent5Card1 whanau={whanau} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TabContent5Card2 whanau={whanau} />
      </Grid>
    </Grid>

    <Grid container spacing={16}>
      <Grid item sm={12} md={6}>
        <TabContent5Table1 whanau={whanau} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TabContent5Table2 whanau={whanau} />
      </Grid>
    </Grid>
  </div>
)
