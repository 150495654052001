import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { LineChart } from 'components/shared/ui/charts/LineChart'
import { prepareChartData } from 'helpers/ChartDataHelper'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchChart } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

const fields = [
  { code: 'Goals set', name: 'Goals set', color: '#26C6DA' },
  { code: 'Foals completed', name: 'Goals completed in that month', color: '#77C288' },
  { code: 'Navigator contacts', name: 'Navigator contacts', color: '#0A867E' },
]

export const TabContent2Chart = () => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchChart('goal-setting-completion')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })
  return (
    <Card padding={ 24 } title={ 'Goal setting and completion over time (Apr 16 - Jul 18)' }>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <LineChart
          withLegend
          height={ 400 }
          lines={ fields.map(i => ({ name: i.name, color: i.color, field: i.code })) }
          data={ prepareChartData(fields, data) }
        />
      </LoaderPane>
    </Card>
  )
}
