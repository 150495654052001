import React from 'react'
import styles from 'components/shared/ui/Heading2.module.scss'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className?: string
}

export const Heading2 = React.memo(({ children, className }: Props) => (
  <div className={classNames(styles.root, className)}>{children}</div>
))
