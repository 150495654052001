import React from 'react'
import { Dialog } from '@material-ui/core'
import styles from './ContentModal.module.scss'

interface Props {
  content: React.ReactNode | string
  open: boolean
  close: () => void
}

export const ContentModal = ({ content, close, open }: Props) => (
  <Dialog className={styles.root} classes={{ paper: styles.paper }} open={open} onClose={close}>
    <div>
      {content}
    </div>
  </Dialog>
)
