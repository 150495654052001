import React, { useState } from 'react'
import classNames from 'classnames'
import { Card } from 'base/components/ui/Card'
import { BarChart } from 'components/shared/ui/charts/BarChart'
import styles from './Chart.module.scss'
import { useOnMount } from 'base/hooks/useOnMount'
import { TWhanau } from 'models/Whanau'
import { IChartData } from 'store/state'
import dayjs from 'dayjs'
import { Loader } from 'base/components/ui/Loader'
import { fetchChart } from 'api/KWOApi'

interface Props {
  className?: string
  whanau: TWhanau
}

const tempMeasure1 = 'ED Presentations'
const tempMeasure2 = 'IP Admissions'
export const TabContent2Chart = ({ className, whanau }: Props) => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async (id: string) => {
      const result = await fetchChart('ed-presentations-and-ip-admissions', id)
      setData(result)
      setIsLoaded(true)
    }

    fetch(whanau.id).catch(window.logger.error)
  })
  return (
    <Card className={classNames(styles.root, className)} title={'ED Presentations and Hospital Admissions'}>
      {!isLoaded ? (
        <Loader absolute />
      ) : data.length > 0 ? (
        <BarChart
          stack
          withLegend
          height={400}
          bars={[
            { name: tempMeasure1, color: '#0379A0', field: tempMeasure1 },
            { name: tempMeasure2, color: '#26C6DA', field: tempMeasure2 },
          ]}
          data={prepareData(data)}
        />
      ) : (
        <div className={styles.noData}>No Data</div>
        )}
    </Card>
  )
}

const prepareData = (data: IChartData[]) => {
  const result: { timestamp: number; name: string; axisLabel: string[]; [key: string]: any }[] = []
  if (data) {
    data.forEach(i => {
      const date = dayjs(i.time)
      const name = date.format('MMMM')
      const year = date.format('YYYY')
      let item = result.find(r => r.timestamp === i.time)
      if (!item) {
        item = {
          name,
          timestamp: i.time,
          axisLabel: [name, year],
        }
        result.push(item)
      }
      if (i.measure.toLowerCase() === tempMeasure1.toLowerCase()) {
        item[tempMeasure1] = i.num_val
      }

      if (i.measure.toLowerCase() === tempMeasure2.toLowerCase()) {
        item[tempMeasure2] = i.num_val
      }
    })
  }
  return result
}
