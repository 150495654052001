import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './HorizontalBarItem.module.scss'
import useTimeout from 'base/hooks/useTimeout'

interface Props {
  title: string
  titleWidth: number | string
  value: number
  max: number
  color?: string
  areaColor?: string
  renderValue?: (value: number) => React.ReactNode
  valuePosition?: 'inside' | 'outside'
  titlePosition?: 'left' | 'top'
  className?: string
  classes?: {
    root?: string
    block?: string
    title?: string
    area?: string
    bar?: string
    value?: string
  }
}

export const HorizontalBarItem = (props: Props) => {
  const [readyToAnimateBar, setReadyToAnimateBar] = useState(false)
  const { title, titleWidth, value, max, color, areaColor, className } = props
  const { renderValue = (v: number) => v } = props
  const { valuePosition = 'inside' } = props
  const { titlePosition = 'left' } = props
  const classes = props.classes || {}

  useTimeout(() => setReadyToAnimateBar(true))

  const renderTitle = (titleClassName?: string) => (
    <div className={classNames(styles.title, titleClassName, classes.title)} style={{ width: titleWidth }}>{title}</div>
  )

  const renderValueNode = () => (
    <div className={classNames(styles.value, classes.value)}>{renderValue(value)}</div>
  )

  return (
    <div className={classNames(styles.root, classes.root, className)}>
      {titlePosition === 'top' && renderTitle(styles.titleTop)}

      <div className={classNames(styles.block, classes.block)}>
        {titlePosition === 'left' && renderTitle()}

        <div className={classNames(styles.area, classes.area)} style={{ backgroundColor: areaColor }}>
          <div
            className={classNames(styles.bar, classes.bar)}
            style={{ width: readyToAnimateBar ? `${value / max * 100}%` : 0, backgroundColor: color }}
          />
          {valuePosition === 'inside' && renderValueNode()}
        </div>

        {valuePosition === 'outside' && renderValueNode()}
      </div>
    </div>
  )
}
