import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconCohortStatus = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 30 18" width={width} height={height} fill="currentColor">
    <path
      d="M14.9999 0C13.9391 0 12.9216 0.421427 12.1715 1.17157C11.4213 1.92172 10.9999 2.93913 10.9999 4C10.9999 5.06087 11.4213 6.07828 12.1715 6.82843C12.9216 7.57857 13.9391 8 14.9999 8C16.0608 8 17.0782 7.57857 17.8283 6.82843C18.5785 6.07828 18.9999 5.06087 18.9999 4C18.9999 2.93913 18.5785 1.92172 17.8283 1.17157C17.0782 0.421427 16.0608 0 14.9999 0ZM6.99992 2.89333C6.11586 2.89333 5.26802 3.24452 4.6429 3.86964C4.01777 4.49477 3.66659 5.34261 3.66658 6.22667C3.66659 6.66441 3.7528 7.09786 3.92032 7.50228C4.08784 7.9067 4.33337 8.27416 4.6429 8.58369C4.95242 8.89322 5.31989 9.13875 5.72431 9.30627C6.12873 9.47378 6.56218 9.56 6.99992 9.56C8.17325 9.56 9.19992 8.94667 9.78658 8.04C8.89325 6.90667 8.33325 5.53333 8.33325 4C8.33325 3.73333 8.33325 3.46667 8.38658 3.2C7.95992 3 7.49325 2.89333 6.99992 2.89333ZM22.9999 2.89333C22.5066 2.89333 22.0399 3 21.6132 3.2C21.6666 3.46667 21.6666 3.73333 21.6666 4C21.6666 5.53333 21.1066 6.90667 20.2133 8.04C20.7999 8.94667 21.8266 9.56 22.9999 9.56C23.4377 9.56 23.8711 9.47378 24.2755 9.30627C24.6799 9.13875 25.0474 8.89322 25.3569 8.58369C25.6665 8.27416 25.912 7.9067 26.0795 7.50228C26.247 7.09786 26.3333 6.66441 26.3333 6.22667C26.3333 5.78893 26.247 5.35547 26.0795 4.95106C25.912 4.54664 25.6665 4.17917 25.3569 3.86964C25.0474 3.56012 24.6799 3.31458 24.2755 3.14707C23.8711 2.97955 23.4377 2.89333 22.9999 2.89333ZM14.9999 10.6667C12.3333 10.6667 6.99992 12 6.99992 14.6667V17.3333H22.9999V14.6667C22.9999 12 17.6666 10.6667 14.9999 10.6667ZM5.22658 11.96C2.99992 12.3467 0.333252 13.3867 0.333252 15.1067V17.3333H4.33325V14.6667C4.33325 13.6267 4.71992 12.7067 5.22658 11.96ZM24.7733 11.96C25.2799 12.7067 25.6666 13.6267 25.6666 14.6667V17.3333H29.6666V15.1067C29.6666 13.3867 26.9999 12.3467 24.7733 11.96Z"
      fill="#6F6F6F"
    />
  </svg>
))
