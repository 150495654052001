import * as React from 'react'
import { History } from 'history'
import { RouteComponentProps, withRouter } from 'react-router'
import Paper from '@material-ui/core/Paper'
import { Meta } from 'components/shared/Meta'
import { LayoutSimple } from 'components/shared/layouts/LayoutSimple'
import { ForgotPasswordForm } from './viewForgotPassword/Form'
import { ForgotPasswordSent } from './viewForgotPassword/Sent'
import styles from './Common.module.scss'
import { authService } from 'services/AuthService'

export interface Props extends RouteComponentProps {
  history: History
}

interface State {
  email: string
  hasError: boolean
  sent: boolean
}

class ViewForgotPassword extends React.Component<Props, State> {
  public state: State = {
    email: '',
    hasError: false,
    sent: false,
  }

  public onSubmit = async (email: string) => {
    const sent = await authService.sendPasswordReset(email)
    this.setState({ sent, email, hasError: !sent })
  }

  public render() {
    const { hasError, sent, email } = this.state

    return (
      <LayoutSimple>
        <Meta title="Forgot password" />
        <div className={styles.root}>
          <Paper className={styles.paper}>
            {sent ? (
              <ForgotPasswordSent email={email} />
            ) : (
              <ForgotPasswordForm hasError={hasError} onSubmit={this.onSubmit} />
            )}
          </Paper>
        </div>
      </LayoutSimple>
    )
  }
}

export const ViewForgotPasswordContainer = withRouter(ViewForgotPassword)
