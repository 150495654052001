import * as React from 'react'
import { History } from 'history'
import { RouteComponentProps, withRouter } from 'react-router'
import Paper from '@material-ui/core/Paper'
import { Meta } from 'components/shared/Meta'
import { LayoutSimple } from 'components/shared/layouts/LayoutSimple'
import styles from './Common.module.scss'
import { authService } from 'services/AuthService'
import { ChangePasswordForm } from './viewChangePassword/Form'
import posed from 'react-pose'

export interface Props extends RouteComponentProps<{ resetToken: string }> {
  history: History
  email: string
}

interface State {
  hasError: boolean
  email: string
  token: string
  sent: boolean
  next: boolean | Function
}

class ViewChangePassword extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)
    const query = new URLSearchParams(this.props.location.search)

    this.state = {
      hasError: false,
      email: '',
      sent: false,
      token: query.get('resetToken') || '',
      next: false,
    }
  }

  public componentDidMount(): void {
    if (!this.state.token) {
      this.props.history.push('/forgotPassword')
    }
  }

  public onSubmit = async (email: string, password: string) => {
    let next: boolean | Function = false
    const sent = await authService.sendPasswordChange(email, password, this.state.token)
    if (sent) {
      next = await authService.login(email, password)
    }

    this.setState({ next, email, hasError: !sent })
  }

  public onAnimationComplete = (pose: any) => {
    const location = this.props.history.location
    if (pose === 'finish' && typeof this.state.next === 'function') {
      this.state.next()
      this.props.history.push((location.state && location.state.from) || '/')
    }
  }

  public render() {
    const { hasError, next } = this.state

    return (
      <LayoutSimple>
        <Meta title="Change password" />
        <Animated
          className={styles.root}
          initialPose="start"
          pose={next ? 'finish' : 'active'}
          onPoseComplete={this.onAnimationComplete}
        >
          <div className={styles.root}>
            <Paper className={styles.paper}>
              <ChangePasswordForm hasError={hasError} onSubmit={this.onSubmit} />
            </Paper>
          </div>
        </Animated>
      </LayoutSimple>
    )
  }
}

const Animated = posed.div({
  start: { x: '-50%', y: '-60%', opacity: 0 },
  active: { x: '-50%', y: '-50%', opacity: 1, transition: { duration: 300 } },
  finish: { x: '-50%', y: '-60%', opacity: 0, transition: { duration: 300 } },
})

export const ViewChangePasswordContainer = withRouter(ViewChangePassword)
