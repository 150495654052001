import { createMuiTheme } from '@material-ui/core/styles'

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Lato", sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
  },
  palette: {
    primary: {
      main: '#4527a0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4fcfdf',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '12px 56px 12px 24px',
      },
      body: {
        fontSize: '0.9rem',
      },
    },
  },
})

export { muiTheme }
