import React from 'react'
import classNames from 'classnames'
import { Card } from 'base/components/ui/Card'
import styles from './Card3.module.scss'
import { TMember } from 'models/Member'

interface Props {
  className?: string
  member: TMember
}

export const ViewMemberCard3 = ({ className, member }: Props) => (
  <Card className={classNames(styles.root, className)}>
    <div className={styles.title}>Leading healthy Lifestyles</div>
    <table className={styles.table}>
      <tbody>
      <tr>
        <td>Enrolled GP Practice</td>
        <td>{member.enrolled}</td>
      </tr>
      </tbody>
    </table>
    {member.isAdult ? renderAdult(member) : renderChild(member) }
  </Card>
)

const renderAdult = (member: TMember) => {
  return (
    <>
      <div className={classNames(styles.title, styles.mt)}>Economically Secure</div>
      <table className={styles.table}>
        <tbody>
        <tr>
          <td>16-24 year old NEET</td>
          <td>{member.neet || 'N/A'}</td>
        </tr>
        <tr>
          <td>
            <div className={styles.block}>Benefit</div>
            <div className={styles.block}>Work obligation</div>
            <div className={styles.block}>Training Referral</div>
          </td>
          <td>
            <div className={styles.block}>{member.benefit}</div>
            <div className={styles.block}>{member.workObligation}</div>
            <div className={styles.block}>{member.trainingReferral}</div>
          </td>
        </tr>
        <tr>
          <td>Employment</td>
          <td>{member.employment}</td>
        </tr>
        <tr>
          <td>Total debt</td>
          <td>{member.totalDebt}</td>
        </tr>
        </tbody>
      </table>
    </>
  )
}

const renderChild = (member: TMember) => {
  return (
    <>
      <div className={classNames(styles.title, styles.mt)}>Participating In Society</div>
      <table className={styles.table}>
        <tbody>
        <tr>
          <td>Current schools</td>
          <td>{member.currentSchools}</td>
        </tr>
        </tbody>
      </table>
    </>
  )
}
