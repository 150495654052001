import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { IState } from 'store'
import { Icon, IconButton, Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styles from './Snackbar.module.scss'
import { layoutService } from 'services/LayoutService'

interface Props {
  open: boolean
  variant: string
  message: string
  close: () => void
}

const Snackbar = ({ open, variant, message, close }: Props) => {
  const handleClose = (e: any, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  return (
    <MuiSnackbar
      className={styles.root}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={styles[variant]}
        message={
          <span className={styles.message}>
            <Icon className={classNames(styles.icon, styles.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={styles.close} onClick={handleClose}>
            <CloseIcon className={styles.icon} />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  )
}

const SnackbarConnected = connect(
  (state: IState): Props => ({
    open: state.app.layout.snackbar.open,
    variant: state.app.layout.snackbar.variant,
    message: state.app.layout.snackbar.message,
    close: layoutService.closeSnackbar,
  })
)(Snackbar)

export { SnackbarConnected as Snackbar }
