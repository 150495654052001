import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { Block } from 'base/components/ui/Block'
import { Text } from 'base/components/ui/Text'
import { IKWOData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchReport } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

export const TabContent7Card1 = () => {
  const [data, setData] = useState<IKWOData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReport('connection-with-services')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card title={'Connection with services'} padding={24}>
      <LoaderPane
        isLoaded={isLoaded}
        isData={data.length > 0}
        noDataMessage={'Data to be collected through whānau fast feedback survey'}
      >
        <Block flex justifySpace alignEnd maxWidth={340}>
          <Block>
            <Text bold>Connection with services:</Text>
          </Block>
          <Block width={60} ml={10}>
            Example
          </Block>
        </Block>

        <Block flex justifySpace alignEnd maxWidth={340} mt={8}>
          <Block>
            <Text bold>Primary mental health services:</Text>
          </Block>
          <Block width={60} ml={10}>
            Example
          </Block>
        </Block>

        <Text block bold mt={32}>
          Family Stability
        </Text>

        <Block flex justifySpace alignEnd maxWidth={340} mt={8}>
          <Block>
            <Text bold>Family harm events</Text>
          </Block>
          <Block width={60} ml={10}>
            2
          </Block>
        </Block>

        <Block flex justifySpace alignEnd maxWidth={340} mt={8}>
          <Block>
            <Text bold>Current Care Protection</Text>
          </Block>
          <Block width={60} ml={10}>
            Yes
          </Block>
        </Block>
      </LoaderPane>
    </Card>
  )
}
