import React from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent7Card1 } from './tabContent7/Card1'
import { TabContent7Card2 } from './tabContent7/Card2'

export const ViewCohortStatusTabContent7 = () => (
  <div>
    <PageFrame simple>
      <Grid container spacing={16}>
        <Grid item xs={12} lg={6}>
          <TabContent7Card1 />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContent7Card2 />
        </Grid>
      </Grid>
    </PageFrame>
  </div>
)
