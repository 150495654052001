import React from 'react'
import { FormikProps } from 'formik'
import { Loader } from 'base/components/ui/Loader'
import styles from './FormFrame.module.scss'

interface Props {
  formProps: FormikProps<any>
  isLoading: boolean
  children: any
}

export const FormPropsContext = React.createContext<FormikProps<any>>(null as any)

export const FormFrame = ({ formProps, isLoading, children }: Props) => {
  return (
    <FormPropsContext.Provider value={formProps}>
      {isLoading ? (
        <div className={styles.loading}>
          <Loader absolute />
        </div>
      ) : (
        <form onSubmit={formProps.handleSubmit} noValidate className={styles.root}>
          {children}
        </form>
      )}
    </FormPropsContext.Provider>
  )
}
