import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconMan = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 14 39" width={width} height={height} fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99992 0C9.07889 0 10.9374 1.25593 10.9374 4.0429C10.9374 6.10667 10.4714 8.05543 8.983 8.76023L11.6247 9.95635C12.8112 10.3851 13.943 11.0246 13.943 12.3366L13.6741 21.0196C13.6741 22.3325 12.6337 23.3999 11.3559 23.3999H10.6551L10.1175 34.7916C9.77875 36.5968 9.02602 37.9999 7.88886 37.9999H7.54302L7.7847 13.8633C10.2647 14.2937 11.1099 15.3863 11.3613 16.1847C11.6439 17.0821 11.2621 18.1105 10.5649 18.3153C9.7677 18.5496 9.18407 17.6896 9.46075 17.5698C9.6752 18.1157 10.5397 17.982 10.5128 17.1845C10.4943 16.6082 9.75929 16.1396 9.15295 16.3722C8.69631 16.5466 8.38095 17.1906 8.47598 17.8137C8.60296 18.6468 9.58858 19.6822 10.9005 19.3081C12.2116 18.9341 12.9365 17.2982 12.3495 15.4358C11.7734 13.6046 10.3261 12.3819 7.03792 12.0095L7.0354 12C7.02362 12.0017 7.01269 12.0035 7.00008 12.0043C6.98874 12.0036 6.97877 12.0021 6.96832 12.0005L6.96476 12L6.96223 12.0095C3.67405 12.3819 2.22674 13.6046 1.64984 15.4358C1.06368 17.2982 1.7886 18.9341 3.09967 19.3081C4.41158 19.6822 5.39719 18.6468 5.52418 17.8137C5.61921 17.1906 5.30384 16.5466 4.8472 16.3722C4.24086 16.1396 3.50585 16.6082 3.48651 17.1845C3.46044 17.982 4.32496 18.1157 4.5394 17.5698C4.81608 17.6896 4.23245 18.5496 3.43521 18.3153C2.73805 18.1105 2.35625 17.0821 2.63882 16.1847C2.89026 15.3863 3.73544 14.2937 6.21545 13.8633V38L6.11098 38C4.97382 38 4.22109 36.5969 3.88236 34.7916L3.3447 23.3999H2.64394C1.3661 23.3999 0.325717 22.3326 0.325717 21.0196L0.0568848 12.3367C0.0568848 11.0246 1.18867 10.3852 2.37511 9.95642L5.01684 8.76029C3.5284 8.0555 3.06243 6.10674 3.06243 4.04297C3.06243 1.256 4.92095 6.94426e-05 6.99992 6.94426e-05V0ZM6.99992 38L7.54302 38L7.54302 37.9999H6.99992V38Z"
    />
  </svg>
))
