import { IKWOData } from 'store/state'
import { getCurrencySymbol } from '../helpers/MoneyHelper'
import pluralize from 'pluralize'
import { TColor } from '../components/shared/ui/CardIcon'
import { TMember, create as createMember } from './Member'

interface ITrafficLight {
  name: string
  value: number
  flag: string
}

export interface IPeople {
  age: number,
  kwoId: string
}

const initialData = {
  id: '',
  name: '',
  time: '',
  num: 0,
  status: 'Active',
  trafficLights: [] as ITrafficLight[],
  lat: 0,
  lng: 0,
  startDate: -1,
  kwo: [] as IKWOData[],
  peoples: [] as IPeople[],
}

export type TWhanau = typeof initialData

export const create = (data?: Partial<TWhanau>): TWhanau => ({ ...initialData, ...data })

export const map = (data: TObjectAny): TWhanau => {
  const peoples: IPeople[] = []
  for (const key in data.people) {
    const peopele = data.people[key]
    peoples.push({ age: peopele.Age, kwoId: key })
  }

  return {
    peoples,
    id: data.id || initialData.id,
    name: data.id || initialData.name,
    num: data.number_of_people || initialData.num,
    status: data.status || initialData.status,
    trafficLights: data.traffic_lights || initialData.trafficLights,
    time: timeInProgramToString(data.time_in_programme) || initialData.time,
    lat: data.lat_lng[0] || initialData.lat,
    lng: data.lat_lng[1] || initialData.lng,
    startDate: data.programme_entry_date || initialData.startDate,
    kwo: [],
  }
}

export const getLink = (item: TWhanau) => `/whanau/${item.id}`
export const getLinkTab = (item: TWhanau, tab: string) => `/whanau/${item.id}/#{"tab":"${tab}"}`

export const getComfortWithCulture = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'comfort with culture') || 'None'
}

export const getKnowledgeOfIwiAndHapu = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'knowledge of iwi and hapu') || 'None'
}

export const getLearningTeReo = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'learning te reo') || 'None'
}

export const getKohangaParticipation = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'kohanga participation') || 'None'
}

export const getChildInfections = (item: TWhanau) => {
  return getTotal(item.kwo, 'child infections') || 'None'
}

export const getHeatingSource = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'heating source') || '-'
}

export const getFamilyHarmEvents = (item: TWhanau) => {
  return getTotal(item.kwo, 'family harm events')
}

export const getHowTheWhanauAreDoing = (item: TWhanau) => {
  return getTotal(item.kwo, 'how the whanau are doing')
}

export const getBetterWorseOrSame = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'better, worse or same') || '-'
}

export const getSupportInTimeOfCrisis = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'support in time of crisis') || '-'
}

export const getEmergencyPlan = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'emergency plan') || '-'
}

export const getNaturalDisasterPlan = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'natural disaster plan') || '-'
}

export const getNEET = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, '16-24 year old neet') || '-'
}
export const getTotalDebtToGovernmentAgencies = (item: TWhanau) => {
  return getMoneyTotal(item.kwo, 'total debt to government agencies')
}

export const getWorkObligation = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'work obligation', 'No obligation') || '-'
}

export const getTrainingReferral = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'training referral', 'No training referral') || '-'
}

export const getMSDFoodGrantsReceived = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'msd food grants received') || '-'
}

export const getBenefitType = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'benefit type') || '-'
}

export const getCurrentCareProtection = (item: TWhanau) => {
  return getStringList(item.kwo, 'current care protection')
}

export const getBenefitDuration = (item: TWhanau) => {
  const result = getFirst(item.kwo, 'benefit duration')
  if (result) {
    return `${result.num_val} ${result.units}`
  }
  return ''
}

export const getEnrolledInGPTotalCount = (item: TWhanau) => {
  return getCount(item.kwo, 'enrolled at gp')
}

export const getEnrolledInGPCount = (item: TWhanau) => {
  const result = getStringList(item.kwo, 'enrolled at gp')
  return result.filter(i => i.toString().toLowerCase() !== 'none').length
}

export const getChangesInSchool = (item: TWhanau) => {
  return getTotal(item.kwo, 'changes in school')
}

export const getSchoolEnrollment = (item: TWhanau) => {
  return getTotal(item.kwo, 'school enrollment')
}

export const getCurrentSchools = (item: TWhanau) => {
  return getStringList(item.kwo, 'current schools')
}

export const getLearningSupport = (item: TWhanau) => {
  return getTotal(item.kwo, 'learning support')
}

export const getEmployment = (item: TWhanau) => {
  return getFirstStringValue(item.kwo, 'employment') || '-'
}

export const getLengthOfTenancy = (item: TWhanau) => {
  const kwo = getFirst(item.kwo, 'length of tenancy')
  return `${getTotal(item.kwo, 'length of tenancy')} ${kwo ? kwo.units : 's'}`
}

export const getTrafficLight = (data: IKWOData[], measure: string): TColor => {
  const result = getFirst(data, measure)
  return (result && result.traffic_light ? result.traffic_light : 'none') as TColor
}

export const getEDPresentations = (item: TWhanau) => {
  return getTotal(item.kwo, 'ed presentations')
}

export const getKWOMembers = (item: TWhanau) => {
  const map = new Map<string, IKWOData[]>()
  item.kwo.forEach(i => {
    if (i.kwo_id !== 'N/A' && i.kwo_id !== 'ALL') {
      if (!map.has(i.kwo_id)) {
        map.set(i.kwo_id, [])
      }

      const member = map.get(i.kwo_id)
      if (member) {
        member.push(i)
      }
    }
  })

  const result: TMember[] = []
  for (const [key, value] of map) {
    result.push(createMember({ id: key, name: key }, value))
  }
  return result
}

export const getKWOMember = (item: TWhanau, kwoId: string) => {
  const members = getKWOMembers(item)
  return members.find(i => i.id === kwoId)
}

const getFirstStringValue = (data: IKWOData[], measure: string, byDefault = '') => {
  const result = getFirst(data, measure)
  if (result) {
    return result.str_val ? result.str_val : byDefault
  }
  return byDefault
}

const getFirst = (data: IKWOData[], measure: string) => {
  const items = data.filter(i => i.measure.toLowerCase() === measure)
  if (items) {
    return items[0]
  }

  return
}

const getStringList = (data: IKWOData[], measure: string) => {
  const result = data
    .map(i => {
      if (i.measure.toLowerCase() === measure) {
        return i.str_val
      }
      return null
    })
    .filter(i => i !== null) as any[]

  return result || []
}

const getTotal = (data: IKWOData[], measure: string) => {
  return data.reduce((result, i) => {
    if (i.measure.toLowerCase() === measure) {
      return result + i.num_val
    }
    return result
  }, 0)
}

const getCount = (data: IKWOData[], measure: string) => {
  return data.reduce((result, i) => {
    if (i.measure.toLowerCase() === measure) {
      return result + 1
    }
    return result
  }, 0)
}

const getMoneyTotal = (data: IKWOData[], measure: string) => {
  let currency = ''

  const result = data.reduce((result, i) => {
    if (i.measure.toLowerCase() === measure) {
      currency = i.units
      return result + i.num_val
    }
    return result
  }, 0)
  return `${getCurrencySymbol(currency)} ${result}`
}

const timeInProgramToString = (value: number[]) => {
  let result = ''
  if (value[0]) {
    result = `${pluralize('year', value[0], true)}`
  }

  if (value[1] || result) {
    result = `${result} ${pluralize('month', value[1], true)}`
  }

  if (value[2] || result) {
    result = `${result} ${pluralize('day', value[2], true)}`
  }

  return result
}

export const getTrafficColorLowest = (item: TWhanau, measure: string) => {
  let result: TColor = 'none'
  const items = item.kwo.filter(i => i.measure.toLowerCase() === measure)
  if (items) {
    for (const item of items) {
      if (item.traffic_light === 'redAlert') {
        result = 'redAlert'
        break
      }

      if (item.traffic_light === 'concerning') {
        result = 'concerning'
      }

      if (item.traffic_light === 'good' && result !== 'concerning') {
        result = 'positive'
      }
    }
  }

  return result
}

export const trafficLightStatusByName = (name: string, item: TWhanau): TColor => {
  const trafficLight = trafficLightByName(name, item)
  let result: TColor = 'none'
  if (trafficLight) {
    if (trafficLight.flag === 'ok') {
      result = 'concerning'
    }
    if (trafficLight.flag === 'bad') {
      result = 'redAlert'
    }
    if (trafficLight.flag === 'good') {
      result = 'positive'
    }
  }

  return result
}

export const trafficLights = (item: TWhanau) => {
  return [
    {
      code: 'ed presentations',
      name: 'Self managing',
      colorStatus: trafficLightStatusByName('ed presentations', item),
    },
    {
      code: 'enrolled at gp',
      name: 'Healthy lifestyle',
      colorStatus: trafficLightStatusByName('enrolled at gp', item),
    },
    {
      code: 'family harm events',
      name: 'Whanau Cohesion',
      colorStatus: trafficLightStatusByName('family harm events', item),
    },
    { code: 'moemoea', name: 'Participation in Te Ao Māori', colorStatus: trafficLightStatusByName('moemoea', item) },
    {
      code: 'changes in school',
      name: 'Participation in society',
      colorStatus: trafficLightStatusByName('changes in school', item),
    },
    {
      code: 'total debt to government agencies',
      name: 'Economically secure',
      colorStatus: trafficLightStatusByName('total debt to government agencies', item),
    },
  ] as { code: string; name: string; colorStatus?: 'redAlert' | 'concerning' | 'positive' }[]
}

export const trafficLightMap = (item: TWhanau) => {
  return trafficLightStatusByName('final_traffic_light', item)
}

export const trafficLightByName = (name: string, item: TWhanau) => {
  return item.trafficLights.find(i => i.name.toLowerCase() === name.toLowerCase())
}
