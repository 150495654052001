import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { RouteComponentProps, withRouter } from 'react-router'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { Meta } from 'components/shared/Meta'
import { PageTabs } from 'base/components/ui/PageTabs'
import { PageFrame } from 'base/components/ui/PageFrame'
import { layoutService } from 'services/LayoutService'
import { ViewWhanauListTable } from './viewWhanauList/Table'
import { WhanauMap } from 'components/shared/ui/Map'
import { TWhanau } from 'models/Whanau'
import { whanauService, TWhanauFilter } from 'services/WhanauService'
import { ListingFilter } from 'base/components/ui/ListingFilter'
import { useOnMount } from 'base/hooks/useOnMount'
import { useWindowSize } from 'base/hooks/useWindowSize'
import { useQueryHashValue } from 'base/hooks/useQueryHashValue'
import { ViewWhanauListCards } from './viewWhanauList/Cards'
import styles from './ViewWhanauList.module.scss'
import { IconInfo } from '../shared/icons/IconInfo'
import { ContentModal } from '../shared/ui/ContentModal'
import { ViewWhanauListContent } from './viewWhanauList/Content'
import { fetchNavigatorList } from 'api/WhanauApi'
import { Loader } from '../../base/components/ui/Loader'

export interface Props {
  items: TWhanau[]
  mapItems: TWhanau[]
  total: number
  isLoaded: boolean
  filter: TWhanauFilter
  filterUpdate: (filter: TWhanauFilter) => void
  filterMapUpdate: (filter: TWhanauFilter) => void
}

const numberOfPeople = [{ value: '1-3', label: '1-3' }, { value: '3-6', label: '3-6' }, { value: '6+', label: '6+' }]
const programmeEntryDate = [
  { value: '3-6', label: '3-6 months ago' },
  { value: '6-12', label: '6-12 months ago' },
  { value: '12-14', label: '2-24 months ago' },
  { value: '24-100 ', label: '24+ months ago' },
]

const ViewWhanauList = React.memo((props: Props & RouteComponentProps) => {
  const { width } = useWindowSize()
  const isDesktop = width > 960
  const { mapItems, items, total, isLoaded, filter, filterUpdate, filterMapUpdate } = props
  const [queryHashFilter, setQueryHashFilter] = useQueryHashValue('filter', filter)
  const [queryHashTab, setQueryHashTab] = useQueryHashValue('tab', 'list')
  const [openHint, setOpenHint] = useState<boolean>(false)
  const [navigatorList, setNavigatorList] = useState<IOption<string>[]>([])

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchNavigatorList()
      setNavigatorList(data.map(i => ({ value: i.substr(0, 2).toLowerCase(), label: i })))
    }
    fetch().catch(window.logger.error)
    layoutService.setHeaderLarge(false)
    layoutService.setBreadcrumbs([{ label: 'Whānau and individual status' }])
    if (!total) {
      filterUpdate(queryHashFilter)
      filterMapUpdate(queryHashFilter)
    }
  })

  const onFilterUpdate = (filter: TWhanauFilter) => {
    setQueryHashFilter(filter)
    filterUpdate(filter)
    filterMapUpdate(filter)
  }

  const onListingChange = (page: number, size: SizeOption) => {
    onFilterUpdate({ ...filter, page, size })
  }

  const statusOptions = items.reduce(
    (r, i) => {
      const index = r.findIndex(j => j.value === i.status)
      if (index === -1) {
        r.push({ value: i.status, label: i.status })
      }
      return r
    },
    [] as { value: string; label: string }[]
  )

  const renderFilter = () => (
    <ListingFilter
      staticPosition
      filter={filter}
      scheme={[
        { type: 'search', name: 'field1', label: 'Whanau ID', position: 'main' },
        { type: 'select', name: 'field3', label: 'Navigator', position: 'main', options: navigatorList },
        {
          type: 'select',
          name: 'field4',
          label: 'Number of people',
          position: 'main',
          options: numberOfPeople,
        },
        { type: 'select', name: 'field5', label: 'Status', position: 'extra', options: statusOptions },
        {
          type: 'multiselect',
          name: 'field2',
          label: 'Programme entry date',
          position: 'extra',
          options: programmeEntryDate,
        },
        // { type: 'checkbox', name: 'field6', label: 'Checkbox', position: 'extra' },
      ]}
      filterUpdate={onFilterUpdate}
    />
  )

  const renderList = () => (
    <div>
      {renderFilter()}
      {isDesktop ? (
        <PageFrame classes={{ root: styles.root, paper: styles.paper }}>
          <div className={styles.hint} onClick={() => setOpenHint(true)}>
            <IconInfo width={20} /> <span>Understanding the sunrise system</span>
          </div>
          <ViewWhanauListTable
            items={items}
            page={queryHashFilter.page}
            size={queryHashFilter.size}
            total={total}
            isLoaded={isLoaded}
            onChange={onListingChange}
          />
        </PageFrame>
      ) : (
        <PageFrame simple classes={{ paper: styles.cardsPaper }}>
          <div className={styles.hint} onClick={() => setOpenHint(true)}>
            <IconInfo width={20} /> <span>Understanding the sunrise system</span>
          </div>
          <ViewWhanauListCards
            items={items}
            page={queryHashFilter.page}
            size={queryHashFilter.size}
            total={total}
            isLoaded={isLoaded}
            onChange={onListingChange}
          />
        </PageFrame>
      )}
      <ContentModal open={openHint} content={<ViewWhanauListContent />} close={() => setOpenHint(false)} />
    </div>
  )

  const renderMap = () => (
    <div>
      {renderFilter()}
      {!isLoaded ? (
        <Loader absolute />
      ) : (
        mapItems.length > 0 && (
          <WhanauMap
            items={mapItems}
          />
        )
      )}
    </div>
  )

  return (
    <LayoutApp>
      <Meta title="Whānau and individual status" />

      <PageTabs
        initialTab={queryHashTab}
        items={[
          { value: 'list', label: 'Whānau list', content: renderList() },
          { value: 'map', label: 'Whānau map', content: renderMap() },
        ]}
        onTabChange={setQueryHashTab}
      />
    </LayoutApp>
  )
})

const mapStateToProps = (state: IState): Props => ({
  items: state.app.whanau.list.items,
  mapItems: state.app.whanau.list.mapItems,
  total: state.app.whanau.list.total,
  isLoaded: state.app.whanau.isLoaded,
  filter: state.app.whanau.list.filter,
  filterUpdate: whanauService.loadList,
  filterMapUpdate: whanauService.loadMapList,
})

const ViewWhanauListConnected = connect(mapStateToProps)(withRouter(ViewWhanauList))

export { ViewWhanauListConnected as ViewWhanauList }
