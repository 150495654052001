import React, { useState } from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent1Card } from './tabContent1/Card'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import {
  getBenefit,
  getConnectionWithServices,
  getDebt,
  getEnrolledatGP,
  getEDpresentations,
  getEventsCorrections,
  getFamilyHarm,
  getNEET,
  getWhanauEmployed,
} from 'helpers/BreakDownDataHelper'

export const ViewCohortKpiTabContent1 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('cohort-kpi-overview')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <div>
      <PageFrame simple>
        <Grid container spacing={16} mb={24}>
          <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
            {data &&
              getData(data).map(item => (
                <Grid item key={`${item.name}`} xs={12} md={6} lg={4}>
                  <TabContent1Card {...item} />
                </Grid>
              ))}
          </LoaderPane>
        </Grid>
      </PageFrame>
    </div>
  )
}

const getData = (data: IReportBreakDown[]) => {
  const debt = getDebt(data)
  const benefit = getBenefit(data)
  const enrolled = getEnrolledatGP(data)
  const ed = getEDpresentations(data)
  const connection = getConnectionWithServices(data)
  const neet = getNEET(data)
  const employed = getWhanauEmployed(data)
  const harm = getFamilyHarm(data)
  const corrections = getEventsCorrections(data)

  return [
    {
      name: 'Debt',
      text: 'Whānau in debt',
      value: debt.num_val,
      percent: debt.num_perc,
      color: '#26C6DA',
      url: '/cohort-kpi#{"tab":"2"}',
    },
    {
      name: 'Benefit',
      text: 'Whānau on a benefit',
      value: benefit.num_val,
      percent: benefit.num_perc,
      color: '#36B9B1',
      url: '/',
    },
    {
      name: 'Enrolled at GP',
      text: 'Whānau enrolled with GP' ,
      value: enrolled.num_val,
      percent: enrolled.num_perc,
      color: '#986DCF',
      url: '/',
    },
    {
      name: 'ED presentations',
      text: 'ED presentations',
      value: ed.num_val,
      percent: ed.num_perc,
      color: '#7D5285',
      url: '/',
    },
    {
      name: 'Connection with services',
      text: 'Whānau connected',
      value: connection.num_val,
      percent: connection.num_perc,
      color: '#77C288',
      url: '/',
    },
    { name: 'NEET', text: 'Whānau NEETs', value: neet.num_val, percent: neet.num_perc, color: '#3692BA', url: '/' },
    {
      name: 'Whānau employed',
      text: 'Whānau adults employed',
      value: employed.num_val,
      percent: employed.num_perc,
      color: '#0A867E',
      url: '/',
    },
    {
      name: 'Family harm',
      text: 'Family harm events this quarter',
      value: harm.num_val,
      percent: harm.num_perc,
      color: '#3C9F76',
      url: '/',
    },
    {
      name: 'Events corrections',
      text: 'Events with corrections',
      value: corrections.num_val,
      percent: corrections.num_perc,
      color: '#2B6A7D',
      url: '/',
    },
  ]
}
