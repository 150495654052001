import React, { LegacyRef } from 'react'
import classNames from 'classnames'
import { Paper } from '@material-ui/core'
import styles from './Card.module.scss'

interface Props {
  title?: string
  children?: React.ReactNode
  className?: string
  padding?: number | string
}

export const Card = React.forwardRef((
  { title, children, className, padding }: Props, ref: LegacyRef<HTMLDivElement>
) => (
  <div ref={ref} className={styles.wrapper}>
    {title && (<div className={styles.title}>{title}</div>)}
    <Paper className={classNames(styles.root, className)} style={{ padding }}>
      {children}
    </Paper>
  </div>
))
