import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { IconButton, Tooltip } from '@material-ui/core'
import styles from './TooltipIconButton.module.scss'

interface Props {
  icon: React.ReactNode
  href?: string
  to?: string
  replace?: boolean
  tooltipText?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export const TooltipIconButton = ({
  tooltipText,
  placement,
  icon,
  to,
  replace,
  href,
  onClick,
  disabled,
  className,
}: Props) => {
  const tooltipProps = {
    placement,
    title: tooltipText,
    disableFocusListener: true,
    PopperProps: {
      disablePortal: true,
      modifiers: {
        flip: {
          enabled: false,
        },
        preventOverflow: {
          escapeWithReference: true,
        },
      },
    },
  }

  if (disabled) {
    return <IconButton disabled>{icon}</IconButton>
  }

  if (to) {
    return (
      <Tooltip {...tooltipProps}>
        <Link className={classNames(styles.link, className)} to={to} replace={replace}>
          <IconButton className="tooltip-icon-button">{icon}</IconButton>
        </Link>
      </Tooltip>
    )
  }

  if (href) {
    return (
      <Tooltip {...tooltipProps}>
        <a className={classNames(styles.link, className)} href={href}>
          <IconButton className="tooltip-icon-button">{icon}</IconButton>
        </a>
      </Tooltip>
    )
  }

  return (
    <Tooltip {...tooltipProps}>
      <IconButton className={classNames('tooltip-icon-button', className)} onClick={() => onClick && onClick()}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}
