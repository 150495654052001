import React from 'react'
import IconAdd from '@material-ui/icons/Add'
import { Button } from '@material-ui/core'
import { Modal } from 'base/components/ui/Modal'
import styles from './Extra.module.scss'

interface Props<T> {
  label?: string
  filter: T
  children: (extraFilter: T, extraFilterUpdate: (f: T) => void) => React.ReactNode
  filterUpdate: (filter: T) => void
}

export function ListingFilterExtra<T>({ filter, children, filterUpdate, label = 'More filters' }: Props<T>) {
  const [open, setOpen] = React.useState(false)
  const [extraFilter, setExtraFilter] = React.useState(filter)

  const onOpen = () => {
    setExtraFilter(filter)
    setOpen(true)
  }

  const onAction = () => {
    setOpen(false)
    filterUpdate(extraFilter)
  }

  return (
    <div className={styles.root}>
      <Button className={styles.extraButton} onClick={onOpen}>
        <IconAdd />
        {label}
      </Button>

      <Modal
        open={open}
        title={label}
        content={children(extraFilter, setExtraFilter)}
        actionLabel={'Apply'}
        onAction={onAction}
        classNamePaper={styles.modalPaper}
        close={() => setOpen(false)}
      />
    </div>
  )
}
