import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { HorizontalBarItem } from 'components/shared/ui/charts/HorizontalBarItem'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { LoaderPane } from 'components/shared/ui/LoaderPane'
import { useOnMount } from 'base/hooks/useOnMount'

export const TabContent3Card3 = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('top-reasons-ed-presentations')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  const max = Math.max(...data.map(i => i.num_val))

  return (
    <Card title={'Top reasons for ED presenations and Hospital admissions'} padding={24}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        {data.map(item => (
          <HorizontalBarItem
            key={`${item.measure}-${item.dimension}`}
            title={item.dimension}
            titleWidth={80}
            color={'#26C6DA'}
            value={item.num_val}
            valuePosition={'outside'}
            titlePosition={'top'}
            max={max * 1.5}
          />
        ))}
      </LoaderPane>
    </Card>
  )
}
