import React from 'react'
import { Dialog } from '@material-ui/core'
import { TGoal } from 'models/Goal'
import styles from './GoalModal.module.scss'

interface Props {
  goal: TGoal | null
  close: () => void
}

export const GoalModal = ({ goal, close }: Props) => (
  <Dialog className={styles.root} classes={{ paper: styles.paper }} open={!!goal} onClose={close}>
    <div>
      {goal && (
        <>
          {goal.id}, {goal.summary}
        </>
      )}
    </div>
  </Dialog>
)
