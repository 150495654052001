import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IState } from 'store'
import { RouteComponentProps, withRouter } from 'react-router'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { Meta } from 'components/shared/Meta'
import { LayoutApp } from 'components/shared/layouts/LayoutApp'
import { PageFrame } from 'base/components/ui/PageFrame'
import { ViewUserEditForm } from './viewUserEdit/Form'
import { layoutService } from 'services/LayoutService'
import { confirmDeleteUser, confirmResetPassword, userService } from 'services/UserService'
import { TModel as TUser, create as createUser } from 'models/User'
import { TooltipIconButton } from 'base/components/ui/TooltipIconButton'
import { getSuspendOrRestoreAction } from 'utils/suspendUtils'
import { useOnMount } from 'base/hooks/useOnMount'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  id?: string
  action: 'view' | 'edit'
  item: null | TUser
  isLoading: boolean
  load: (id: string) => void
  clear: () => void
  save: (item: TUser, oldItem: TUser) => Promise<TUser>
  suspend: () => void
  restore: () => void
}

const ViewUserEdit = (props: Props & RouteComponentProps) => {
  const { id, item, isLoading, load, save, clear, action, history: { push } } = props

  useOnMount(() => {
    layoutService.setHeaderLarge(false)
    if (id) {
      load(id)
    } else {
      clear()
    }
  })

  useEffect(() => {
    layoutService.setBreadcrumbs([{ label: 'Users', url: '/users' }, { label: id ? action : 'Create' }])
  }, [id, action])

  const onSubmit = async (item: TUser, oldItem: TUser) => {
    const user = await save(item, oldItem)
    if (user.id) {
      push(`/users/${user.id}/view`)
    }
  }

  return (
    <LayoutApp>
      <Meta title={id ? `${action} User` : 'Create User'} />
      <PageFrame title={id ? `${action} User` : 'Create User'} actions={renderActions(props)}>
        <ViewUserEditForm action={action} item={item || createUser()} isLoading={isLoading} onSubmit={onSubmit} />
      </PageFrame>
    </LayoutApp>
  )
}

const renderActions = ({ item, suspend, restore, history: { push }, action }: Props & RouteComponentProps) =>
  item &&
  !!item.id && (
    <>
      {action === 'view' && (
        <TooltipIconButton
          icon={<EditIcon />}
          tooltipText={'Edit User'}
          onClick={() => push(`/users/${item.id}/edit`)}
        />
      )}
      <TooltipIconButton
        icon={<LockOpenIcon />}
        tooltipText={'Reset Password'}
        onClick={() => confirmResetPassword(item)}
      />

      {item.role < 99 && getSuspendOrRestoreAction(item, 'User', suspend, restore)}
    </>
  )

const mapStateToProps = (state: IState, ownProps: any): Props => ({
  id: ownProps.match.params.id,
  action: ownProps.match.params.action,
  item: state.app.users.detail.user,
  isLoading: state.app.users.isLoading,
  load: userService.loadDetail,
  clear: userService.clearDetail,
  save: userService.saveDetail,
  suspend: confirmDeleteUser,
  restore: () => userService.suspend(false),
})

const ViewUserEditConnected = connect(mapStateToProps)(withRouter(ViewUserEdit))

export { ViewUserEditConnected as ViewUserEdit }
