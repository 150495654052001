import { request } from 'base/utils/http/Request'
import { LOGIN, LOGOUT, ME } from 'config/paths'
import { TModel as TUser, create as createUser, map as mapUser } from 'models/User'

export const me = async (): Promise<TUser | null> => {
  const responseMe = await request.get(ME)
  return responseMe.user ? createUser(mapUser(responseMe.user)) : null
}

export const login = async (email: string, password: string): Promise<boolean> => {
  try {
    const { username } = await request.post(LOGIN, { email, password })
    return !!username
  } catch (e) {
    return false
  }
}

export const logout = async (): Promise<void> => {
  await request.post(LOGOUT)
}
