import React from 'react'
import classNames from 'classnames'
import memoizeOne from 'memoize-one'
import { Card } from 'base/components/ui/Card'
import { Table, TColumn } from 'base/components/ui/Table'
import { TWhanau } from 'models/Whanau'
import { TMember } from 'models/Member'
import { CardMemberTableItem } from './cardMemberTable/Item'
import styles from './CardMemberTable.module.scss'
import { getAgeCategory, getAgeCategoryName } from '../../../../helpers/KWODataHelper'

interface Props {
  whanau: TWhanau
  members: TMember[]
  className?: string
}

export const TabContent1CardMemberTable = ({ whanau, members, className }: Props) => {
  const memberFiltered = members.filter(i => !!whanau.peoples.find(p => p.kwoId === i.id))

  return (
    <Card className={classNames(styles.root, className)}>
      <Table className={styles.table} columns={getColumns(whanau, memberFiltered)} items={getItems(whanau, members)} />
    </Card>
  )
}

const getColumns = memoizeOne(
  (whanau: TWhanau, members: TMember[]): TColumn[] => [
    {
      label: 'People',
      render: (item: any) => item.name,
    },
    {
      label: <Members whanau={whanau} members={members} />,
      render: (item: any) => item.members,
    },
  ]
)

const AGE_CATEGORIES = ['Kaumatua', 'Maatua', 'Rangatahi', 'Tamariki', 'Pepi']

const getItems = memoizeOne(
  (whanau: TWhanau, members: TMember[]): TObjectAny[] => {
    return AGE_CATEGORIES.map(ageCategoryName => {
      const peopleByAge = whanau.peoples.filter(i => {
        const ageCategory = getAgeCategory(i.age)
        return getAgeCategoryName(ageCategory).toLowerCase() === ageCategoryName.toLowerCase()
      })

      const membersFiltered = members.filter(i => !!peopleByAge.find(p => p.kwoId === i.id))
      return {
        name: ageCategoryName,
        members: (
          <Members dark whanau={whanau} members={membersFiltered} />
        ),
      }
    })
  }
)

interface MembersProps {
  dark?: boolean
  whanau: TWhanau
  members: TMember[]
}

const Members = ({ dark, whanau, members }: MembersProps) => (
  <div className={styles.members}>
    {members.map(member => (
      <CardMemberTableItem dark={dark} key={member.id} whanau={whanau} member={member} />
    ))}
  </div>
)
