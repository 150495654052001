import { useReduxState } from 'base/hooks/useReduxState'

export function useMultiFocus(namespace: string) {
  const key = `multiFocus:${namespace}`
  const [ids, setIds] = useReduxState<string[]>(key, [], true)

  const isFocused = (id: string) => ids.indexOf(id) !== -1

  const setFocus = (id: string, focus: boolean) => {
    focus ? setIds([...ids, id]) : setIds(ids.filter(i => i !== id))
  }

  return { isFocused, setFocus }
}
