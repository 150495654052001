import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { AreaChart } from 'components/shared/ui/charts/AreaChart'
import { IChartData } from 'store/state'
import { useOnMount } from 'base/hooks/useOnMount'
import { fetchChart } from 'api/KWOApi'
import { prepareChartData } from 'helpers/ChartDataHelper'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

const fields = [
  {
    code: 'Total whanau in programme',
    name: 'Total whānau in programme',
    color: '#0A867E',
    props: { fillOpacity: 1 },
  },
]

export const TabContent6Card1 = () => {
  const [data, setData] = useState<IChartData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchChart('NEET')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  return (
    <Card padding={24} title={'16-24 year old NEET'}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        <AreaChart
          height={200}
          areas={fields.map(i => ({ name: i.name, color: i.color, field: i.code, props: i.props }))}
          data={prepareChartData(fields, data)}
        />
      </LoaderPane>
    </Card>
  )
}
