import React, { useState } from 'react'
import { Card } from 'base/components/ui/Card'
import { HorizontalBarItem } from 'components/shared/ui/charts/HorizontalBarItem'
import { fetchReportBreakDown, IReportBreakDown } from 'api/KWOApi'
import { useOnMount } from 'base/hooks/useOnMount'
import { LoaderPane } from 'components/shared/ui/LoaderPane'

const colorScheme = new Map<string, string>([
  ['single', '#77C288'],
  ['couple with no kids', '#3692BA'],
  ['couple with kids', '#7D5285'],
  ['single parents', '#433072'],
])

export const TabContent1FamilyType = () => {
  const [data, setData] = useState<IReportBreakDown[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const fetch = async () => {
      const data = await fetchReportBreakDown('family-type')
      setData(data)
      setIsLoaded(true)
    }

    fetch().catch(window.logger.error)
  })

  const max = Math.max(...data.map(i => i.num_val))

  return (
    <Card padding={24} title={'Family type breakdown / number of whanau receiving whanau direct'}>
      <LoaderPane isLoaded={isLoaded} isData={data.length > 0}>
        {data.map(item => (
          <HorizontalBarItem
            key={`${item.measure}-${item.dimension}`}
            title={item.dimension}
            titleWidth={80}
            color={colorScheme.get(item.dimension.toLowerCase())}
            value={item.num_val}
            max={max}
          />
        ))}
      </LoaderPane>
    </Card>
  )
}
