import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconEventEmail = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} width={width} height={height} fill="none">
    <circle cx="12" cy="12" r="12" fill="#4C88CE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3334 6.66675H6.66671C5.93337 6.66675 5.34004 7.26675 5.34004 8.00008L5.33337 16.0001C5.33337 16.7334 5.93337 17.3334 6.66671 17.3334H17.3334C18.0667 17.3334 18.6667 16.7334 18.6667 16.0001V8.00008C18.6667 7.26675 18.0667 6.66675 17.3334 6.66675ZM6.66671 9.33341L12 12.6667L17.3334 9.33341V16.0001H6.66671V9.33341ZM6.66671 8.00008L12 11.3334L17.3334 8.00008H6.66671Z"
      fill="#fff"
    />
  </svg>
))
