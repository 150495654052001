import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import { TooltipIconButton } from 'base/components/ui/TooltipIconButton'

interface Item {
  suspended: boolean
}

export const getSuspendOrRestoreAction = (item: Item, name: string, onSuspend: () => void, onRestore: () => void) => {
  return item && item.suspended ? (
    <TooltipIconButton tooltipText={`Restore ${name}`} icon={<RestoreFromTrashIcon />} onClick={() => onRestore()} />
  ) : (
    <TooltipIconButton tooltipText={`Delete ${name}`} icon={<DeleteIcon />} onClick={onSuspend} />
  )
}
