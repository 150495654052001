import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  className?: string
}

export const IconClock = React.memo(({ width, height, className }: Props) => (
  <svg className={`MuiSvgIcon-root ${className}`} viewBox="0 0 28 28" width={width} height={height} fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2142 2.52905C7.97307 2.52905 2.91913 7.5943 2.91913 13.8354C2.91913 20.0765 7.97307 25.1418 14.2142 25.1418C20.4666 25.1418 25.5319 20.0765 25.5319 13.8354C25.5319 7.5943 20.4666 2.52905 14.2142 2.52905ZM14.2255 22.8804C9.22809 22.8804 5.18041 18.8327 5.18041 13.8353C5.18041 8.83785 9.22809 4.79017 14.2255 4.79017C19.2229 4.79017 23.2706 8.83785 23.2706 13.8353C23.2706 18.8327 19.2229 22.8804 14.2255 22.8804ZM13.0949 8.18208H14.7908V14.1179L19.8787 17.1367L19.0307 18.5274L13.0949 14.9659V8.18208Z"
    />
  </svg>
))
