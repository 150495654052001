import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import ReactMapGL, { LinearInterpolator, Marker, Popup, ViewState, NavigationControl } from 'react-map-gl'
import { TWhanau, trafficLightMap } from 'models/Whanau'
import { config } from 'config/config'
import styles from './Map.module.scss'
import { CardWhanauStats } from 'components/shared/ui/CardWhanauStats'
import { CloseButton } from 'components/shared/ui/CloseButton'
import { useWindowSize } from 'base/hooks/useWindowSize'
import { lineString, bbox } from '@turf/turf'
import WebMercatorViewport from 'viewport-mercator-project'

interface Props {
  items: TWhanau[]
  markerColor?: string
  withPopup?: boolean
}

interface MapViewPortState extends ViewState {
  transitionInterpolator?: any
  transitionDuration?: number
  width?: string
  height?: string
}

export const WhanauMap = ({ items, markerColor, withPopup = true }: Props) => {
  const { height } = useWindowSize()
  const [viewport, setViewport] = React.useState<MapViewPortState>({
    latitude: -36.8485,
    longitude: 174.7633,
    zoom: 4,
    transitionInterpolator: null,
    transitionDuration: 0,
  })

  useEffect(() => {
    const values = items.map(i => [i.lng, i.lat])
    if (values.length > 1) {
      const line = lineString(values)
      const [minLng, minLat, maxLng, maxLat] = bbox(line)

      const webMercatorViewport = new WebMercatorViewport({
        latitude: -36.8485,
        longitude: 174.7633,
        zoom: 4,
        width: 800,
        height: 600,
        pitch: 60,
        bearing: 30,
      })
      const { longitude, latitude, zoom } = webMercatorViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: 40,
      })

      setViewport(prevState => ({ ...prevState, longitude, latitude, zoom: zoom - 1 }))
    }
  }, [items, setViewport])

  const [selected, setSelected] = React.useState<null | TWhanau>(null)

  return (
    <div className={styles.root} style={{ height: height - 276 }}>
      <ReactMapGL
        mapboxApiAccessToken={config.mapboxglAccessToken}
        width={'100%'}
        height={'100%'}
        scrollZoom={false}
        {...viewport}
        onViewportChange={viewport => setViewport({ ...viewport, width: '100%', height: '100%' })}
      >
        {items.map(item => (
          <Marker key={item.id} latitude={item.lat} longitude={item.lng} offsetLeft={-11} offsetTop={-11}>
            <div
              className={classNames(styles.marker, {
                [styles[`${trafficLightMap(item)}`]]: !markerColor,
                [styles[markerColor ? markerColor : '']]: markerColor,
              })}
              onClick={() => {
                setSelected(selected && selected.id === item.id ? null : item)
                setViewport({
                  latitude: item.lat,
                  longitude: item.lng,
                  zoom: viewport.zoom,
                  transitionInterpolator: new LinearInterpolator(),
                  transitionDuration: 500,
                })
              }}
            />
          </Marker>
        ))}
        {withPopup && selected && (
          <Popup
            latitude={selected.lat}
            longitude={selected.lng}
            closeButton={false}
            closeOnClick={false}
            offsetTop={-270}
            offsetLeft={-96}
            className={styles.popupWrapper}
          >
            <div className={styles.popup}>
              <CardWhanauStats item={selected} />
              <CloseButton className={styles.popupClose} onClick={() => setSelected(null)} />
            </div>
          </Popup>
        )}

        <div className={styles.navigation}>
          <NavigationControl onViewportChange={viewport => setViewport({ ...viewport })} className={styles.controls} />
        </div>
      </ReactMapGL>
    </div>
  )
}
