import { TModel as TUser, create as createUser } from 'models/User'
import { TWhanau, create as createWhanau } from 'models/Whanau'
import { TMember, create as createMember } from 'models/Member'
import { TBreadcrumb } from 'services/LayoutService'
import { TCollective } from '../models/Collective'

export interface IKWOData {
  kwo_id: string
  measure: string
  num_val: number
  str_val: string
  traffic_light: string
  units: string
}

export interface IChartData {
  time: number
  measure: string
  num_val: number
  str_val: string
  units: string
}

export interface IAppState {
  currentUser: TUser
  layout: {
    isExpanded: boolean
    isHeaderLarge: boolean
    breadcrumbs: TBreadcrumb[]
    dialog: {
      open: boolean
      title: string
      content: string
      cancel: boolean
      actionLabel: string
      action: () => any
    }
    snackbar: {
      open: boolean
      variant: string
      message: string
    }
  }
  users: {
    isLoading: boolean
    list: {
      items: TUser[]
      total: number
      filter: {
        page: number
        size: SizeOption
        'user.suspended': boolean
        'user.email': null | string
        'user.role': null | number
        sort: string
      }
    }
    detail: {
      user: TUser
    }
  }
  whanau: {
    isLoaded: boolean
    list: {
      tab: 'list'
      items: TWhanau[]
      mapItems: TWhanau[]
      total: number
      filter: {
        page: number
        size: SizeOption
        field1?: string
        field2?: number[]
        field3?: number
        field4?: number
        field5?: string // status
        field6?: string
      }
    }
    detail: {
      tab: 'tab1'
      whanau: TWhanau
    }
  }
  members: {
    isLoading: boolean
    detail: {
      member: TMember
      whanau: TWhanau
    }
  }
  collective: {
    items: TCollective[]
    isLoading: boolean
  }
}

export const initialState: IAppState = {
  currentUser: createUser({}),
  layout: {
    isExpanded: false,
    isHeaderLarge: false,
    breadcrumbs: [] as TBreadcrumb[],
    dialog: {
      open: false,
      title: '',
      content: '',
      cancel: true,
      actionLabel: '',
      action: () => {},
    },
    snackbar: {
      open: false,
      variant: 'success',
      message: 'Success',
    },
  },
  users: {
    isLoading: false,
    list: {
      items: [] as TUser[],
      total: 0,
      filter: {
        page: 0,
        size: 25 as SizeOption,
        'user.suspended': false,
        'user.email': null as null | string,
        'user.role': null as null | number,
        sort: 'name,asc',
      },
    },
    detail: {
      user: createUser({}),
    },
  },
  whanau: {
    isLoaded: false,
    list: {
      tab: 'list',
      items: [] as TWhanau[],
      mapItems: [] as TWhanau[],
      total: 0,
      filter: {
        page: 0,
        size: 25 as SizeOption,
        field1: undefined,
        field2: undefined,
        field3: undefined,
        field4: undefined,
        field5: undefined, // status
        field6: undefined,
      },
    },
    detail: {
      tab: 'tab1',
      whanau: createWhanau({}),
    },
  },
  members: {
    isLoading: false,
    detail: {
      member: createMember({}),
      whanau: createWhanau({}),
    },
  },
  collective: {
    items: [] as TCollective[],
    isLoading: false,
  },
}

export interface IState {
  app: IAppState
  internal: TObjectAny
}
