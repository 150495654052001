import { TWhanau } from 'models/Whanau'
import { IKWOData } from 'store/state'
import {
  getAddress,
  getAge,
  getAgeCategory,
  getAgeCategoryName,
  getBenefit, getCurrentSchools,
  getEmployment, getEnrolled,
  getEthnicity,
  getGender,
  getInProgramSince,
  getIWI,
  getNeet,
  getTotalDebt,
  getTrainingReferral,
  getWorkObligation,
} from '../helpers/KWODataHelper'

export type TAgeCategory = 0 | 1 | 2 | 3 | 4
export type TGender = 'M' | 'F' | undefined

const initialData = {
  id: '',
  name: '',
  age: 0,
  ageCategory: undefined as undefined | TAgeCategory,
  gender: 'M' as TGender,
  ethnicity: '',
  iwi: '',
  enrolled: '',
  address: '',
  ageCategoryName: '',
  inProgramSince: '',
  neet: '',
  benefit: '',
  workObligation: '',
  trainingReferral: '',
  employment: '',
  totalDebt: '',
  currentSchools: '',
  isAdult: false,
}

export type TMember = typeof initialData

export const create = (data?: Partial<TMember>, rawData?: IKWOData[]): TMember => {
  const age = getAge(rawData)
  const ageCategory = getAgeCategory(age)
  return {
    ...initialData,
    ...data,
    age,
    ageCategory,
    enrolled: getEnrolled(rawData),
    gender: getGender(rawData),
    ageCategoryName: ageCategory ? getAgeCategoryName(ageCategory) : '',
    ethnicity: getEthnicity(rawData),
    iwi: getIWI(rawData),
    address: getAddress(rawData),
    inProgramSince: getInProgramSince(rawData),
    neet: getNeet(rawData),
    benefit: getBenefit(rawData),
    workObligation: getWorkObligation(rawData),
    trainingReferral: getTrainingReferral(rawData),
    employment: getEmployment(rawData),
    totalDebt: getTotalDebt(rawData),
    currentSchools: getCurrentSchools(rawData),
    isAdult: ![0, 1, 2].includes(ageCategory),
  }
}

export const getLink = (whanau: TWhanau, item: TMember) => `/whanau/${whanau.id}/member/${item.id}`
export const getMemberLinkById = (whanauId: string, memberId: string) => `/whanau/${whanauId}/member/${memberId}`
