import React from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { TooltipIconButton } from './TooltipIconButton'
import styles from './ListingActions.module.scss'

interface TMainAction {
  to: string
  icon: React.ReactNode
  tooltipText?: string
}

interface TMenuAction {
  label: string
  onClick: () => void
}

interface Props {
  mainActions?: TMainAction[]
  menuActions?: TMenuAction[]
}

export const ListingActions = ({ mainActions, menuActions }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null as any)

  return (
    <div className={styles.root}>
      {mainActions &&
        mainActions.map(action => (
          <TooltipIconButton
            key={action.to}
            to={action.to}
            icon={action.icon}
            tooltipText={action.tooltipText}
            placement={'bottom'}
            className={styles.main}
          />
        ))}
      {menuActions && (
        <>
          <Tooltip title="More Actions" {...standardTooltipProps}>
            <IconButton className={styles.menuButton} onClick={e => setAnchorEl(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
            {menuActions.map(action => (
              <MenuItem
                key={action.label}
                onClick={() => {
                  action.onClick()
                  setAnchorEl(null)
                }}
              >
                {action.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  )
}

const standardTooltipProps = {
  disableFocusListener: true,
  PopperProps: {
    disablePortal: true,
    modifiers: {
      flip: {
        enabled: false,
      },
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  },
}
