import React  from 'react'
import { PageFrame } from 'base/components/ui/PageFrame'
import { Grid } from 'base/components/ui/Grid'
import { TabContent2Chart } from './tabContent2/Chart'

export const ViewCohortStatusTabContent2 = () => {
  return (
    <div>
      <PageFrame simple>
        <Grid container>
          <TabContent2Chart />
        </Grid>
      </PageFrame>
    </div>
  )
}
