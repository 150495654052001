import React from 'react'
import { Dayjs } from 'dayjs'
import { TEvent } from 'models/Event'
import { EventsItem } from './events/Item'
import styles from './Events.module.scss'

interface Props {
  events: TEvent[]
  minDay: Dayjs
  maxDay: Dayjs
}

export const GoalPlanEvents = ({ events, minDay, maxDay }: Props) => (
  <div className={styles.root}>
    <div className={styles.label}>Navigator contacts</div>
    <div className={styles.line} />
    <div className={styles.area}>
      {events.map((event, i) => (
        <EventsItem
          key={event.id}
          event={event}
          minDay={minDay}
          maxDay={maxDay}
          position={i % 2 ? 'top' : 'bottom'}
          hint={event.name}
        />
      ))}
    </div>
  </div>
)
