import React from 'react'
import styles from './Content.module.scss'
import classNames from 'classnames'

export const ViewWhanauListContent = () => (
  <div className={styles.root}>
    <Section
      className={styles.positive}
      title={'Mauri oho - awakening to change'}
      subtitle={'Te ata tū (just after sunrise)'}
      text={
        'Positive change is indicated, and whānau are actively progressing forward with their Te Ara Whānau Ora plan.'
      }
    />

    <Section
      className={styles.concerning}
      title={'Mauri rere - movement or moving'}
      subtitle={'Te ata tuhi (breaking dawn)'}
      text={
        `Signaling that change is in motion – the change can see whānau moving to and fro as they navigate their
         pathway`
      }
    />

    <Section
      className={styles.redAlert}
      title={'Mauri noho - resting'}
      subtitle={'Te ata hāpara (the breath of dawn)'}
      text={
        `Denotes that whilst there may be some darkness, we are aware that the light will break on the horizon at dawn
         encouraging positive opportunities to be explored.`
      }
    />
  </div>
)

interface SectionProps {
  className: string
  title: string
  subtitle: string
  text: string
}
const Section = ({ className, title, subtitle, text }: SectionProps) => {
  return (
    <div className={styles.section}>
      <div className={classNames(styles.title, className)}>
        <div className={classNames(styles.round, className)} />
        <div>{title}</div>
      </div>
      <div className={styles.frame}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
}
