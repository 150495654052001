import * as React from 'react'
import { IconWahnauList } from '../components/shared/icons/sidebar/IconWahnauList'
import { IconHome } from '../components/shared/icons/sidebar/IconHome'
import { IconLeadership } from '../components/shared/icons/sidebar/IconLeadership'
import { IconCohortStatus } from '../components/shared/icons/sidebar/IconCohortStatus'
import { IconKPI } from '../components/shared/icons/sidebar/IconKPI'
import { IconAbout } from '../components/shared/icons/sidebar/IconAbout'
import { IconAdministration } from '../components/shared/icons/sidebar/IconAdministration'

export interface TSidebarNavItem {
  name: string
  icon: any
  url: string
  requiredRoles?: number[]
}

export interface TSidebarNavSection {
  sectionName: string
  items: TSidebarNavItem[]
}

export type TSidebarNav = TSidebarNavSection[]

export const sidebarNav: TSidebarNav = [
  {
    sectionName: '',
    items: [
      {
        name: 'Home',
        icon: <IconHome width={22} />,
        url: '/',
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'Whānau',
        icon: <IconWahnauList width={23} />,
        url: '/whanau',
        requiredRoles: [99, 40, 35],
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'Collective leadership',
        icon: <IconLeadership width={15} />,
        url: '/collective-leadership',
        requiredRoles: [99, 40, 35, 33, 15],
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'Cohort status',
        icon: <IconCohortStatus width={29} />,
        url: '/cohort-status',
        requiredRoles: [99, 40, 35, 33],
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'Cohort KPI',
        icon: <IconKPI width={24} />,
        url: '/cohort-kpi',
        requiredRoles: [99, 40, 35, 33, 15],
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'About whānau ora',
        icon: <IconAbout width={23} />,
        url: '/about',
      },
    ],
  },
  {
    sectionName: '',
    items: [
      {
        name: 'Users',
        icon: <IconAdministration width={24} />,
        url: '/users',
        requiredRoles: [99, 40],
      },
    ],
  },
]
