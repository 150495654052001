import * as React from 'react'
import { Avatar as MuiAvatar } from '@material-ui/core'
import { roleOptions, TModel as TUser } from 'models/User'
import styles from './Avatar.module.scss'
import classNames from 'classnames'

export interface Props {
  user: TUser
  className?: string
}

const backgroundColor = (user: TUser) => {
  const roleOption = roleOptions.find(i => i.value === user.role)
  if (!roleOption) {
    return '#64597f'
  }

  return roleOption.color || '#64597f'
}

const userLetters = (user: TUser) => `${user.firstName[0] || ''}${user.lastName[0] || ''}`.trim()

export const Avatar = ({ user, className }: Props) => {
  return (
    <MuiAvatar className={classNames(styles.avatar, className)} style={{ backgroundColor: backgroundColor(user) }}>
      {!user.avatar && userLetters(user)}
      {user.avatar && <img src={user.avatar} alt={'avatar'} />}
    </MuiAvatar>
  )
}
