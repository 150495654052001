import * as React from 'react'
import * as yup from 'yup'
import memoizeOne from 'memoize-one'
import { Formik, FormikActions } from 'formik'
import classNames from 'classnames'
import posed from 'react-pose'
import Fab from '@material-ui/core/Fab'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import TextField from '@material-ui/core/TextField'
import styles from '../Common.module.scss'
import { Link } from 'react-router-dom'

const formInitialValues = {
  email: '',
  password: '',
}

type TFormValues = typeof formInitialValues
type TFormAction = FormikActions<TFormValues>

interface Props {
  hasError: boolean
  onSubmit: (email: string, password: string) => void
}

interface State {
  formValues: TFormValues
}

export class ChangePasswordForm extends React.Component<Props, State> {
  public state: State = {
    formValues: formInitialValues,
  }

  public validationScheme = memoizeOne(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required('Please enter email')
        .email('Please enter valid email'),
      password: yup
        .string()
        .required('Please enter new password')
        .min(10,"Password must be at least 10 characters"),
    })
  })

  public submit = async (values: TFormValues, { setSubmitting }: TFormAction) => {
    await this.props.onSubmit(values.email, values.password)
    setSubmitting(false)
  }

  public render() {
    const { hasError } = this.props
    const { formValues } = this.state
    return (
      <>
        <div className={classNames(styles.title, styles.titleSignIn)}>Change password</div>

        <p className={styles.subTitleInfo}>
          Enter your new password.
        </p>

        <Formik
          initialValues={formValues}
          isInitialValid
          validationSchema={this.validationScheme()}
          onSubmit={this.submit}
        >
          {formProps => (
            <form className={styles.form} onSubmit={formProps.handleSubmit} noValidate>
              <TextField
                className={styles.formRow}
                required
                fullWidth
                autoFocus
                label="Email"
                name="email"
                type="text"
                autoComplete="new-password"
                helperText={(!!formProps.touched.email || formProps.submitCount > 0) && formProps.errors.email}
                error={(!!formProps.touched.email || formProps.submitCount > 0) && !!formProps.errors.email}
                value={formProps.values.email}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
              <TextField
                className={styles.formRow}
                required
                fullWidth
                autoFocus
                label="New Password"
                name="password"
                type="password"
                autoComplete="new-password"
                helperText={(!!formProps.touched.password || formProps.submitCount > 0) && formProps.errors.password}
                error={(!!formProps.touched.password || formProps.submitCount > 0) && !!formProps.errors.password}
                value={formProps.values.password}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />

              {hasError && (
                <ErrorAnimated initialPose="closed" pose="opened">
                  <SnackbarContent className={styles.snackbar} message={'An error occurred. Please try again later!'} />
                </ErrorAnimated>
              )}

              <div className={styles.actions}>
                <Link className={styles.actionLink} to={'/login'}>
                  Back to login
                </Link>
                <Fab className={styles.button} variant="extended" type="submit" disabled={formProps.isSubmitting}>
                  {formProps.isSubmitting ? 'Sending' : 'Send'}
                </Fab>
              </div>
            </form>
          )}
        </Formik>
      </>
    )
  }
}

const ErrorAnimated = posed.div({
  closed: { opacity: 0 },
  opened: { opacity: 1, width: '100%' },
})
